import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Constants} from '../../constants';
import {AuthenticationHelper} from '../../app.authentication';
import {RefdataService} from '../../services/app.refdata.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() contactListFiltered: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() durationOptionChanged: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() onContactSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInstanceSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAutoSaveChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshActionFlow: EventEmitter<any> = new EventEmitter<any>();
  contactGroupModel: number[];
  breadcrumbValues: any = {};
  saveBtn: boolean;
  importTooltip = '';
  exportTooltip = '';
  addTooltip = '';
  viewModeText = '';
  allowAutoSave = true;
  allowEdit = false;
  participantLabel = 'Participant';
  participantMgmtLabel = 'Participant Managment';
 // pageHeaderStyle = '';

  constructor(public constants: Constants, private authentication: AuthenticationHelper,
              private refdataService: RefdataService, private sanitizer: DomSanitizer ) {

    // this.pageHeaderStyle = this.refdataService.getCustomStyleCss('PageHeaderBackground');
    // if ( this.pageHeaderStyle == null ) {
    //   this.pageHeaderStyle = 'background-color: white;';
    // }
  }

  @Input()
  set breadcrumbData(value) {
    if (value) {
      this.breadcrumbValues = value;
    }
  }

  @Input()
  set participantAlias(value) {
    if ( value ) {
      this.participantLabel = value;
      if ( value !== 'Participant' ) {
        this.participantMgmtLabel = value + ' Management';
      }
    }
  }

  // getPageHeaderStyle() {
  //   return this.sanitizer.bypassSecurityTrustStyle(this.pageHeaderStyle);
  // }

  onButtonClick(buttonType) {
    this.buttonClicked.emit(buttonType);
  }

  onChange(allowAutoSave) {
    this.allowAutoSave = allowAutoSave;
    this.onAutoSaveChanged.emit(this.allowAutoSave);
  }

  onContactChosen(event) {
    this.contactListFiltered.emit(this.contactGroupModel);
  }

  onChangeDurationFilter(event) {
    this.durationOptionChanged.emit(event.target.value);
  }

  onContactSelect(event) {
    this.onContactSelected.emit(event);
  }

  onInstanceSelect(event) {
    this.onInstanceSelected.emit(event.id);
  }

  setProperTooltips() {
    switch (this.breadcrumbValues.constantHeaderName) {
      case 'Action_Center': {
        this.importTooltip = '';
        this.addTooltip = 'Add Attribute Widget';
        this.allowEdit = this.authentication.getPrivilege('EditTeamMemberDashboard');
        break;
      }
      case 'Questionnaires_and_Guides': {
        this.importTooltip = 'Import Questionnaire';
        this.addTooltip = 'Create New Questionnaire';
        this.allowEdit = this.authentication.getPrivilege('EditSurveys');
        break;
      }
      case 'Action_Flows': {
        this.importTooltip = 'Import Workflow';
        this.addTooltip = 'Create New Workflow';
        this.allowEdit = this.authentication.getPrivilege('EditJourneyDesigns');
        break;
      }
      case 'Action_Flows_Canvas': {
        this.allowEdit = this.authentication.getPrivilege('EditJourneyDesigns');
        break;
      }
      case 'Journey_Run_Validation_Rules': {
        this.addTooltip = 'Create New Validation Rule';
        this.allowEdit = this.authentication.getPrivilege('EditJourneyDesigns');
        break;
      }
      case 'Journey_Runs' : {
        this.addTooltip = 'Create New Journey Run';
        this.importTooltip = 'Import Journey Run';
        this.allowEdit = this.authentication.getPrivilege('EditJourneys');
        break;
      }
      case 'Journey_Defs' : {
        this.addTooltip = 'Create New Journey Definition';
        this.importTooltip = 'Import Journey Definition';
        this.allowEdit = this.authentication.getPrivilege('EditJourneyDesigns');
        break;
      }
      case 'Edit_Journey_Run' : {
        this.addTooltip = 'Add Person to Journey';
        this.importTooltip = '';
        this.allowEdit = this.authentication.getPrivilege('EditJourneys');
        break;
      }
      case 'Resource_Items' : {
        this.addTooltip = 'Add Resource Item to Package';
        this.importTooltip = '';
        this.allowEdit = this.authentication.getPrivilege('EditHelpResources');
        break;
      }
      case 'Message_Bank_Messages' : {
        this.addTooltip = 'Add Message to Message Bank';
        this.importTooltip = '';
        this.allowEdit = this.authentication.getPrivilege('EditHelpResources');
        break;
      }
      case 'Person_Relationship' : {
        this.addTooltip = 'Add Relationship for Person';
        this.importTooltip = '';
        this.allowEdit = this.authentication.getPrivilege('EditParticipants');
        break;
      }
      case 'My_Care': {
        this.importTooltip = '';
        this.addTooltip = 'Add Activity';
        this.allowEdit = this.authentication.getPrivilege('EditCalendar');
        break;
      }
      case 'Questionnaires_and_Guides_Analyze': {
        this.exportTooltip = 'Export Questionnaire Results';
        break;
      }
      case 'Person_Surveys': {
        this.exportTooltip = 'Export ' + this.participantLabel + ' Survey Results';
        break;
      }
      case 'Person_Locations': {
        this.addTooltip = 'Add Location Entry';
        this.exportTooltip = 'Export ' + this.participantLabel + ' Locations';
        this.allowEdit = this.authentication.getPrivilege('EditParticipants');
        break;
      }
      case 'View_Journey_Instance': {
        this.exportTooltip = 'Export Journey Trace';
        break;
      }
    }
  }

  setVisualModeTextForCanvas() {
    if (this.breadcrumbValues.analyze) {
      this.viewModeText = 'Visual Analyze';
    } else if (this.breadcrumbValues.simulator) {
      this.viewModeText = 'Simulate Mode';
    } else {
      this.viewModeText = 'View-only Mode';
    }
  }

  manualUpdateActionFlow() {
    this.refreshActionFlow.emit(true);
  }

  ngOnInit() {
    this.setProperTooltips();
    this.setVisualModeTextForCanvas();
  }
}
