import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {Constants} from "../../constants";

export interface uploadImageUploadModel {
  defaultImage;
  width;
  height;
}

@Component({
  selector: 'upload-image-modal',
  templateUrl: './uploadImageModal.component.html',
})

export class UploadImageModalComponent extends DialogComponent<uploadImageUploadModel, any> implements uploadImageUploadModel {
  defaultImage = '';
  width;
  height;
  saveButton: boolean = false;


  constructor(dialogService: DialogService, private constants: Constants) {
    super(dialogService);
  }

  setValue(value = '') {
    if (value) {
      this.result = value;
      this.close();
    }
  }

  saveImage() {
    this.saveButton = true;
  }
}

