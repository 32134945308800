import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class AgentGroupService {
  private agentGroupUrl: string = 'api/v1/product/' + this.authentication.getSelectedObject() + '/agentGroup';

  constructor(private httpClient: HttpClientHelper, private authentication: AuthenticationHelper) {
  }

  getAgentGroupList(): Observable<any> {
    return this.httpClient.get(this.agentGroupUrl + '/all')
      .pipe(
        catchError(this.handleError)
      );
  }

  getAgentBusinessAccounts(): Observable<any> {
    return this.httpClient.get(this.agentGroupUrl + '/businessAccounts')
      .pipe(
        catchError(this.handleError)
      );
  }

  createAgentGroup(data) {
    return this.httpClient.post(this.agentGroupUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAgentGroup(data) {
    return this.httpClient.put(this.agentGroupUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessEmployeesWithRespectToServiceGroup(): Observable<any> {
    return this.httpClient.get(this.agentGroupUrl + '/agents')
      .pipe(
        catchError(this.handleError)
      );
  }

  getAgentGroupDetails(agentGroupId): Observable<any> {
    return this.httpClient.get(this.agentGroupUrl + '/' + agentGroupId)
      .pipe(
        catchError(this.handleError)
      );
  }


  handleError(error: any) {
    return throwError(error);
  }
}
