import {Injectable} from '@angular/core';

@Injectable()
export class AppModalService {
  static modals = [];

  static openModal(modalRef) {
    this.modals.push(modalRef);
  }

  static closeLastModal() {
    const lastModal = this.modals.pop();
    lastModal.close();
  }
}
