import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AppModalService} from '../../services/app.modalService';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'confirm',
  template: `
    <div class="modal-dialog" id="confirm-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="close()" >&times;</button>
          <h4 class="modal-title">{{title || 'Confirm'}}</h4>
        </div>
        <div class="modal-body">
          <p [innerHTML]="message | safeHtml"></p>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="confirm()">OK</button>
          <button autoFocusInput type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
      </div>
  </div>`
})

export class ConfirmComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm() {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

  ngOnInit() {
    AppModalService.openModal(this);
  }
}
