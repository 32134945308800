import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sort'
})

export class SortFilterPipe implements PipeTransform {

  transform(array: any[], field: string): any[] {
    try {
      if (array) {
        array.sort((a: any, b: any) => {
          if (a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field]) {
            return 1;
          } else {
            return 0;
          }
        });
        return array;
      }
    } catch (e) {
      console.log(e.message);
    }
  }
}
