import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationHelper} from '../app.authentication';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TicketService {
  private ticketUrlPrefix = 'api/tickets';
  private ticketResponseUrlPrefix = 'api/ticketResponses';
  private ticketProductPrefixUrl = 'api/ticket/v1/product/' + this.authentication.getSelectedObject() + '/business';
  private ticketUrlV1Prefix = 'api/ticket/v1';

  constructor(private httpClient: HttpClientHelper, private http: HttpClient, private authentication: AuthenticationHelper) {
  }

  getAllTickets(data: any = ''): Observable<any> {
    if (!data) {
      data = {
        length: 0
      }
    }
    const url = this.ticketProductPrefixUrl + '/list';

    // To be removed after applying pagination in all places of API call
    if (!data.pageNumber) {
      data.pageNumber = 1;
      data.pageSize = 100000;
    }

    return this.httpClient.post(url, data)
      .pipe(
        map(response => response.body)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllTicketsForConsumer(consumerId): Observable<any> {
    const url = 'api/ticket/v1/list/consumer/' + consumerId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      )
  }

  getConversationResponseStats(personId): Observable<any> {
    const url = 'api/ticket/v1/product/' + this.authentication.getSelectedObject() + '/conversation/stats/' + personId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      )
  }

  getTicket(ticketId: number): Observable<any> {
    const url = this.ticketUrlPrefix + '/' + ticketId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  resolveTicket(ticketId: number): Observable<any> {
    return this.updateStatus(ticketId, 'RESOLVED');
  }

  assignTicket(ticketId: number, assignTo: number): Observable<any> {
    const assignTicket: any = {businessEmployeeId: assignTo};
    return this.httpClient.post(this.ticketUrlPrefix + '/' + ticketId + '/assignTicket', assignTicket)
      .pipe(
        catchError(this.handleError)
      );
  }

  respondOnTicket(ticketId: number, response: string, fileType): Observable<any> {
    const ticketResponse: any = {
      ticketId: ticketId,
      responseOwner: 'BUSINESS', responseText: response, responseType: fileType
    };
    return this.httpClient.post(this.ticketResponseUrlPrefix, ticketResponse)
      .pipe(
        map(response => response.body)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  getResponses(ticketid: number): Observable<any> {
    return this.httpClient.get(this.ticketUrlPrefix + '/' + ticketid + '/responses')
      .pipe(
        map(response => response.body)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  updateStatus(ticketId: number, status: string): Observable<any> {
    const ticketStatus: any = {id: ticketId, status: status};
    return this.httpClient.post(this.ticketUrlPrefix + '/updateStatus', ticketStatus)
      .pipe(
        catchError(this.handleError)
      );
  }

  createTicket(data) {
    const url = this.ticketProductPrefixUrl;
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createTeamMemberConversation(data) {
    const url = this.ticketUrlV1Prefix + '/teamMember/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteConversation(conversationId) {
    const url = this.ticketUrlV1Prefix + '/delete/' + conversationId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  // consumer ticket create api for support

  consumerHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Auth-Token', localStorage.getItem('consumer_x_auth_token'));
    return headers;
  }

  createConsumerTicket(data) {
    const serverUrl = this.httpClient.getApiBaseUrl();
    const headers = this.consumerHeader();
    return this.http.post(serverUrl + this.ticketUrlPrefix, data, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  consumerRespondOnTicket(ticketId: number, response: string, fileType): Observable<any> {
    const ticketResponse = {
      ticketId: ticketId,
      responseOwner: 'CONSUMER', responseText: response, responseType: fileType
    };
    const headers = this.consumerHeader();
    const serverUrl = this.httpClient.getApiBaseUrl();
    return this.http.post(serverUrl + this.ticketResponseUrlPrefix, ticketResponse, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getConsumerResponses(ticketid: number): Observable<any> {
    const headers = this.consumerHeader();
    const serverUrl = this.httpClient.getApiBaseUrl();
    return this.http.get(serverUrl + this.ticketUrlPrefix + '/' + ticketid + '/responses', {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  // extractResponse(res: Response) {
  //   let body = res.json();
  //   return body || { };
  // }

  handleError(error: any) {
    return throwError(error);
  }


}
