import './vendor.ts';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app/';

if (environment.production) {
  enableProdMode();
}

const p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => {
  (<any>window).appBootstrap && (<any>window).appBootstrap();
});
// .catch(err => console.error(err));
