import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Constants} from '../../constants';
import {ContactService} from '../../services/app.contact.service';
import {ThemeLoader} from '../../app.component.loader';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {RefdataService} from '../../services/app.refdata.service';

declare let toastr;

@Component({
  selector: 'publishData',
  templateUrl: './publishData.component.html',
  styleUrls: ['./publishData.component.scss'],
  providers: [ContactService, RefdataService]
})

export class PublishDataComponent implements OnInit {
  title: string;
  @Output()
  savedData: EventEmitter<Object> = new EventEmitter<Object>();
  selectedGroupListIds = [];
  selectedContactListIds = [];
  allGroupList = [];
  allContactList = [];
  publishSurveyToServiceGroup = false;
  publishValue;
  @Input('required') required = true;
  @Input('helpnowPage') helpnowPage = false;
  @Input('eventType') eventType: string;
  @Input('readOnly') readOnly = false;

  pageSize = 100000;
  contactListPageNumber = 1;
  contactListSearchQuery = '';
  loadingContact = false;
  selectedContacts: FormControl = new FormControl([]);
  inputContact$: Subject<string> = new Subject<string>();

  contactGroupListPageNumber = 1;
  contactGroupListSearchQuery = '';
  loadingGroup = false;
  selectedGroups: FormControl = new FormControl([]);
  inputContactGroup$: Subject<string> = new Subject<string>();
  participantLabel = 'Participant';
  participantGroupLabel = 'Participant Group';

  firstTimeLoad = true;

  constructor(private constants: Constants, private contactService: ContactService,
              private refdataService: RefdataService,
              private spinner: ThemeLoader) {
    this.inputContact$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(
      value => this.onSearch(value, 'contactList')
    );

    // this.inputContactGroup$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(
    //   value => this.onSearch(value, 'contactGroupList')
    // );
  }

  @Input()
  set savePublishClick(value) {
    if (typeof value !== 'undefined' && value === true) {
      if (this.required) {
        if (this.selectedGroupListIds.length > 0 || this.selectedContactListIds.length > 0 || this.publishSurveyToServiceGroup) {
          this.savePublishDataObj();
        } else {
          this.savedData.emit(false);
          toastr.error(this.constants.contactGroupSelectionError);
        }
      } else {
        this.savePublishDataObj();
      }
    }
  }

  @Input()
  set getValue(value) {
    if (typeof value !== 'undefined') {
      this.getListOfGroupList();
      this.publishValue = value;
    }
  }

  selectedGroup(value: any): void {
    this.selectedGroupListIds = this.selectedGroups.value;
  }

  selectedContact(value: any): void {
    this.selectedContactListIds = this.selectedContacts.value;
  }

  removeGroup(value: any): void {
    const index = this.selectedGroupListIds.indexOf(value.id);
    if (index > -1) {
      this.selectedGroupListIds.splice(index, 1);
    }
  }

  removeContact(value: any): void {
    const index = this.selectedContactListIds.indexOf(value.id);
    if (index > -1) {
      this.selectedContactListIds.splice(index, 1);
    }
  }

  getListOfGroupList() {
    this.spinner.show();
    this.contactService.getContactGroupForProduct().subscribe(
      data => this.allGroupListSuccess(data),
      error => this.error(error)
    );
  }

  allGroupListSuccess(data) {
    this.loadingGroup = false;
    let list: any;
    if (data.status === 1) {
      const allList = data.body;
      for (let i = 0; i < allList.length; i++) {
        list = {
          id: allList[i].id,
          name: allList[i].name
        };
        this.allGroupList = [...this.allGroupList, list];
      }
    }
    this.getListOfContacts();
  }

  onScroll(list) {
    // if (list === 'contactList') {
    //   this.loadingContact = true;
    //   this.contactListPageNumber++;
    //   this.getListOfContacts();
    // } else {
    //   this.loadingGroup = true;
    //   this.contactGroupListPageNumber++;
    //   this.getListOfGroupList();
    // }
  }

  onSearch(event, list) {
    if (list === 'contactList') {
      this.loadingContact = true;
      this.contactListSearchQuery = event;
      this.contactListPageNumber = 1;
      this.allContactList = [];
      this.getListOfContacts();
    } else {
      // Add this for paginated API

      // this.loading = true;
      // this.contactGroupListSearchQuery = event;
      // this.contactGroupListPageNumber = 1;
      // this.allGroupList = [];
      // this.getListOfGroupList();
    }
  }

  getListOfContacts() {
    const data = {
      informationLevel: 'BASIC',
      orderBy: 'ASC',
      pageNumber: this.contactListPageNumber,
      pageSize: this.pageSize,
      sortBy: 'userName',
      userName: this.contactListSearchQuery,
    };

    this.contactService.getAllContacts(data).subscribe(
      data => this.allContactListSuccess(data),
      error => this.error(error)
    );
  }

  allContactListSuccess(data) {
    this.loadingContact = false;
    let contact: any;
    if (data.status === 1) {
      const contacts = data.body.contactList;
      const tempContactList = [];
      for (let i = 0; i < contacts.length; i++) {
        contact = {
          id: contacts[i].id,
          name: contacts[i].userName
        };

        // HERE WE NEED ONE MORE FILTER PARAMETER INSTEAD OF IF CONDITION TO FILTER BY ACTIVATION STATUS
        if (contacts[i].activated || this.helpnowPage) {
          tempContactList.push(contact);
        }
      }
      this.allContactList = [...this.allContactList, ...tempContactList];
    }
    // THIS MUST BE REMOVED AFTER INTEGRATING API TO GET NAMES FROM ID ARRAY. IT WILL BE AN ISOLATED FUNCTION CALLING ONCE ON PAGE LOAD.
    if (this.firstTimeLoad) {
      this.firstTimeLoad = false;
      this.setPublishDataInUI();
    }
  }

  setPublishDataInUI() {
    if (this.publishValue) {
      if (this.publishValue.serviceGroup) {
        this.publishSurveyToServiceGroup = this.publishValue.serviceGroup;
      }
      if (this.publishValue.contactGroup) {
        this.selectedGroupListIds = this.publishValue.contactGroup;
        for (let i = 0; i < this.publishValue.contactGroup.length; i++) {
          if (this.allGroupList.length > 0) {
            const singleGroup = this.allGroupList.filter(x => x.id == this.publishValue.contactGroup[i]);
            if (typeof singleGroup[0] !== 'undefined') {
              if (!this.selectedGroups.value.includes(singleGroup[0])) {
                this.selectedGroups.patchValue([...this.selectedGroups.value, singleGroup[0].id]);
              }
            }
          }
        }
        this.selectedGroupListIds = this.selectedGroups.value;
      }

      if (this.publishValue.contacts) {
        this.selectedContactListIds = this.publishValue.contacts;
        for (let i = 0; i < this.publishValue.contacts.length; i++) {
          if (this.allContactList.length > 0) {
            const singleContact = this.allContactList.filter(x => x.id == this.publishValue.contacts[i]);
            if (typeof singleContact[0] !== 'undefined') {
              if (!this.selectedContacts.value.includes(singleContact[0].id)) {
                this.selectedContacts.patchValue([...this.selectedContacts.value, singleContact[0].id]);
              }
            }
          }
        }
        this.selectedContactListIds = this.selectedContacts.value;
      }
    }
    if (this.readOnly) {
      this.selectedGroups.disable();
      this.selectedContacts.disable();
    }
    this.spinner.hide();
  }

  savePublishDataObj() {
    if (this.publishSurveyToServiceGroup) {
      this.selectedGroupListIds = [];
      this.selectedContactListIds = [];
    }
    const data = {
      serviceGroup: this.publishSurveyToServiceGroup,
      contactGroup: this.selectedGroupListIds,
      contacts: this.selectedContactListIds
    };
    this.savedData.emit(data);
  }

  error(data) {
    toastr.error(data.message);
    this.loadingContact = false;
    this.loadingGroup = false;
    this.spinner.hide();
  }

  ngOnInit() {
    this.participantGroupLabel = this.refdataService.getLabelByNameInCategory('Participants', 'ParticipantGroup');
    this.participantLabel = this.refdataService.getParticipantLabel();
    if ( !this.participantGroupLabel ) {
      this.participantGroupLabel = 'Participant Group';
    }
  }

}
