import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import * as moment from 'moment';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {Constants} from '../../constants';

declare let toastr;

export interface editAttributeValueModal {
  attributeData;
  contactId;
}

@Component({
  selector: 'edit-attribute-value',
  template: `<div class="modal-dialog">
    <div class="modal-content float-left full-width">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h4 class="modal-title">Update value of {{attributeData.displayName}} ({{attributeData.displayDataType}})</h4>
      </div>
      <div class="modal-body float-left full-width">
        <label class="float-left full-width font-size-16-px">Please
          <span *ngIf="attributeData.dataType == constants.variableTypes.BOOLEAN">select</span>
          <span *ngIf="attributeData.dataType != constants.variableTypes.BOOLEAN">enter</span> value
        </label>
        <custom-data-type-plugin class="float-left padding-top-20-px full-width"
                                 [type]="attributeData.dataType" [value]="attributeData.latestValue"
                                 [format]="attributeData.valueFormat"
                                 [number]="true" (onChange)="onChange($event, 'updatedValue')"
                                 [uploadImageToServer]="saveBtn">
        </custom-data-type-plugin>
      </div>
      <div class="modal-footer float-left full-width">
        <button type="button" [disabled]="saveBtn" class="btn btn-primary" (click)="onClick()">Save</button>
        <button type="button" class="btn btn-default" (click)="close()">Close</button>
      </div>
    </div>
  </div>`,
  providers: [ContactAttributeService, AppRouteChangeSubscriptionService]
})

export class EditAttributeValueModalDialogComponent extends DialogComponent<editAttributeValueModal, Object>
  implements editAttributeValueModal {
  attributeData;
  contactId;
  saveBtn = false;

  constructor(dialogService: DialogService, public constants: Constants, private contactAttributeService: ContactAttributeService,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);
  }

  onClick() {
    if (this.attributeData.dataType === this.constants.questionType.IMAGE) {
      this.saveBtn = true;
    } else {
      if (typeof this.attributeData.updatedValue !== 'undefined' && this.attributeData.updatedValue !== '') {
        this.updateAttributeValue();
      } else {
        return toastr.error('Please provide value to update');
      }
    }
  }

  updateAttributeValue() {
    let key = null;

    switch (this.attributeData.dataType) {
      case this.constants.variableTypes.NUMBER: {
        key = 'doubleValue';
        break;
      }
      case this.constants.variableTypes.BOOLEAN: {
        key = 'booleanValue';
        break;
      }
      case this.constants.variableTypes.TEXT: {
        key = 'stringValue';
        break;
      }
      case this.constants.variableTypes.DATETIME: {
        key = 'dateTimeValue';
        break;
      }
      case this.constants.variableTypes.DATE: {
        key = 'dateValue';
        break;
      }
      case this.constants.variableTypes.TIME: {
        key = 'timeValue';
        break;
      }
      case this.constants.variableTypes.EMAIL: {
        key = 'stringValue';
        break;
      }
      case this.constants.questionType.IMAGE: {
        key = 'stringValue';
        break;
      }
    }


    if ((key === 'timeValue' && this.attributeData.updatedValue !== null) || key !== 'timeValue') {
      this.saveBtn = true;

      const data = [{
        timeStamp: moment().unix() * 1000,
        variableName: this.attributeData.attributeName,
        variableType: this.attributeData.dataType,
        contactId: parseInt(this.contactId, 10),
        variableValue: {
          [key]: this.attributeData.updatedValue
        },
        attributeScope: this.attributeData.scope,
        metadata: null,
        referenceId: this.attributeData.referenceId,
        customProfileAttributeId: this.attributeData.customProfileAttributeId
      }];

      this.contactAttributeService.updateContactAttributeValue(data).subscribe(
        data => this.success(data),
        error => this.error(error)
      );
    } else {
      toastr.error(this.constants.invalidTimeError);
    }
  }

  error(data) {
    this.saveBtn = false;
  }

  success(data) {
    this.saveBtn = false;
    if (data.status === 1) {
      toastr.success(this.constants.updatedSuccess);
      this.result = data;
      this.close();
    } else {
      toastr.error(data.message);
    }
  }

  onChange(value, key) {
    if (this.attributeData.dataType === this.constants.questionType.IMAGE) {
      this.setAndSendImageUrlToAPI(value, key)
    } else {
      this.attributeData[key] = value;
    }
  }

  setAndSendImageUrlToAPI(imageUrl, key) {
    if (imageUrl) {
      this.attributeData[key] = imageUrl;
      if (typeof this.attributeData.updatedValue !== 'undefined' && this.attributeData.updatedValue !== '') {
        this.updateAttributeValue();
      } else {
        return toastr.error('Please provide value to update');
      }
    } else {
      this.saveBtn = false;
    }
  }

  ngOnInit() {
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}
