import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjectService} from '../../services/app.object.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {CommonComponent} from '../../shared/commonComponent';
import {RefdataService} from '../../services/app.refdata.service';
import {AuthenticationHelper} from '../../app.authentication';
import {Constants} from '../../constants';
import {ThemeLoader} from '../../app.component.loader';
import {AppModalService} from '../../services/app.modalService';

declare let toastr;

export interface userSecurityModalDialogModel {
  title: any;
  text: string;
  personId: any;
}

@Component({
  selector: 'userSecurityModal',
  templateUrl: './userSecurityModal.component.html',
  styleUrls: ['../settings/settings.component.scss'],
  providers: [ObjectService, AppRouteChangeSubscriptionService, CommonComponent, RefdataService]
})

export class UserSecurityModalDialogComponent extends
  DialogComponent<userSecurityModalDialogModel, string> implements userSecurityModalDialogModel {
  title: any;
  text: string;
  personId: any;
  name: string;
  securityGroupList = [];
  userSecurityGroupForm: any;
  saveSecurityGroupBtn = false;

  constructor(dialogService: DialogService, fb: FormBuilder, private objectService: ObjectService,
              private authentication: AuthenticationHelper, public constants: Constants, private spinner: ThemeLoader,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService,
              private refdataService: RefdataService,
              private commonComponent: CommonComponent) {
    super(dialogService);
    this.userSecurityGroupForm = fb.group({
      'securityGroups': [[], Validators.compose([Validators.required])],
      });
  }

  onSubmit(values) {
    this.spinner.show();
    this.saveSecurityGroupBtn = true;
    this.submitDataToServer(values);
  }

  private submitDataToServer(values) {
    const data: any = {
      personId: this.personId,
      securityGroupList: values.securityGroups.map(p => p.id)
    };
    this.refdataService.updateUserSecurityGroups(data).subscribe(
      data => this.success(data),
      error => this.error(error)
    );
  }

  private success(data) {
    this.saveSecurityGroupBtn = false;
    let message;

    if (data.status === 1) {
      this.spinner.hide();
      this.result = data;
      this.close();
      if (this.personId !== '' ) {
        message = this.constants.updatedSuccess;
      } else {
        message = this.constants.createdSuccess;
      }
      toastr.success(message);
    } else {
      this.spinner.hide();
      toastr.error(data.message);
    }
  }

  private getSecurityGroups() {
    this.refdataService.getSecurityGroupList().subscribe(
      data => this.getSecurityGroupListSuccess(data),
      error => this.error(error)
    );
  }

  getSecurityGroupListSuccess(data: any) {
    if ( data.status === 1 ) {
      this.securityGroupList = data.body;
    }
  }

  private error(error: any) {
    console.log('Error in getting or saving data');
    this.spinner.hide();
  }

  private getUserSecurityGroupSelections() {
    this.refdataService.getUserSecurityGroups(this.personId).subscribe(
      data => this.setSecurityGroupValuesInForm(data),
      error => this.error(error)
    );
  }

  setSecurityGroupValuesInForm(data) {
    if ( data.status === 1 ) {
      const userSecurityGroups = data.body;
      if ( userSecurityGroups ) {
        this.userSecurityGroupForm.get('securityGroups').patchValue(userSecurityGroups);
      }
    }
    this.spinner.hide();
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.spinner.show();
    this.getSecurityGroups();
    if ( this.personId && this.personId > 0 ) {
      setTimeout(() => {
        this.getUserSecurityGroupSelections();
      }, 250);
    } else {
      this.spinner.hide();
    }
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }


}
