import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {UserService} from '../services/app.user.service';
import {AuthenticationHelper} from '../app.authentication';
import {ThemeLoader} from '../app.component.loader';
import {Constants} from '../constants';
import {Router} from '@angular/router';
import {AppRouteChangeSubscriptionService} from '../services/app.routerChangeSubscription.service';

declare let toastr;

export interface SetStrongPasswordModalDialogModel {

}

@Component({
  selector: 'set-strong-password-modal',
  template: `
    <div class="modal-dialog" id="confirm-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Set Strong Password</h4>
        </div>
        <div class="modal-body">
          <password (onChange)="updatePassword($event)" [submitBtnClick]="changePasswordBtn"></password>
        </div>
      </div>
    </div>`,
  providers: [UserService, AppRouteChangeSubscriptionService]
})

export class SetStrongPasswordModalDialogComponent extends DialogComponent<SetStrongPasswordModalDialogModel, boolean> implements SetStrongPasswordModalDialogModel {
  changePasswordBtn;

  constructor(dialogService: DialogService, private router: Router, private userService: UserService, private authentication: AuthenticationHelper,
              private spinner: ThemeLoader, private constants: Constants,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);
  }

  updatePassword(value: any) {
    this.changePasswordBtn = true;
    this.spinner.show();
    const data = {
      password: value
    };

    this.userService.updatePassword(data).subscribe(
      data => this.updatePasswordSuccess(data),
      error => this.spinner.hide()
    );
  }

  updatePasswordSuccess(data) {
    this.changePasswordBtn = false;
    this.spinner.hide();
    if (data.status == 1) {
      toastr.success(this.constants.updatedSuccess);
      this.authentication.removeLoggedIn();
      this.router.navigate(['login']);
      this.result = true;
      this.close();
    } else {
      toastr.error(data.message);
    }
  }

  cancel() {
    this.result = false;
    this.close();
  }

  ngOnInit() {
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}
