import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable()
export class AppRouteChangeSubscriptionService {
  routeSubscription: Subscription;

  constructor(private router: Router) {
  }

  closeModalOnRouteChange(modal) {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      modal.close();
    });
  }
}
