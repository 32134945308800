import {LayoutComponent} from '../layout/layout.component';

export const routes = [

  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', loadChildren: './login/login.module#LoginModule'},
  {path: 'teams/login', loadChildren: './teamsLogin/teamsLogin.module#TeamsLoginModule'},
  {path: 'register', loadChildren: './register/register.module#RegisterModule'},
  {path: 'recover', loadChildren: './recover/recover.module#RecoverModule'},
  {path: 'setPassword', loadChildren: './setPassword/setPassword.module#SetPasswordModule'},
  {path: 'setPassword/:id', loadChildren: './setPassword/setPassword.module#SetPasswordModule'},
  {path: 'passwordReset/:id', loadChildren: './passwordReset/passwordReset.module#PasswordResetModule'},
  {path: 'passwordReset', loadChildren: './passwordReset/passwordReset.module#PasswordResetModule'},
  {path: 'thankyou', loadChildren: './thankyou/thankyou.module#ThankYouModule'},
  {path: 'teams/surveyList', loadChildren: './teamsSurveyList/teamsSurveyList.module#TeamsSurveyListModule'},
  {path: 'mobileView', loadChildren: './mobileView/mobileView.module#MobileViewModule'},
  {path: 'mobileView/:id', loadChildren: './mobileView/mobileView.module#MobileViewModule'},
  {path: 'mobileView/:id/:type', loadChildren: './mobileView/mobileView.module#MobileViewModule'},
  {
    path: 'softwareServiceAgreement',
    loadChildren: './softwareServiceAgreement/softwareServiceAgreement.module#SoftwareServiceAgreementModule'
  },
  {path: 'consumerTickets', loadChildren: './consumerTickets/consumerTickets.module#ConsumerTicketsModule'},
  {path: 'participantUI', loadChildren: './participantUI/participantUI.module#ParticipantUIModule'},
  {path: 'logout', loadChildren: './logout/logout.module#LogoutModule'},
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'home', loadChildren: './home/home.module#HomeModule'},
    //  {path: 'logout', loadChildren: './logout/logout.module#LogoutModule'},
      {path: 'workflow', loadChildren: './workflow/workflow.module#WorkflowModule'},
      {path: 'workflow/:id', loadChildren: './workflow/workflow.module#WorkflowModule'},
      {path: 'journeydef', loadChildren: './journeydef/journeydef.module#JourneyDefModule'},
      {path: 'journey', loadChildren: './journey/journey.module#JourneyModule'},
      {path: 'settings', loadChildren: './settings/settings.module#SettingModule'},
      {path: 'settings/:id', loadChildren: './settings/settings.module#SettingModule'},
      {path: 'businessAdminSettings/:id', loadChildren: './businessAdminSettings/businessAdminSettings.module#BusinessAdminSettingModule'},
      {path: 'tickets', loadChildren: './tickets/tickets.module#TicketsModule'},
      {path: 'surveyList', loadChildren: './survey/surveyList.module#SurveyListModule'},
      {path: 'unauthorizeAccess', loadChildren: './unauthorizeAccess/unauthorizeAccess.module#UnauthorizeAccessModule'},
      {path: 'userAccount', loadChildren: './userAccountDetails/userAccountDetails.module#UserAccountDetailsModule'},
      {path: 'userAccount/:id', loadChildren: './userAccountDetails/userAccountDetails.module#UserAccountDetailsModule'},
      {path: 'calendar', loadChildren: './calendar/calendar.module#CalendarModule'},
      {path: 'contact', loadChildren: './contact/contact.module#ContactModule'},
      {path: 'helpnow', loadChildren: './helpnow/helpnow.module#HelpNowModule'},
      {path: 'docs', loadChildren: './APIdocs/APIdocs.module#APIdocsModule'},
    ]
  },

  {path: '**', redirectTo: ''}

];
