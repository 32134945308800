import {ChangeDetectorRef, Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {Constants} from '../../constants';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {AppModalService} from '../../services/app.modalService';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';

declare let toastr;

export interface setPipeVariableDefaultValueModal {
  data;
  contentType;
}

@Component({
  selector: 'set-pipe-variable-default-value',
  templateUrl: './setPipeVariableDefaultValue.html',
  styleUrls: ['./setPipeVariableDefaultValue.component.scss'],
  providers: [AppRouteChangeSubscriptionService, ContactAttributeService]
})

export class SetPipeVariableDefaultValueModalDialogComponent extends DialogComponent<setPipeVariableDefaultValueModal, Object> implements setPipeVariableDefaultValueModal {
  data;
  color = 'rgb(51, 51, 51)';
  fontSize = '13px';
  fontWeight = 'normal';
  contentType;
  valueType = {
    DEFAULT: 'DEFAULT',
    CODE: 'CODE'
  };
  exampleExpression = '{$} == true';
  dataExpression = {
    type: this.valueType.DEFAULT,
    value: [{
      expression: null,
      label: null,
      style: {
        color: 'rgb(51, 51, 51)',
        fontWeight: 'normal',
        fontSize: '13px'
      }
    }]
  };
  hideDefaultValueExpression: boolean = false;
  saveButton: boolean = false;


  constructor(dialogService: DialogService, public constants: Constants, private cdrf: ChangeDetectorRef,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService, private contactAttributeService: ContactAttributeService) {
    super(dialogService);
  }

  saveClick() {
    if (this.data.type === this.constants.variableTypes.IMAGE) {
      this.saveButton = true;
    } else {
      if (this.data.type === this.constants.questionType.TIME) {
        if (!(this.data.defaultValue || this.data.defaultValue === 0)) {
          return toastr.error(this.constants.invalidTimeError);
        }
      }
      this.setAttributeValue();
    }
  }

  setAttributeValue() {
    this.convertParamIntoStyleString();
    this.result = this.data;
    this.saveButton = false;
    this.close();
  }

  convertParamIntoStyleString() {
    this.data.fontStyle = 'font-size:' + this.fontSize + ';color:' + this.color + ';font-weight:' + this.fontWeight;
    this.data.expression = JSON.parse(JSON.stringify(this.dataExpression));
    this.data.expression.value.map(single => {
      single.style = 'font-size:' + single.style.fontSize + ';color:' + single.style.color + ';font-weight:' + single.style.fontWeight;
      if (single.expression) {
        single.expression = single.expression.replace(/{\/?[^}]+(}|$)/g, (attribute) => {
          attribute = '{' + this.data.dataAttribute + '}';
          return attribute;
        });
      }
    });
    this.data.expression = JSON.stringify(this.data.expression);
  }

  convertStyleStringIntoParam() {
    if (this.data.fontStyle && this.dataExpression.type == this.valueType.DEFAULT) {
      const fontParams = this.data.fontStyle.split(';');
      if (fontParams && fontParams[0].indexOf('font-size') > -1) {
        this.fontSize = fontParams[0].substring(fontParams[0].indexOf(':') + 1);
        this.color = fontParams[1].substring(fontParams[1].indexOf(':') + 1);
        this.fontWeight = fontParams[2].substring(fontParams[2].indexOf(':') + 1);
      }
    }
    this.dataExpression = this.data.expression ? JSON.parse(this.data.expression) : this.dataExpression;
    if (this.dataExpression.type == this.valueType.CODE) {
      this.dataExpression.value.map(single => {
        let fontParams: any = single.style;
        fontParams = fontParams.split(';');
        if (fontParams && fontParams[0].indexOf('font-size') > -1) {
          single.style = {
            fontSize: fontParams[0].substring(fontParams[0].indexOf(':') + 1),
            color: fontParams[1].substring(fontParams[1].indexOf(':') + 1),
            fontWeight: fontParams[2].substring(fontParams[2].indexOf(':') + 1)
          };
          if (single.expression) {
            single.expression = single.expression.replace(/{\/?[^}]+(}|$)/g, (attribute) => {
              attribute = '{' + this.data.displayName + '}';
              return attribute;
            });
          }
        }
        return single;
      });
    }
  }

  onChange(value, key) {
    if (key == 'defaultValue' && this.data.type === this.constants.questionType.IMAGE) {
      this.setAndSendImageUrlToAPI(value, key);
    } else {
      this.data[key] = value;
    }
  }

  setAndSendImageUrlToAPI(imageUrl, key) {
    this.data[key] = imageUrl;
    this.setAttributeValue();
  }

  setFontWeight(index = null) {
    if (index != null) {
      this.dataExpression.value[index].style.fontWeight = this.dataExpression.value[index].style.fontWeight == 'bold' ? 'normal' : 'bold';
    } else {
      this.fontWeight = this.fontWeight == 'bold' ? 'normal' : 'bold';
    }
  }

  addDefaultValue() {
    const value = {
      expression: null,
      label: null,
      style: {
        color: 'rgb(51, 51, 51)',
        fontWeight: 'normal',
        fontSize: '13px'
      }
    };
    this.dataExpression.value.push(value);
  }

  deleteDefaultValue(item) {
    this.dataExpression.value.splice(item, 1);
  }

  setValueType(value) {
    this.color = 'rgb(51, 51, 51)';
    this.fontSize = '13px';
    this.fontWeight = 'normal';
    this.dataExpression.type = value;
    this.data.defaultValue = '';
    this.dataExpression.value = [{
      expression: null,
      label: null,
      style: {
        color: 'rgb(51, 51, 51)',
        fontWeight: 'normal',
        fontSize: '13px'
      }
    }];
  }

  getAttributeDefaultValue() {
    const attributeId = this.data.dataAttribute.split(':')[2];
    if (attributeId && this.data.defaultValue == '') {
      this.contactAttributeService.getContactAttributeDetails(attributeId).subscribe(
        data => this.getDefaultValueSuccess(data)
      )
    }
  }

  getDefaultValueSuccess(data) {
    if (data.status == 1) {
      this.data.defaultValue = data.body.defaultValue;
    }
  }

  ngOnInit() {
    AppModalService.openModal(this);
    const serverUrl = this.constants.serverAPIURl.split('.')[0];
    if (this.constants.allowLocalServers.indexOf(serverUrl) > -1) {
      this.hideDefaultValueExpression = true;
    }
    this.convertStyleStringIntoParam();
    this.getAttributeDefaultValue();
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterViewChecked() {
    this.cdrf.detectChanges();
  }

}
