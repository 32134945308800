import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[autoFocusInput]'
})

export class AutoFocusInputDirective {

  currentElement;

  constructor(el: ElementRef) {
    this.currentElement = el;
  }

  ngAfterViewInit() {
    this.currentElement.nativeElement.focus();
  }
}
