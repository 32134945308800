import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../constants';
import {BlankSpaceValidator} from '../../validators/blankSpace.validator';
import {TicketService} from '../../services/app.ticket.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {AppModalService} from '../../services/app.modalService';

declare let toastr;

export interface createTicketModalDialogModel {
  contactId;
}

@Component({
  selector: 'create-ticket',
  templateUrl: './createTicketModal.component.html',
  providers: [TicketService, AppRouteChangeSubscriptionService]
})
export class CreateTicketModalDialogComponent extends DialogComponent<createTicketModalDialogModel, string> implements createTicketModalDialogModel {
  title: any;
  ticketForm: FormGroup;
  subject: AbstractControl;
  body: AbstractControl;
  saveBtn = false;
  contactId;

  constructor(dialogService: DialogService, fb: FormBuilder, private ticketService: TicketService, private constants: Constants,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);

    this.ticketForm = fb.group({
      'subject': [null, Validators.compose([Validators.required, BlankSpaceValidator.validate])],
      'body': [null, Validators.compose([Validators.required, BlankSpaceValidator.validate])]
    });
    this.body = this.ticketForm.controls['body'];
    this.subject = this.ticketForm.controls['subject'];
  }

  onSubmit(values: any) {
    this.saveBtn = true;
    values.consumerId = this.contactId;
    this.ticketService.createTicket(values).subscribe(
      data => this.success(data),
      error => this.error(error)
    );
  }

  success(data) {
    this.saveBtn = false;
    if (data.status === 1) {
      this.result = data;
      this.close();
      toastr.success(this.constants.messageSentSuccess);
    } else {
      toastr.error(data.message);
    }
  }

  error(error) {
    this.saveBtn = false;
    toastr.error(this.constants.serverError);
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}
