import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CropperSettings, ImageCropperComponent} from 'ngx-img-cropper';
import {UploadService} from '../../services/app.uploadFile.service';
import {ThemeLoader} from '../../app.component.loader';
import {FormControl} from '@angular/forms';

declare let toastr;

@Component({
  selector: 'custom-image-cropper',
  templateUrl: './customImageCropper.component.html',
  styleUrls: ['./customImageCropper.component.scss'],
  providers: [UploadService]
})
export class CustomImageCropperComponent implements OnInit {
  @Output() emitImageUrl: EventEmitter<string> = new EventEmitter<string>();
  data: any = {};
  cropperSettings: CropperSettings;
  @ViewChild('cropper', undefined)
  @ViewChild('imageInputRef') imageInputRef: ElementRef;
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  defaultPicture = '';
  imageInputControl: FormControl = new FormControl(null);
  @Input('width') width = 100;
  @Input('height') height = 100;
  @Input('canvasWidth') canvasWidth = 250;
  @Input('canvasHeight') canvasHeight = 220;
  @Input('required') required = false;
  @Input('businessAccountId') businessAccountId = null;
  isRatioProvided = false;

  constructor(private uploadService: UploadService, private spinner: ThemeLoader) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.rounded = false;
  }

  @Input()
  set callImageUploadAPI(value) {
    if (value) {
      this.uploadFileToServer();
    }
  }

  @Input()
  set defaultValue(value) {
    if (value) {
      this.defaultPicture = value;
    }
  }

  @Input()
  set ratio(value) {
    if (value) {
      // If ratio is provided in 'width:height' string form
      if (value.toString().indexOf(':') > -1) {
        value = value.split(':');
        value = value[0] / value[1];
      }
      this.isRatioProvided = true;
      this.cropperSettings.preserveSize = true;
      this.cropperSettings.compressRatio = 0.5;
      this.cropperSettings.width = this.width;
      this.cropperSettings.height = this.height / value;
      this.cropperSettings.croppedWidth = this.width;
      this.cropperSettings.croppedHeight = this.height / value;
    }
  }

  fileChangeListener($event) {
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    const that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      image.onload = () => {
        that.cropper.setImage(image);
      };
    };
    if (typeof file !== 'undefined') {
      myReader.readAsDataURL(file);
    }
  }

  uploadFileToServer() {
    if (this.data.image) {
      const imageBase64 = this.data.image;
      let array = imageBase64.split(','), mime = array[0].match(/:(.*?);/)[1], bstr = atob(array[1]), n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], 'image.jpg', {type: mime});

      if ( this.businessAccountId ) {
        console.log('upload image to account = ' + this.businessAccountId);
        this.uploadService.uploadImageToAccount(this.businessAccountId, file).subscribe(
          data => this.getUploadedFileData(data),
          error => this.error(error)
        );
      } else {
        console.log('upload image to default user account');
        this.uploadService.uploadImage(file).subscribe(
          data => this.getUploadedFileData(data),
          error => this.error(error)
        );
      }
    } else {
      this.emitImageUrl.emit(this.defaultPicture);
    }
  }

  getUploadedFileData(data) {
    if (data.status === 1) {
      this.defaultPicture = data.body.url;
      this.emitImageUrl.emit(this.defaultPicture);
      this.deleteCurrentActiveImage();
    } else {
      this.emitImageUrl.emit(null);
      toastr.error(data.message);
    }
  }

  openImageInputDialog() {
    this.imageInputRef.nativeElement.click();
  }

  error(error) {
    this.emitImageUrl.emit(null);
    this.spinner.hide();
    toastr.error(error.message);
  }

  deleteCurrentActiveImage() {
    delete this.data.image;
    this.imageInputControl.setValue(null);
  }

  ngOnInit() {
    this.cropperSettings.canvasWidth = this.canvasWidth;
    this.cropperSettings.canvasHeight = this.canvasHeight;
    this.cropperSettings.minWidth = this.width;
    this.cropperSettings.minHeight = this.height;

    if (!this.isRatioProvided) {
      this.cropperSettings.width = this.width;
      this.cropperSettings.height = this.height;
      this.cropperSettings.croppedWidth = this.width;
      this.cropperSettings.croppedHeight = this.height;
    }
  }
}
