import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjectService} from '../../services/app.object.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {CommonComponent} from '../../shared/commonComponent';
import {AuthenticationHelper} from '../../app.authentication';
import {Constants} from '../../constants';
import {ThemeLoader} from '../../app.component.loader';
import {AppModalService} from '../../services/app.modalService';
import {UserService} from '../../services/app.user.service';

declare let toastr;

export interface ChangeUserPwModalDialogModel {
  title: any;
  personId: any;
}

@Component({
  selector: 'changeUserPwModal',
  templateUrl: './changeUserPwModal.component.html',
  styleUrls: ['../settings/settings.component.scss'],
  providers: [ObjectService, AppRouteChangeSubscriptionService, CommonComponent, UserService]
})

export class ChangeUserPwModalDialogComponent extends DialogComponent<ChangeUserPwModalDialogModel, string>
  implements ChangeUserPwModalDialogModel {

  title: any;
  personId: any;
  name: string;
  changeUserPwForm: any;
  saveUserPwBtn = false;
  constructor(dialogService: DialogService, fb: FormBuilder, private objectService: ObjectService,
              private authentication: AuthenticationHelper, public constants: Constants, private spinner: ThemeLoader,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService,
              private userService: UserService,
              private commonComponent: CommonComponent) {
    super(dialogService);
  }

  updatePassword(value: any) {
    this.saveUserPwBtn = true;
    this.spinner.show();
    const data = {
      personId: this.personId,
      password: value
    }
    this.userService.updatePasswordForUser(data).subscribe(
      data => this.updatePasswordSuccess(data),
      error => this.error(error)
    );
  }

  private updatePasswordSuccess(data: any) {
    this.saveUserPwBtn = false;
    this.spinner.hide();
    if ( data.status === 1 ) {
      toastr.success('Password changed');
      this.close();
    } else {
      toastr.error(data.message);
    }
  }

  private error(error: any) {
    console.log('Error in saving new password data');
    this.spinner.hide();
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }

}

