import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AppModalService} from '../../services/app.modalService';

export interface AlertModel {
  title: string;
  message: string;
}

@Component({
  selector: 'confirm',
  template: `
    <div class="modal-dialog" id="confirm-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="close()" >&times;</button>
          <h4 class="modal-title">{{title || 'Confirm'}}</h4>
        </div>
        <div class="modal-body">
          <p [innerHTML]="message | safeHtml"></p>
        </div>
        <div class="modal-footer">
          <button autoFocusInput type="button" class="btn btn-default" (click)="cancel()">OK</button>
        </div>
      </div>
  </div>`
})

export class AlertModalDialogComponent extends DialogComponent<AlertModel, boolean> implements AlertModel {
  title: string;
  message: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  cancel() {
    this.result = true;
    this.close();
  }

  ngOnInit() {
    AppModalService.openModal(this);
  }

}
