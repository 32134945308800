import {fromEvent as observableFromEvent, merge as observableMerge, Observable, of as observableOf} from 'rxjs';

import {mapTo} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RefdataService} from '../services/app.refdata.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [RefdataService]
})
export class LayoutComponent implements OnInit {

  online$: Observable<boolean>;
  isServiceGroupLoaded = false;
  pageBackgroundStyle = '';

  constructor(private router: Router, private refdataService: RefdataService, private sanitizer: DomSanitizer) {
    this.online$ = observableMerge(
      observableOf(navigator.onLine),
      observableFromEvent(window, 'online').pipe(mapTo(true)),
      observableFromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.pageBackgroundStyle = this.refdataService.getCustomStyleCss('PageBackground');
    if ( !this.pageBackgroundStyle ) {
      this.pageBackgroundStyle = 'background-color: #e3f3f6;';
    }
  }

  checkServiceGroupLoaded(value) {
    this.isServiceGroupLoaded = value;
  }

  getPageBackgroundStyle() {
    return this.sanitizer.bypassSecurityTrustStyle(this.pageBackgroundStyle);
  }

  ngOnInit() {
    if (localStorage.getItem('auth_token') == null || localStorage.getItem('auth_token') === 'undefined') {
      this.router.navigate(['/login']);
    }
  }

}
