import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SurveyService {

  private serviceGroupListUrl = 'api/businessunit/list';
  //  private serviceGroupListUrl = 'api/businessUser/product/list';
  private survey = 'api/survey/v1/';
  private surveyPrefix = this.survey + 'product/' + this.authentication.getSelectedObject() + '/business';
  private surveyProductPrefix = this.survey + 'product/' + this.authentication.getSelectedObject();
  private surveyTrackingPrefix = 'api/surveyTracking/';

  constructor(private httpClient: HttpClientHelper, private http: HttpClient, private authentication: AuthenticationHelper) {

  }

  getListOfObjects(params: any = {}) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;

      params.name = '';
      params.orderBy = 'ASC';
      params.sortBy = 'name';
    }

    return this.httpClient.get(this.serviceGroupListUrl, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * get paginated list of survey tracking data for a participant
   * @param data
   */
  getParticipantSurveyTrackingList(data) {
    const url = this.surveyTrackingPrefix + 'list';
    return this.httpClient.post(url, data).pipe(
      catchError(this.handleError)
    );
  }

  getParticipantSurveyStats(personId) {
    const url = this.surveyTrackingPrefix + 'statistics/' + personId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSurveyTracking(personId) {
    const url = this.httpClient.getApiBaseUrl() + this.surveyTrackingPrefix + 'export/' + personId;
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(this.exportSurveyTrackingData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSurveyTrackingData(res) {
    return res;
  }

  surveySave(data) {
    const url = this.surveyPrefix;
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  surveyUpdate(data) {
    const url = this.surveyPrefix;
    return this.httpClient.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  surveySummaryUpdate(data) {
    const url = this.surveyProductPrefix + '/summary/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyListWithFilter(data: any = '') {
    if (!data) {
      data = {
        length: 0
      }
    }
    const offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    // set the timezone id
    data.zoneId = (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
    const url = this.surveyPrefix + '/list';

    // To be removed after applying pagination in all places of API call
    if (!data.pageNumber) {
      data.pageNumber = 1;
      data.pageSize = 100000;
    }

    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyDetails(surveyId) {
    const url = this.surveyPrefix + '/' + surveyId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  surveyClone(data) {
    const url = this.surveyPrefix + '/clone';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  surveyPublishSave(data) {
    const url = this.surveyPrefix + '/publish';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyPublishData(surveyId) {
    const url = this.surveyPrefix + '/' + surveyId + '/publish';
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyAnalyzeData(surveyId, data) {
    const url = this.surveyPrefix + '/' + surveyId + '/analyze';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactListForAnalyze(surveyId) {
    const url = this.surveyPrefix + '/' + surveyId + '/analyze/contact';
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  surveyChangeStatus(data) {
    const url = this.surveyPrefix + '/status';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSurvey(surveyId) {
    const url = this.surveyPrefix + '/' + surveyId + '/export';
    return this.httpClient.post(url, '')
      .pipe(
        catchError(this.handleError)
      );
  }

  deploySurvey(surveyId) {
    const url = this.surveyProductPrefix + '/' + surveyId + '/deploy';
    return this.httpClient.post(url, '')
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyCardDeck(deckId, personId) {
    const url = this.surveyProductPrefix + '/surveyCard/deck/' + deckId + '/' + personId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveSurveyCardResponses(data: any) {
    const url = 'api/survey/v1/response/surveyCards/save';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSurveyResponse(surveyResponseId) {
    const url = this.httpClient.getApiBaseUrl() + 'api/survey/v1/response/export/' + surveyResponseId;
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(this.exportSurveyResponseData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSurveyResponseData(res) {
    return res;
  }

  exportMultipleSurveyResponses(params) {
    const url = this.httpClient.getApiBaseUrl() + 'api/survey/v1/response/export/multiple';
    return this.http.post(url, params, {responseType: 'blob'})
      .pipe(
        map(this.exportSurveyResponseData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  reportSurvey(surveyId) {
    const url = this.surveyPrefix + '/' + surveyId + '/report';
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFormattedSurveyDetails(data) {
    const url = 'api/survey/v1/workflow/questions';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyScheduledJob(surveyId, quartzJobPagination) {
    const url = 'api/quartz/v1/survey/scheduler/' + surveyId + '?page=' + quartzJobPagination.currentPage + '&size=' + quartzJobPagination.size;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  replaceHTMLParticipantAttributeWithActualValue(data) {
    const url = this.survey + 'content';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveyTitle(data) {
    const url = this.survey + 'title';
    return this.httpClient.post(url, data).pipe(
      map(this.extractResponse),
      catchError(this.handleError), );
  }

  getSurveyListWithRespectToContact(contactId) {
    const url = this.survey + 'agentSurvey/contact/' + contactId + '/list';
    return this.httpClient.get(url).pipe(
      map(this.extractResponse),
      catchError(this.handleError), );
  }

  getPersonSurveyList(personId, params) {
    const url = 'api/survey/v1/summaries/' + personId;
    return this.httpClient.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonSurveyResponseSummaries(personId) {
    const url = 'api/survey/v1/response/summaries/' + personId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSurveySummariesForServiceGroup(serviceGroupId) {
    const url = 'api/survey/v1/summaries/serviceGroup/' + serviceGroupId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonSurveyResults(surveyResponseId) {
    const url = 'api/survey/v1/response/' + surveyResponseId;
    return this.httpClient.get(url).pipe(
      map(this.extractResponse),
      catchError(this.handleError), );
  }

  getSurveyQuestionSummaries(params) {
    const url = this.survey + 'questions/list';
    return this.httpClient.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAgentSurveyFromList(agentSurveyId) {
    const url = this.survey + 'agentSurvey/' + agentSurveyId;
    return this.httpClient.Delete(url).pipe(
      map(this.extractResponse),
      catchError(this.handleError), );
  }


  extractResponse(res: JSON) {
    return res;
  }

  handleError(error: any) {
    return throwError(error);
  }


}
