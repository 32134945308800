import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Constants} from '../../constants';
import {AuthenticationHelper} from '../../app.authentication';
import {ThemeLoader} from '../../app.component.loader';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';
import {CommonComponent} from '../commonComponent';

declare let $;

@Component({
  selector: 'profile-attribute-select',
  templateUrl: './profileAttributeSelect.component.html',
  styleUrls: ['./customSelect.component.scss'],
  providers: [ContactAttributeService, CommonComponent],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'handleClick($event)',
  }
})

export class ProfileAttributeSelectComponent implements AfterViewInit {

  @Input('allowMultiple') allowMultiple;
  @Input('showOnlyTimeSeries') showOnlyTimeSeries;
  @Output() setControlValue: EventEmitter<any> = new EventEmitter<any>();
  attributeList = [];
  selectedAttributes: FormControl = new FormControl([]);
  selectedAttributeList = [];
  preselectedAttributeIDs = [];

  constructor(private contactAttributeService: ContactAttributeService,
              public constants: Constants, public authentication: AuthenticationHelper, public spinner: ThemeLoader,
              public commonComponent: CommonComponent) {

  }

  @Input()
  set setPreselectedOption(value) {
    console.log('setPreselectedOption called for value = ' + value);
    if ( this.allowMultiple && value ) {
      //  value is template
      const templateJSON = JSON.parse(value);
      this.preselectedAttributeIDs = [];
      if ( templateJSON.attributes ) {
        const templateAttList = templateJSON.attributes;
        for ( const templateAtt of templateAttList ) {
          this.preselectedAttributeIDs.push(templateAtt.id);
        }
        console.log('+++ found ' + this.preselectedAttributeIDs.length + ' existing selected attributes');
      } else {
        console.log('+++ no attributes in template');
      }
    }
  }

  @Input()
  set setList(value) {
    console.log('setList called for value = ' + value);
    if (value) {
      this.getAttributeList();
    }
  }

  private getAttributeList() {
    // console.log('get attribute list for profileAttributeSelect');
    const params = {
      dataType: [''],
      name: '',
      orderBy: 'ASC',
      pageNumber: 1,
      pageSize: 10000,
      scope: null,
      sortBy: 'attributeName',
      type: this.showOnlyTimeSeries ? ['TIMELINE'] : ['']
    };
    this.contactAttributeService.getAllCustomAttributes(params).subscribe(
      data => this.getAttributeListSuccess(data),
      error => this.spinner.hide()
    );
  }

  private getAttributeListSuccess(data) {
    if ( data.status === 1 ) {
      this.attributeList = data.body.elements;
      if ( this.attributeList && this.attributeList.length > 0 && this.preselectedAttributeIDs.length > 0 ) {
        this.selectedAttributeList = [];
        for ( const attId of this.preselectedAttributeIDs ) {
          const att = this.attributeList.find(a => a.id === attId);
          if ( att ) {
             this.selectedAttributeList.push(att);
          }
        }
        console.log('+++ found ' + this.selectedAttributeList.length + ' selected attributes');
        this.selectedAttributes.patchValue(this.selectedAttributeList);
      }
    }
  }

  removeAttribute(value: any) {
    const index = this.selectedAttributeList.indexOf(value.id);
    if (index > -1) {
      this.selectedAttributeList.splice(index, 1);
      this.emitSelectedListChanges();
    }
  }

  setSelectedAttributes(event: any) {
    this.selectedAttributeList = this.selectedAttributes.value;
    this.emitSelectedListChanges();
  }

  emitSelectedListChanges() {
    const selectedList = [];
    if ( this.selectedAttributeList.length > 0 ) {
      for ( const att of this.selectedAttributeList ) {
        const attData = { id: att.id, name: att.displayName };
        selectedList.push(attData);
      }
    }
    const templateData = JSON.stringify({ attributes: selectedList });
    this.setControlValue.emit(templateData);
  }

  handleClick($event: any) {

  }

  ngAfterViewInit() {

  }



}
