import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class MessageBankService {

  private httpClient;
  private messageBankUrlPrefix = 'api/messageBank/';
  private serviceGroupUrl = 'serviceGroup/' + this.authentication.getSelectedObject();

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper,
              private http: HttpClient) {
    this.httpClient = httpClient;
  }

  addMessageBankFromImport(data) {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/import';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  addMessageBank(data) {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMessageBank(data) {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllMessageBanks() {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/getAll';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getMessageBanksForParticipant(participantId) {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/contact/' + participantId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getMessageBankTypeList() {
    const url = this.messageBankUrlPrefix + this.serviceGroupUrl + '/types';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteMessageBank(messageBankId): Observable<any> {
    const url = this.messageBankUrlPrefix + 'delete/' + messageBankId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSingleMessageBank(messageBankId): Observable<any> {
    const url = this.messageBankUrlPrefix + 'details/' + messageBankId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportMessageBank(messageBankId) {
    const url = this.httpHelper.getApiBaseUrl() + this.messageBankUrlPrefix + 'export/' + messageBankId;
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(this.exportMessageBankData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  exportMessageBankData(res) {
    return res;
  }

  getMessageBankMessagesPaginated(messageBankId, params) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
    }
    const url = this.messageBankUrlPrefix + 'messages/page/' + messageBankId;
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getMessagesByMessageBankType(params) {
    const url = this.messageBankUrlPrefix + 'select/messages/bytype';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllMessagesInBank(messageBankId) {
    const url = this.messageBankUrlPrefix + 'messages/all/' + messageBankId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSingleMessageDetails(messageId: any) {
    const url = this.messageBankUrlPrefix + 'message/details/' + messageId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addMessageBankMessage(data) {
    const url = this.messageBankUrlPrefix + 'message/create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  addMessageToBankByType(data) {
    const url = this.messageBankUrlPrefix + 'message/create/bytype';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMessageBankMessage(data) {
    const url = this.messageBankUrlPrefix + 'message/update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  rateMessageBankMessage(data) {
    const url = this.messageBankUrlPrefix + 'message/rate';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteMessageBankMessage(messageId) {
    const url = this.messageBankUrlPrefix + 'message/delete/' + messageId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    return throwError(error);
  }



}
