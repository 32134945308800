import {Component} from '@angular/core';

@Component({
  selector: 'micro-spinner',
  template: '<img class="micro-spinner" src="../../assets/img/spinner.gif">',
})

export class MicroSpinnerComponent {

}
