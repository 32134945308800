import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import {HttpClient} from '@angular/common/http';
import 'rxjs';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HelpNowService {
  private httpClient;
  private helpNowVersionOnePrefix = 'api/helpnow/v1/'; // old
  private resourcePackagePrefix = 'api/resourcePackage/'; // new
  private supportUrlPrefix = 'api/supporturl/v1/';
  private emergencyContactOnePrefix = 'api/emergencycontact/v1/';
  private safetyPlanOnePrefix = 'api/safetyplan/v1/';
  private product: string = 'product/' + this.authentication.getSelectedObject();
  private resourceDocumentPrefix = 'api/resourcePackage/' + this.product + '/document';

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper) {
    this.httpClient = httpClient;
  }

  getAllHelpNow(): Observable<any> {
    // const url = this.helpNowVersionOnePrefix + this.product;
    const url = this.resourcePackagePrefix + this.product;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getHelpNow(helpNowId): Observable<any> {
    const url = this.resourcePackagePrefix + 'details/' + helpNowId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactHelpNow(contactId): Observable<any> {
    const url = this.resourcePackagePrefix + this.product + '/' + 'contact/' + contactId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getParticipantSafetyPlan(participantId) {
    const url = this.resourcePackagePrefix + this.product + '/safetyPlan/participant/' + participantId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addHelpNow(data) {
    const url = this.resourcePackagePrefix + this.product;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateHelpNow(data) {
    const url = this.resourcePackagePrefix + this.product;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteHelpNow(helpNowId) {
    const url = this.resourcePackagePrefix + 'delete/' + helpNowId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addHelpNowForContact(data) {
    const url = this.resourcePackagePrefix + this.product + '/suggest';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  removeHelpNowForContact(data) {
    const url = this.resourcePackagePrefix + this.product + '/remove';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceModelList(modelName) {
    const url = this.resourcePackagePrefix + this.product + '/resourceModels/' + modelName;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceItemDetails ( itemId) {
    const url = this.resourcePackagePrefix + 'item/details/' + itemId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Additional resources include support URLs, tickers, and documents that belong to a resource package for a user.
   * Result of query is a list of id - name - url triples.
   * @param personId
   */
  getAdditionalResourcesForPerson ( personId ) {
    const url = this.resourcePackagePrefix + this.product + '/additionalResources/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createResourceItem(data) {
    const url = this.resourcePackagePrefix + 'item/create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateResourceItem(data) {
    const url = this.resourcePackagePrefix + 'item/update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteResourceItem(resourceItemId) {
    const url = this.resourcePackagePrefix + 'item/delete/' + resourceItemId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Emergency Contact Service

  getAllEmergencyContact(): Observable<any> {
    const url = this.emergencyContactOnePrefix + this.product;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getParticipantEmergencyContacts(participantId) {
    const url = this.emergencyContactOnePrefix + this.product + '/participant/' + participantId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getParticipantMainEmergencyContact(participantId) {
    const url = this.emergencyContactOnePrefix + this.product + '/participant/' + participantId + '/main';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getMobileViewEmergencyContacts(participantId) {
    const url = this.emergencyContactOnePrefix + this.product + '/mobileview/' + participantId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmergencyContactTypeList() {
    const url = this.emergencyContactOnePrefix + this.product + '/types';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  searchMobileViewEmergencyContacts(participantId, searchText) {
    const params = {
      personId: participantId,
      searchText: searchText,
      contactTypeName: null,
      personalOnly: false
    };
    const url = this.emergencyContactOnePrefix + this.product + '/mobileview/search';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  searchParticipantEmergencyContacts ( participantId, searchText, contactTypeName ) {
    const params = {
      personId: participantId,
      searchText: searchText,
      contactTypeName: contactTypeName,
      personalOnly: true
    };
    const url = this.emergencyContactOnePrefix + this.product + '/mobileview/search';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmergencyContactDetails(contactId) {
    const url = this.emergencyContactOnePrefix + this.product + '/' + contactId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addEmergencyContact(data) {
    const url = this.emergencyContactOnePrefix + this.product;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEmergencyContact(data) {
    const url = this.emergencyContactOnePrefix + this.product;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEmergencyContact(emergencyContactId) {
    const url = this.emergencyContactOnePrefix + this.product + '/' + emergencyContactId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Document service - new
  getAllResourceDocuments(): Observable<any> {
    const url = this.resourceDocumentPrefix + '/list';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addResourceDocument(data) {
    const url = this.resourceDocumentPrefix + '/create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateResourceDocument(data) {
    const url = this.resourceDocumentPrefix + '/update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteResourceDocument(documentId) {
    const url = this.resourceDocumentPrefix + '/delete/' + documentId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Document Service - old
  getAllSafetyPlan(): Observable<any> {
    const url = this.safetyPlanOnePrefix + this.product;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addSafetyPlan(data) {
    const url = this.safetyPlanOnePrefix + this.product;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSafetyPlan(data) {
    const url = this.safetyPlanOnePrefix + this.product;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSafetyPlan(safetyPlanId) {
    const url = this.safetyPlanOnePrefix + this.product + '/' + safetyPlanId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addSupportUrl(data) {
    const url = this.supportUrlPrefix + this.product;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSupportUrl(data) {
    const url = this.supportUrlPrefix + this.product;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSupportUrl(supportUrlId) {
    const url = this.supportUrlPrefix + this.product + '/' + supportUrlId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllSupportUrl() {
    const url = this.supportUrlPrefix + this.product;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  extractResponse(res: JSON) {
    return res;
  }

  handleError(error: any) {
    return throwError(error);
  }


}
