import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonComponent} from '../../shared/commonComponent';

@Component({
  selector: 'duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss']
})
export class DurationComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() eventData: any;
  @Output() onSelectionTime: EventEmitter<any>;

  constructor(public fb: FormBuilder, private commonComponent: CommonComponent) {
  }

  addDuration() {
    const control = <FormArray>this.group.controls['durationsFormArray'];
    control.push(this.initDurations());
  }

  deleteDuration(durationIndex) {
    const control = <FormArray>this.group.controls['durationsFormArray'];
    control.removeAt(durationIndex);
  }

  initDurations() {
    return this.fb.group({
      'fromTime': [null, Validators.compose([Validators.nullValidator, Validators.required])],
      'toTime': [null, Validators.compose([Validators.nullValidator, Validators.required])]
    });
  }

  getFormArrayElement(value) {
    return this.fb.group({
      'fromTime': [value.fromTime, Validators.compose([Validators.nullValidator, Validators.required])],
      'toTime': [value.toTime, Validators.compose([Validators.nullValidator, Validators.required])]
    })
  }

  setValueInForm() {
    const durationsFormArray = <FormArray>this.group.controls['durationsFormArray'];
    let durationFormGroup: FormGroup;
    let durationData = this.eventData.duration || [];
    if (durationData.length > 0) {
      durationData = this.eventData.duration.map((a) => {
        const fromTime = this.commonComponent.convertMilliSecondsIntoTime(a.startTime);
        const toTime = this.commonComponent.convertMilliSecondsIntoTime(a.endTime);
        return {fromTime: fromTime, toTime: toTime}
      });
      durationData.forEach((value) => {
        durationFormGroup = this.getFormArrayElement(value);
        durationsFormArray.push(durationFormGroup);
      });
    } else {
      durationFormGroup = this.initDurations();
      durationsFormArray.push(durationFormGroup);
    }
  }

  ngOnInit() {
    this.group.addControl('durationsFormArray', this.fb.array([]));
    this.setValueInForm();
  }
}
