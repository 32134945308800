import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationHelper} from '../app.authentication';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService {
  private httpClient;
  private loginUrl = 'api/login';
  private logoutUrl = 'logout';
  private userForgotUrl = 'api/businessEmployees/reset_password/init';
  private accountDetails = 'api/account';
  private businessRegisterUrl = 'api/register/business';
  private activateRegisterAccountUrl = 'api/activate';
  private updateUserAccountDetailsUrl = 'api/businessEmployees';
  private passwordResetUrl = 'api/businessEmployees/reset_password/finish';
  private changePasswordUrl = 'api/businessEmployees/change_password';
  private businessEmployeesUrl = 'api/businessEmployees';
  private businessUrl = 'api/product/';
  private userLogUrl = 'api/userLog/';

  constructor(httpClient: HttpClientHelper, private http: HttpClient, private authentication: AuthenticationHelper) {
    this.httpClient = httpClient;
  }

  userLogin(data): Observable<any> {
    return this.httpClient.postPreLogin(this.loginUrl, data)
  }

  userLoginWithUUID(data): Observable<any> {
    const url = 'api/loginWithUUID';
    return this.httpClient.postPreLogin(url, data)
  }

  userLoginWithMobileToken(token): Observable<any> {
    const url = 'api/loginWithMobileToken/' + token;
    return this.httpClient.getPreLogin(url);
  }

  addUserLogAction (personId, actionType): Observable<any> {
    const url = this.userLogUrl + 'create/' + personId + '/' + actionType;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  userRegister(data): Observable<any> {
    return this.httpClient.postPreLogin(this.businessRegisterUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  activateRegisterAccount(data): Observable<any> {
    return this.httpClient.postPreLogin(this.activateRegisterAccountUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  // For signup service
  userLogout(data): Observable<any> {
    return this.httpClient.postPreLogin(this.logoutUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  passwordReset(data): Observable<any> {
    return this.httpClient.postPreLogin(this.passwordResetUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Send Forgot Password Link
  userForgot(data): Observable<any> {
    return this.httpClient.postPreLogin(this.userForgotUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserAccountDetails(): Observable<any> {
    return this.httpClient.get(this.accountDetails)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUserAccount(data): Observable<any> {
    return this.httpClient.put(this.updateUserAccountDetailsUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePassword(data): Observable<any> {
    return this.httpClient.post(this.changePasswordUrl, data)
  }

  updatePasswordForUser(data): Observable<any> {
    const changeUserPwUrl = this.businessEmployeesUrl + '/changepw/person';
    return this.httpClient.post(changeUserPwUrl, data);
  }

  getAllBusinessEmployees(isUserSettings: boolean) {
    let url = this.businessEmployeesUrl + '?all=1';
    if (isUserSettings) {
      const productId = this.authentication.getSelectedObject();
//      url = this.businessUrl + productId + '/businessEmployees/all';
      url = this.businessUrl + productId + '/persons/all';
    }
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessEmployeesFiltered(data) {
    const productId = this.authentication.getSelectedObject();
    const url = this.businessUrl + productId + '/persons/filtered';
    if (!data.pageNumber) {
      data.pageNumber = 1;
      data.pageSize = 100000;
    }
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserLogEntries(params): Observable<any> {
    const url = this.userLogUrl + 'query/list'
    return this.httpClient.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportUserLogEntries(personId) {
    const url = this.httpClient.getApiBaseUrl() + this.userLogUrl + 'export/' + personId;
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(this.exportUserLoginData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  exportUserLoginData(res) {
    return res;
  }

  getServiceGroupLogEntries(params): Observable<any> {
    const url = this.userLogUrl + 'query/byuser'
    return this.httpClient.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Export team member logins for service group
   */
  exportServiceGroupTeamMemberLogins(exportingPersonId) {
    const url = this.httpClient.getApiBaseUrl() + this.userLogUrl + 'serviceGroup/' + this.authentication.getSelectedObject() + '/teamMembers/export/' + exportingPersonId;
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(this.exportUserLoginData)
      )
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessEmployees(): Observable<any> {
    return this.httpClient.get(this.businessEmployeesUrl)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTeamMembersForServiceGroup() {
    const url = this.businessUrl + this.authentication.getSelectedObject() + '/teamMembers/all';
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private extractResponse(res: JSON) {
    return res;
  }

  private handleError(error: any) {
    return throwError(error);
  }

}
