import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {Constants} from '../../constants';

declare let toastr;

export interface editVariableValueModal {
  variableName;
  dataType;
  latestValue;
  valueFormat;
}

@Component({
  selector: 'edit-variable-value',
  template: `<div class="modal-dialog">
    <div class="modal-content float-left full-width">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h4 class="modal-title">Update value of {{variableName}}</h4>
      </div>
      <div class="modal-body float-left full-width">
        <label class="float-left full-width font-size-16-px">Please
          <span *ngIf="dataType == constants.variableTypes.BOOLEAN">select</span>
          <span *ngIf="dataType != constants.variableTypes.BOOLEAN">enter</span> value
        </label>
        <custom-data-type-plugin class="float-left padding-top-20-px full-width"
                                 [type]="dataType" [value]="latestValue"
                                 [format]="valueFormat"
                                 [number]="true" (onChange)="onChange($event, 'updatedValue')"
                                 [uploadImageToServer]="saveBtn">
        </custom-data-type-plugin>
      </div>
      <div class="modal-footer float-left full-width">
        <button type="button" [disabled]="saveBtn" class="btn btn-primary" (click)="onClick()">Save</button>
        <button type="button" class="btn btn-default" (click)="close()">Close</button>
      </div>
    </div>
  </div>`,
  providers: [ContactAttributeService, AppRouteChangeSubscriptionService]
})

export class EditVariableValueModalDialogComponent extends DialogComponent<editVariableValueModal, Object>
  implements editVariableValueModal {
  variableName;
  dataType;
  latestValue;
  valueFormat;
  updatedValue;
  saveBtn = false;

  constructor(dialogService: DialogService, public constants: Constants,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);
  }

  onClick() {
    if (this.dataType === this.constants.questionType.IMAGE) {
      this.saveBtn = true;
    } else {
      if (typeof this.updatedValue !== 'undefined' && this.updatedValue !== '') {
        this.updateVariableValue();
      } else {
        return toastr.error('Please provide value to update');
      }
    }
  }

  private updateVariableValue() {
    this.saveBtn = false;
    this.result = this.updatedValue;
    this.close();
  }

  onChange(value, key) {
    this.updatedValue = value;
  }

  ngOnInit() {
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}

