import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UploadService} from '../../../../services/app.uploadFile.service';
import {ThemeLoader} from '../../../../app.component.loader';
import {Constants} from '../../../../constants';
import {ContactAttributeService} from '../../../../services/app.contactAttribute.service';
import {DialogService} from 'ng6-bootstrap-modal';
import {SetPipeVariableDefaultValueModalDialogComponent} from '../../../setPipeVariableDefaultValue/setPipeVariableDefaultValue.component';
import {UUID} from 'angular2-uuid';
import {HelpNowService} from '../../../../services/app.helpnow.service';
import {MessageBankService} from '../../../../services/app.messageBank.service';

declare let $: any, toastr;

@Component({
  selector: 'summernote',
  templateUrl: './summernoteComponent.html',
  providers: [UploadService, ContactAttributeService, HelpNowService, MessageBankService]
})

export class SummernoteComponent implements OnInit {

  @Output('onChange') change: EventEmitter<String> = new EventEmitter<String>();
  elemId = '.summernote';
  contentData;
  attributeList: any = '';
  emcontactList: any = '';
  type;
  isAttributeListLoaded = false;
  showLoader = true;
  pageNumber = 1;
  readyToAPICall = true;
  pageSize = 25;
  searchQuery = '';
  fixedAttributes = '';
  contactQrCode = 'CONTACT:QRCODE';
  @Input('codeType') codeType;
  allowCodeTypes = [this.constants.BUSINESS_ACCOUNT, this.constants.QUESTION, this.constants.PDF, this.constants.participantDashboard.PARTICIPANT_FORM];

  constructor(private uploadService: UploadService, private spinner: ThemeLoader, public constants: Constants,
              private contactAttributeService: ContactAttributeService, private helpnowService: HelpNowService,
              private messageBankService: MessageBankService, private dialogService: DialogService) {

  }

  @Input()
  set contentType(value) {
    this.type = value;
  }

  @Input()
  set setValue(data) {
    this.contentData = data;
    if (this.type === this.constants.EMAIL || this.type === this.constants.SMS || this.type === this.constants.ACTOHEALTHAPP) {
      if (this.attributeList) {
        this.initializeAndSetData();
      } else {
        this.getListOfContactAttributes();
      }
    }
  }

  @Input()
  set saveButtonClick(value) {
    if (value) {
      this.hidePopover();
    }
  }

  customSummerNoteToolbarOption() {
    let searchInputBoxHtml: any = `<div class="search-box-container pipe-variable-search"><input class="form-control search-input" placeholder="Search..." type="search"></div>`;
    let attributeHtml = '';
    return (context) => {
      const self = this;
      this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='contactFirstName' data-scope='DISCRETE' data-type='TEXT' data-attribute='CONTACT:FIRSTNAME'><a href='#'>Participant First Name</a></li>
        <li class="special-attribute-list" id='contactLastName' data-scope='DISCRETE' data-type='TEXT' data-attribute='CONTACT:LASTNAME'><a href='#'>Participant Last Name</a></li>
        <li class="special-attribute-list" id='username' data-attribute='CONTACT:USERNAME'><a href='#'>Participant Username</a></li>`;
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="currentDate" data-attribute="CONTACT:CURRENTDATE"><a href="#">Current Date</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="surveyLastCompleted" data-attribute="CONTACT:LASTCOMPLETED"><a href="#">Last Completed Date of this Survey</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="activationStatus" data-attribute="CONTACT:ACTIVATED"><a href="#">Activation Status</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="activationDate" data-attribute="CONTACT:ACTIVATEDDATE"><a href="#">Activation Date</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="contactEmail" data-attribute="CONTACT:EMAIL"><a href="#">Participant Email</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="contactPhone" data-attribute="CONTACT:PHONE"><a href="#">Participant Mobile Phone</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="contactTimezone" data-attribute="CONTACT:TIMEZONE"><a href="#">Participant Timezone</a></li>';
      this.fixedAttributes = this.fixedAttributes + '<li class="special-attribute-list" id="contactLanguage" data-attribute="CONTACT:LANGUAGE"><a href="#">Participant Language</a></li>';

      if (this.codeType === this.constants.MESSAGE && (this.type === this.constants.EMAIL || this.type === this.constants.SMS)) {
        this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='appUrl' data-attribute='CONTACT:APPURL'><a href='#'>APP URL</a></li>`;
      }

      if (this.codeType === this.constants.workflowCustomParams.SURVEY && (this.type === this.constants.EMAIL || this.type === this.constants.SMS)) {
        this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='surveyURL' data-attribute='SURVEY:URL'><a href='#'>Questionnaire URL</a></li>`;
        if (this.type === this.constants.EMAIL) {
          this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='attachSurveyURL' data-attribute='SURVEY:URL'><a href='#'><i class='note-icon-link'/> Link as Questionnaire URL</a></li>`;
        }
      }
      // if ( this.codeType === this.constants.PDF ) {
      //   this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='mainEmContact' data-attribute='EMCONTACT:MAIN:ALL'><a href='#'>Main Emergency Contact</a></li>`;
      // }

      if (this.codeType === this.constants.BUSINESS_ACCOUNT) {
        this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='password' data-attribute='CONTACT:TEMP_PASSWORD'><a href='#'>OTP</a></li>
                               <li class="special-attribute-list" id='appUrl' data-attribute='CONTACT:APPURL'><a href='#'>APP URL</a></li>`;
        if (this.type === this.constants.ONBOARDING_EMAIL) {
          this.fixedAttributes = this.fixedAttributes + `<li class="special-attribute-list" id='qrCode' data-attribute='${this.contactQrCode}'><a href='#'>QR Code</a></li>
                                <li class="special-attribute-list" id='attachAppUrl' data-attribute='CONTACT:APPURL'><a href='#'><i class='note-icon-link'/> Link as APP Url</a></li>`;
        }
        searchInputBoxHtml = ''; // search option will not available for organization template
      }

      attributeHtml = searchInputBoxHtml + `<ul class="${this.type + '-attribute'} pipe-attribute-list">${this.fixedAttributes + this.attributeList + this.emcontactList}</ul>`;
      return this.createDropdownList(self, attributeHtml, context);
    };
  }

  createDropdownList(self, customAttributeList, context) {
    const ui = $.summernote.ui;
    const button = ui.buttonGroup([
      ui.button({
        className: 'dropdown-toggle',
        contents: 'Insert <span class="caret"></span>',
        tooltip: 'Insert',
        data: {
          toggle: 'dropdown'
        }
      }),
      ui.dropdown({
        className: 'drop-default summernote-list',
        contents: customAttributeList,
        callback: function ($dropdown) {
          self.onParticipantAttributeListClick($dropdown);
        }
      })
    ]);
    return button.render();   // return button as jquery object
  }

  onParticipantAttributeListClick(ui) {
    const self = this;
    ui.find('li').each(function () {
      $(this).click(function () {
        let htmlTemplate: any;
        const dataAttribute = $(this).attr('data-attribute');
        const type = $(this).attr('data-type');
        const name = $(this).text();
        const scope = $(this).attr('data-scope');
        let defaultValue = $(this).attr('data-default');
        defaultValue = defaultValue ? (defaultValue === 'null' ? '' : defaultValue) : '';
        if ($(this).attr('id') === 'attachSurveyURL' || $(this).attr('id') === 'attachAppUrl') {
          if ($('.' + self.type).summernote('createRange').toString()) {
            htmlTemplate = `<a target="_blank" data-attribute="${dataAttribute}"> ${$('.' + self.type).summernote('createRange').toString()} </a>`;
          }
        } else {
          const count = UUID.UUID();
          htmlTemplate = `<input style="" id="${count}" type="button" data-list="STRING" data-value="1" data-default="${defaultValue}"  data-expression="" data-scope="${scope}"  data-type="${type}"
                      class="note-btn btn btn-default btn-sm contact-attr-btn" data-attribute="${dataAttribute}" data-name="${name}" value="${name}">`;
          $('.' + self.type).summernote('restoreRange');
        }
        if (htmlTemplate) {
          $('.' + self.type).summernote('editor.pasteHTML', htmlTemplate);
        }
        self.openSetContactAttributeValueModal(self);
      })
    });
  }

  openSetContactAttributeValueModal(self) {
    $('.' + this.type).next().find('input.contact-attr-btn').unbind('click').bind('click', function () {
      const data = {
        latest: $(this).attr('data-value'),
        defaultValue: $(this).attr('data-default'),
        type: $(this).attr('data-type'),
        name: $(this).attr('value'),
        scope: $(this).attr('data-scope'),
        fontStyle: $(this).attr('style'),
        dataExpression: $(this).attr('data-expression'),
        dataAttribute: $(this).attr('data-attribute'),
        selectedElement: $(this),
        orderList: $(this).attr('data-list') ? $(this).attr('data-list') : 'STRING'
      };
      if (data.dataExpression === '{') {
        data.dataExpression = '';
      }
      if (data.scope !== 'undefined') {
        self.setDefaultValue(data);
      }
    });
  }

  uploadImage(file) {
    this.spinner.show();
    this.uploadService.uploadImage(file).subscribe(
      data => this.uploadImageSuccess(data, file),
      error => this.spinner.hide()
    );
  }

  uploadImageSuccess(data, file) {
    if (data.status === 1) {
      let filename = 'image';
      if (file[0]) {
        filename = file[0].name;
      }
      $('.' + this.type).summernote('insertImage', data.body.url, filename);
    }
    this.spinner.hide();
  }

  getCallBack() {
    const callback: any = {
      onImageUpload: (files) => {
        if (files[0] && files[0].size <= 8000000) {
          this.uploadImage(files[0]);
        } else {
          toastr.error('Max upload file size is 8MB');
        }
      },
      onFocus: () => {
        $('.' + this.type).summernote('restoreRange');
      },
      onBlur: (contents, $editable) => {
        $('.' + this.type).summernote('saveRange');
      },
      onBlurCodeview: () => {
        setTimeout(() => {
          this.openSetContactAttributeValueModal(this);
        }, 100);
      },
      onPaste: (e) => {
        // attach a click event on paste on html with participant attribute
        setTimeout(() => {
          this.openSetContactAttributeValueModal(this);
        }, 0);
      },
      onChange: (contents, $editable) => {
        if (this.allowCodeTypes.indexOf(this.codeType) > -1) {
          this.change.emit(contents);
        }
        setTimeout(() => {
          this.openSetContactAttributeValueModal(this);
        }, 0);
      }
    };
    return callback;
  }

  getLink() {
    let link = ['linkDialogShow', 'picture', 'video'];
    if (this.type === this.constants.ONBOARDING_EMAIL || this.type === this.constants.EMAIL ||
        this.type === this.constants.participantDashboard.PARTICIPANT_FORM) {
      link = ['linkDialogShow', 'picture'];
    }
    if (this.type === this.constants.PDF) {
      link = ['linkDialogShow', 'picture'];
    }
    return link;
  }

  getToolbar() {
    let toolbar = [
      ['myotherbutton', ['customParams']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['fontname', 'strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['link', this.getLink()],
      ['para', ['ul', 'ol', 'paragraph']],
      [['codeview']]
    ];
    if (this.type === this.constants.ONBOARDING_SMS || this.type === this.constants.SMS || this.type === this.constants.ACTOHEALTHAPP) {
      toolbar = [['myotherbutton', ['customParams']]];
    }
    if (this.type === this.constants.PDF) {
      toolbar.push(['table', ['table']]);
    }
    return toolbar;
  }

  initializeAndSetData() {
    this.showLoader = true;
    setTimeout(() => {
      $('.' + this.type).html(this.contentData);
      $('.' + this.type).summernote({
        toolbar: this.getToolbar(),
        fontNames: ['Arial', 'Helvetica', 'Impact', 'Lato', 'Tahoma', 'Verdana'],
        height: this.type === this.constants.PDF ? 345 : 180,
        dialogsInBody: true,
        callbacks: this.getCallBack(),
        buttons: {
          customParams: this.customSummerNoteToolbarOption(),
        },
        popover: {
          image: [
            ['imagesize', ['imageSize100', 'imageSize75', 'imageSize50', 'imageSize25', 'imageSize15', 'imageSize10', 'imageSize5']],
            ['float', ['floatLeft', 'floatRight', 'floatNone']],
            ['remove', ['removeMedia']]
          ],
          table: [
            ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
            ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
            ['color', ['cellBackgroundColor']]
          ]
        }
      });
      // $(this.elemId + '.' + this.type).summernote('fontName', 'Lato');
      this.openSetContactAttributeValueModal(this);
      if (this.codeType !== this.constants.BUSINESS_ACCOUNT) {
        this.onSearch();
        this.onListScroll();
      }
      this.showLoader = false;
    }, 1);
  }

  setDefaultValue(current) {
    const data = {
      type: current.type,
      displayName: current.name,
      latest: current.latest,
      defaultValue: current.defaultValue,
      scope: current.scope,
      fontStyle: current.fontStyle,
      expression: current.dataExpression,
      dataAttribute: current.dataAttribute,
      orderList: current.orderList
    };

    this.dialogService.addDialog(SetPipeVariableDefaultValueModalDialogComponent, {
      data: data,
      contentType: this.type
    }).subscribe((value: any) => {
      if (value) {
        const htmlContentWithoutValue = current.selectedElement[0].outerHTML;
        current.selectedElement.attr('data-value', value.latest);
        current.selectedElement.attr('style', value.fontStyle);
        current.selectedElement.attr('data-default', value.defaultValue);
        current.selectedElement.attr('data-expression', value.expression);
        current.selectedElement.attr('data-list', value.orderList);
        this.contentData = $('.' + this.type).summernote('code');
        this.contentData = this.contentData.replace(htmlContentWithoutValue, current.selectedElement[0].outerHTML);
        $('.' + this.type).html(this.contentData);
        if (this.allowCodeTypes.indexOf(this.codeType) > -1) {
          this.change.emit(this.contentData);
        }
        this.openSetContactAttributeValueModal(this);
      }
      let range = $('#summernote').summernote('getLastRange');
      $('.' + this.type).summernote('saveRange', range);
      $('.' + this.type).summernote('focus');
    });
  }

  getListOfContactAttributes() {
    this.spinner.show();
    const params = {
      dataType: [''],
      name: this.searchQuery,
      orderBy: 'ASC',
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      scope: '',
      sortBy: 'attributeName',
      type: ['']
    };

    this.contactAttributeService.getAllCustomAttributes(params).subscribe(
      data => this.allContactAttributeSuccess(data),
      error => {
        this.spinner.hide();
        this.readyToAPICall = true;
      }
    );
  }

  allContactAttributeSuccess(data) {
    if (data.status === 1) {
      const participantAttributes = data.body.elements;
      let tempListHtml = '';
      if (participantAttributes.length) {
        this.readyToAPICall = true;
        for (let i = 0; i < participantAttributes.length; i++) {
          const singleListHtml = `<li data-default='${participantAttributes[i].defaultValue}' data-scope='${participantAttributes[i].attributeType}' id='${participantAttributes[i].attributeName}'
               data-type='${participantAttributes[i].attributeDataType}' data-attribute='CONTACT:ATTRIBUTE:${participantAttributes[i].id}'>
              <a href='#'>${participantAttributes[i].displayName}</a>
            </li>`;
          tempListHtml = tempListHtml + singleListHtml;
        }
        if (this.attributeList && this.pageNumber !== 1) {
          this.attributeList = this.attributeList.concat(tempListHtml);  // on scroll api call load the data
        } else {   // first time load
          this.attributeList = tempListHtml;
          if (!this.searchQuery) {
            this.attributeList = tempListHtml;
          }
        }
        if (participantAttributes.length < this.pageSize) {
          this.readyToAPICall = false;
        }
      } else {
        this.readyToAPICall = false;
      }
    }

    if (!this.isAttributeListLoaded) {
      this.isAttributeListLoaded = true;
      this.initializeAndSetData();
    } else {
      $(`.${this.type + '-attribute'} li`).remove();
      $(`.${this.type + '-attribute'}`).html(this.fixedAttributes + this.attributeList + this.emcontactList);
      const ui = $('.' + this.type).next().find('.summernote-list');
      this.onParticipantAttributeListClick(ui);
    }
    this.spinner.hide();
  }

  private getListOfContacts() {
    this.helpnowService.getEmergencyContactTypeList().subscribe(
      data => this.getListOfContactsSuccess(data),
      error => this.spinner.hide()
    );
  }

  private getListOfContactsSuccess(data: any) {
    if ( data.status === 1 ) {
      const contactList = data.body;
      let tempListHtml = '';
      if ( contactList && contactList.length ) {
        for (let i = 0; i < contactList.length; i++) {
          const contactId = 'emcontact' + i;
          const singleListHtml = `<li data-default='' data-scope='DISCRETE' id='${contactId}'
               data-type='TEXT' data-attribute='${contactList[i].dataAttribute}'>
               <a href='#'>${contactList[i].displayLabel}</a>
            </li>`;
          tempListHtml = tempListHtml + singleListHtml;
        }
        this.emcontactList = tempListHtml;
        this.getListOfMessageBanks();
      }
    }
  }

  private getListOfMessageBanks() {
    this.messageBankService.getMessageBankTypeList().subscribe(
      data => this.getListOfMessageBanksSuccess(data),
      error => this.spinner.hide()
    );
  }

  private getListOfMessageBanksSuccess(data: any) {
    if ( data.status === 1 ) {
      const mbList = data.body;
      let tempListHtml = '';
      if ( mbList && mbList.length ) {
        for (let i = 0; i < mbList.length; i++) {
          const mbId = 'mbank' + i;
          const singleListHtml = `<li data-default='' data-scope='DISCRETE' id='${mbId}'
               data-type='TEXT' data-attribute='${mbList[i].dataAttribute}'>
               <a href='#'>${mbList[i].displayLabel}</a>
            </li>`;
          tempListHtml = tempListHtml + singleListHtml;
        }
        this.emcontactList = this.emcontactList + tempListHtml;
      }
    }
  }

  onSearch() {
    $('.pipe-variable-search').keypress((e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
    $('.pipe-variable-search').keyup((e) => {
      if (e.keyCode !== 13) {
        this.pageNumber = 1;
        this.attributeList = '';
        this.searchQuery = e.target.value;
        this.getListOfContactAttributes();
      } else {
        e.preventDefault();
      }
    });
  }

  onListScroll() {
    const dropdownList = $(`ul.${this.type}-attribute.pipe-attribute-list`);
    dropdownList.scroll(() => {
      if ((dropdownList.scrollTop() + dropdownList.innerHeight() >= dropdownList[0].scrollHeight) && this.readyToAPICall) {
        this.readyToAPICall = false;
        this.pageNumber++;
        this.getListOfContactAttributes();
      }
    });
  }

  ngOnInit() {
    if (this.codeType === this.constants.QUESTION || this.codeType === this.constants.PDF || this.codeType === this.constants.participantDashboard.PARTICIPANT_FORM) {
      this.getListOfContactAttributes();
    }
    if (this.codeType === this.constants.PDF ) {
      this.getListOfContacts(); // also gets message banks
    } else if ( this.codeType === this.constants.participantDashboard.PARTICIPANT_FORM ) {
      this.getListOfMessageBanks();
    }
    if (this.codeType === this.constants.BUSINESS_ACCOUNT) {
      this.initializeAndSetData();
    }
  }

  hidePopover() {
    $('.note-popover').css('display', 'none');
  }

  ngOnDestroy() {
    this.hidePopover();
  }


}
