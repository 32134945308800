import { Component, OnInit } from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';

export interface ExpandedImageModel {
  userName: string;
  imageUrl: string;
}

@Component({
  selector: 'app-expanded-image-modal',
  templateUrl: './expanded-image-modal.component.html',
  styleUrls: ['./expanded-image-modal.component.scss']
})
export class ExpandedImageModalComponent extends DialogComponent<ExpandedImageModalComponent, object> implements ExpandedImageModel {
  userName: string;
  imageUrl: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }
}
