import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClientHelper} from '../app.httpclient';
import 'rxjs';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class ContactAttributeService {
  private productUrl: string = 'product/' + this.authentication.getSelectedObject();
  private elasticSearchAPIPrefix = 'api/elastic/v1/customAttribute';
  private visualization = 'visualization';
  private customAttributePrefix = 'api/customProfileAttribute/';
  private business = 'api/business/v1/';

  constructor(private httpHelper: HttpClientHelper, private http: HttpClient, private authentication: AuthenticationHelper) {

  }

  getContactAttributeListWithRespectToContact(contactId, filterData, searchData) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/contact/' + contactId + '?fromTs=' + searchData.fromTs +
      '&toTs=' + searchData.toTs + '&period=' + searchData.period + '&pageNumber=' + searchData.pageNumber +
      '&pageSize=' + searchData.pageSize + '&name=' + searchData.name + '&dataType=' + searchData.dataType +
      '&orderBy=' + searchData.orderBy + '&sortBy=' + searchData.sortBy;
    return this.httpHelper.post(url, filterData)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactAttributeValueData(contactId) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/useredit/list/' + contactId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  getContactSingleAttributeValueData(profileAttributeId, contactId) {
    const url = this.elasticSearchAPIPrefix + '/' + profileAttributeId + '/useredit/single/' + contactId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  getParticipantAttributeValue(profileAttributeId, contactId) {
    const url = this.elasticSearchAPIPrefix + '/contact/' + contactId + '/value/' + profileAttributeId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  /**
   * get attribute values as shown on team member dashboard attribute widget
   */
  getContactAttributeValueFrequencyData(widgetId) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/frequency/' + widgetId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  /**
   * get attribute values for participants
   * @param data
   */
  getContactAttributeValueList(data) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/participant/list';
    return this.httpHelper.get(url, data)
      .pipe(catchError(this.handleError)
      );
  }

  /**
   * get the attribute type (SCALAR, TIMELINE, etc.)
   * @param attId
   */
  getAttributeType(attId) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/attType/' + attId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  getDynamicAttributeSourceDef(sourceDefId) {
    const url = this.elasticSearchAPIPrefix + '/' + this.productUrl + '/sourceDef/' + sourceDefId;
    return this.httpHelper.get(url)
      .pipe(catchError(this.handleError)
      );
  }

  createChartVisualizationParameter(data) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/' + this.productUrl;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateChartVisualizationParameter(data) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteChartVisualizationParameter(id) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/' + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactAttributeChartsForContact(widgetId, contactId, filterData, searchData) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/' + this.productUrl + '/contact/' + contactId + '/widget/' + widgetId + '/?fromTs=' + searchData.fromTs + '&toTs=' + searchData.toTs + '&period=' + searchData.period;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChartVisualizationParameter() {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/' + this.productUrl;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSeriesAttributeGraphData(params) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/timeline';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSeriesAttributeChartData(params) {
    const url = this.elasticSearchAPIPrefix + '/' + this.visualization + '/chart';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomAttributeSummaryList(serviceGroupId) {
    const url = this.customAttributePrefix + 'summaries/' + serviceGroupId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllCustomAttributes(params: any = {}) {
    const scope = ['CUSTOM', 'SYSTEM', 'DYNAMIC'];

    params.scope = params.scope ? params.scope : scope;

    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
      params.dataType = '';
      params.name = '';
      params.type = '';
      params.attributeName = '';
    }

    const url = this.customAttributePrefix + this.productUrl;
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactAttributeDetails(id) {
    const url = this.customAttributePrefix + id;
    return this.httpHelper.get(url).pipe(
      map(this.extractResponse),
      catchError(this.handleError));
  }

  getSeriesAttributeData(contactId, attributeId, data) {
    const url = this.elasticSearchAPIPrefix + '/contact/' + contactId + '/attribute/' + attributeId + '?limit=100&period=DAILY';
    return this.httpHelper.post(url, data).pipe(
      map(this.extractResponse),
      catchError(this.handleError)
    );
  }

  /**
   * get paginated list of values for the series attribute values widget
   * @param params
   */
  getSeriesAttributeValues(params) {
    const url = this.elasticSearchAPIPrefix + '/timeSeries/values';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSeriesAttributesMatrixValues(params) {
    const url = this.elasticSearchAPIPrefix + '/timeSeries/matrix';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportSeriesAttributeMatrixValues(contactId, widgetId) {
    const url = this.httpHelper.getApiBaseUrl() + this.elasticSearchAPIPrefix + '/timeSeries/matrix/export/' + contactId + '/' + widgetId;
    console.log('export service URL = ' + url);
    return this.http.get(url, {responseType: 'blob'})
      .pipe(map(this.exportSeriesAttributeMatrixData))
      .pipe(catchError(this.handleError));
  }
  exportSeriesAttributeMatrixData(res) {
    return res;
  }

  getParticipantAttributeMatrixValues(params) {
    const url = this.elasticSearchAPIPrefix + '/participant/matrix';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCustomAttribute(data) {
    const url = this.customAttributePrefix + 'update';
    return this.httpHelper.post(url, data)
    .pipe(
        catchError(this.handleError)
      );
  }

  cloneCustomAttribute(data) {
    const url = this.customAttributePrefix + 'clone';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  addCustomAttributes(data) {
    const url = this.customAttributePrefix + this.productUrl;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCustomAttribute(id) {
    const url = this.customAttributePrefix + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContactAttributeValue(data) {
    const url = this.elasticSearchAPIPrefix;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAllContactAttributeValues(data) {
    const url = this.elasticSearchAPIPrefix + '/data';
    return this.httpHelper.Delete(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactAttributeName(data) {
    const url = this.business + 'contact-attribute/info';
    return this.httpHelper.post(url, data).pipe(
      map(this.extractResponse),
      catchError(this.handleError), );
  }

  extractResponse(res: JSON) {
    return res;
  }

  handleError(error: any) {
    return throwError(error);
  }


}
