import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {Constants} from '../../constants';
import {ThemeLoader} from '../../app.component.loader';
import {HelpNowService} from '../../services/app.helpnow.service';
import {SurveyService} from '../../services/app.survey.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {PdfFormService} from '../../services/app.pdfFormService.service';
import {AppModalService} from '../../services/app.modalService';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ContactService} from '../../services/app.contact.service';
import {AuthenticationHelper} from '../../app.authentication';
import {RefdataService} from '../../services/app.refdata.service';

declare let toastr;

export interface helpNowModalDialogModel {
  title;
  helpNowDetails;
  contactId;
}

@Component({
  selector: 'help-now-modal',
  templateUrl: './createHelpNowModal.component.html',
  styleUrls: ['./helpnow.component.scss'],
  providers: [HelpNowService, ContactService, RefdataService, AppRouteChangeSubscriptionService]
})
export class HelpNowModalDialogComponent extends DialogComponent<helpNowModalDialogModel, string> implements helpNowModalDialogModel {
  title;
  helpNowDetails;
  name: string;
  helpNowName: AbstractControl;
  helpNowDesc: AbstractControl;
  helpNowForm: FormGroup;
  saveHelpNowBtn = false;
  participantList = [];
  groupList = [];
  resourceTypeList = [];
  setPublishData;
  contactId;
  helpNowPublishData;
  assignByGroup = false;

  pageSize = 100000;

  selectedParticipant: FormControl = new FormControl([]);
  selectedGroup: FormControl = new FormControl([]);
  resourceType: AbstractControl;
  selectedParticipantList = [];
  selectedGroupList = [];
  originalParticipantIdList = [];
  originalGroupIdList = [];
  participantsUpdated = false;
  groupsUpdated = false;
  participantLabel = 'Participant';
  participantGroupLabel = 'Participant Group';

  constructor(dialogService: DialogService, fb: FormBuilder, private constants: Constants, private spinner: ThemeLoader,
              private helpNowService: HelpNowService, private contactService: ContactService,
              private authentication: AuthenticationHelper, private refdataService: RefdataService,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);
    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: '1000'
    };
    this.helpNowForm = fb.group({
      'helpNowName': [null, Validators.compose([Validators.required])],
      'helpNowDesc': Validators.nullValidator,
      'resourceType': [[], Validators.nullValidator],
      'assignByGroupFlag': [null, Validators.nullValidator],
    });

    this.helpNowName = this.helpNowForm.controls['helpNowName'];
    this.helpNowDesc = this.helpNowForm.controls['helpNowDesc'];
    this.resourceType = this.helpNowForm.controls['resourceType'];

  }

  setValueInForm() {
    if (typeof this.helpNowDetails.id !== 'undefined' && this.helpNowDetails !== '') {
      this.helpNowName.patchValue(this.helpNowDetails.resourceName);
      this.helpNowDesc.patchValue(this.helpNowDetails.resourceDesc);
      this.assignByGroup = this.helpNowDetails.assignByGroup;
      const participantIDList = this.helpNowDetails.persons;
      const groupIDList = this.helpNowDetails.groups;
      const resourceTypeId = this.helpNowDetails.resourceTypeId;

      if ( participantIDList && participantIDList.length > 0 ) {
        this.originalParticipantIdList = participantIDList;
        const selectedParticipantArray = [];
        for ( const p of this.participantList ) {
          if ( participantIDList.includes(p.id) ) {
            selectedParticipantArray.push(p);
          }
        }
        this.selectedParticipant.patchValue(selectedParticipantArray);
      }

      if ( groupIDList && groupIDList.length > 0 ) {
        this.originalGroupIdList = groupIDList;
        const selectedGroupArray = [];
        for ( const g of this.groupList ) {
          if ( groupIDList.includes(g.id) ) {
            selectedGroupArray.push(g);
          }
        }
        this.selectedGroup.patchValue(selectedGroupArray);
      }

      let resourceTypeValue = null;
      if ( this.resourceTypeList ) {
        for ( const rtype of this.resourceTypeList ) {
          if ( rtype.id === resourceTypeId ) {
            resourceTypeValue = rtype;
            break;
          }
        }
      }

      if ( resourceTypeValue ) {
        this.resourceType.patchValue(resourceTypeValue);
      }

    } else {
      this.setPublishData = {};
      this.helpNowDesc.patchValue('');
    }

    // if (this.contactId) {
      this.spinner.hide();
    // }
  }

  onSubmit() {
    // if (this.selectedPdfTemplates.value.length == 0 && this.selectedSupportUrls.value.length == 0 && this.selectedSafetyPlans.value.length == 0 && this.selectedEmergencyContacts.value.length == 0 && this.selectedInterventionSurveys.value.length == 0) {
    //   return toastr.error(this.constants.selectionErrorHelpNow);
    // }
    this.saveHelpNowBtn = true;
    // if (this.contactId) {
      this.saveHelpNow();
   // }
  }

  saveHelpNow() {
    this.spinner.show();

    const data: any = {
      resourceName: this.helpNowForm.value['helpNowName'],
      resourceDesc: this.helpNowForm.value['helpNowDesc'],
      persons: [],
      groups: [],
      businessUnitId: this.authentication.getSelectedObject(),
      resourceTypeId: this.resourceType.value.id,
      assignByGroup: this.assignByGroup,
    };

    if (this.participantList || this.groupList) {

      data.persons = this.selectedParticipantList.map(x => { return x.id; });
      data.groups = this.selectedGroupList.map(x => { return x.id; });

      if (typeof this.helpNowDetails.id !== 'undefined' && this.helpNowDetails !== '') {
        if ( !this.participantsUpdated && (!data.persons || data.persons.length === 0)) {
          data.persons = this.originalParticipantIdList;
        }
        if ( !this.groupsUpdated && (!data.groups || data.groups.length === 0) ) {
          data.groups = this.originalGroupIdList;
        }
        data.id = this.helpNowDetails.id;
        this.helpNowService.updateHelpNow(data).subscribe(
          data => this.success(data),
          error => this.error(error)
        )
      } else {
        this.helpNowService.addHelpNow(data).subscribe(
          data => this.success(data),
          error => this.error(error)
        )
      }
    } else {
      this.saveHelpNowBtn = false;
      this.spinner.hide();
    }
  }

  success(data) {
    this.saveHelpNowBtn = false;
    this.spinner.hide();
    if (data.status === 1) {
      if (typeof this.helpNowDetails.id !== 'undefined' && this.helpNowDetails !== '') {
        toastr.success(this.constants.updatedSuccess);
      } else {
        toastr.success(this.constants.createdSuccess);
      }
      this.result = data;
      this.close();
    } else {
      toastr.error(data.message);
    }
  }

  error(error) {
    this.saveHelpNowBtn = false;
    this.spinner.hide();
    toastr.error(this.constants.serverError);
  }

  onScroll(list) {

  }

  onSearch(event, list) {

  }

  publishHelpNowData(publishData) {
    this.helpNowPublishData = publishData;
    this.saveHelpNow();
  }

  private getResourceTypes() {
    this.refdataService.getResourceTypeList().subscribe(
      data => this.getResourceTypesSuccess(data),
      error => this.error(error)
    );
  }

  private getResourceTypesSuccess(data: any) {
      if ( data.status === 1 ) {
        this.resourceTypeList = data.body;
      }
  }

  private getParticipantGroups() {
    this.contactService.getContactGroupForProduct().subscribe(
      data => this.participantGroupListSuccess(data),
      error => this.spinner.hide()
    )
  }

  private participantGroupListSuccess(data) {
    if ( data.status === 1 ) {
      this.groupList = data.body;
    }
  }

  private getAllParticipants() {
    this.contactService.getAllContacts(null).subscribe(
      data => this.allParticipantListSuccess(data),
      error => this.error(error)
    );
  }

  private allParticipantListSuccess(data: any) {
    if ( data.status === 1 ) {
      const allParticipants = data.body.contactList;
      this.participantList = allParticipants.map(x => {
        return { id: x.id, name: x.firstName + ' ' + x.lastName }
      });
      this.setValueInForm();
    }
  }

  setSelectedParticipants(value: any) {
    this.selectedParticipantList = this.selectedParticipant.value;
    this.participantsUpdated = true;
  }

  removeParticipant(value: any) {
    const index = this.selectedParticipantList.indexOf(value.id);
    if (index > -1) {
      this.selectedParticipantList.splice(index, 1);
    }
  }

  setSelectedGroups(value: any) {
    this.selectedGroupList = this.selectedGroup.value;
    this.groupsUpdated = true;
  }

  removeGroup(value: any) {
    const index = this.selectedGroupList.indexOf(value.id);
    if (index > -1) {
      this.selectedGroupList.splice(index, 1);
    }
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
    this.spinner.show();
    this.participantGroupLabel = this.refdataService.getLabelByNameInCategory('Participants', 'ParticipantGroup');
    this.participantLabel = this.refdataService.getParticipantLabel();
    if ( !this.participantGroupLabel ) {
      this.participantGroupLabel = 'Participant Group';
    }
    this.getResourceTypes();
    this.getParticipantGroups();
    this.getAllParticipants();
    // setTimeout(() => {
    // this.setValueInForm(); }, 700);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }



}
