import {Injectable} from '@angular/core';
import {HttpClientHelper} from '../app.httpclient';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

@Injectable()
export class BusinessAccountService {
  private httpClient;
  private businessAccountUrl = 'api/businessAccounts/';
  private businessAPIUrl = 'api/businessEmployees/';
  private businessUnitUrl = 'api/businessunit/';
  private business = 'api/business/v1/';
  private mobileAppSettingUrl = this.business + 'app';
  private emailSettingUrl = this.business + 'email';
  private APISettingUrl = this.business + 'API';
  private commCredSettingUrl = this.business + 'communication';
  private organizationTemplate = 'api/v2/business/communication/template';
  private platformVersion = 'api/survey/v1/admin/app/version';
  private actionCenterWallpaper = this.businessAccountUrl + 'wallpaperImages';

  constructor(httpClient: HttpClientHelper) {
    this.httpClient = httpClient;
  }

  updateBusinessAccountDetails(data) {
    return this.httpClient.put(this.businessAccountUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessAccountForServiceGroup(serviceGroupId) {
    const url = this.businessAccountUrl + 'sgdetails/' + serviceGroupId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessAccountsForBusiness(businessId) {
    const url = this.businessAccountUrl + 'list/' + businessId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addBusinessEmployees(data) {
    return this.httpClient.post(this.businessAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveMobileAppSetting(data) {
    return this.httpClient.post(this.mobileAppSettingUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateBusinessEmployees(data) {
    return this.httpClient.put(this.businessAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteBusinessEmployee(userId) {
    const url = this.businessAPIUrl + userId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  resendActivationLink(userId) {
    const url = this.businessAPIUrl + userId + '/resend/email';
    return this.httpClient.post(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAPISettings() {
    return this.httpClient.get(this.APISettingUrl)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveAPISettings() {
    return this.httpClient.post(this.APISettingUrl)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveEmailSetting(data) {
    return this.httpClient.post(this.emailSettingUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrganizationTemplate() {
    return this.httpClient.get(this.organizationTemplate)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveOrganizationTemplate(data) {
    return this.httpClient.put(this.organizationTemplate, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPlatformVersion() {
    return this.httpClient.get(this.platformVersion)
      .map(this.extractResponse)
      .catch(this.handleError);
  }

  getActionCenterWallpapers() {
    return this.httpClient.get(this.actionCenterWallpaper)
      .map(this.extractResponse)
      .catch(this.handleError);
  }

  addOrUpdateActionCenterWallpaper(data) {
    return this.httpClient.post(this.actionCenterWallpaper, data)
      .map(this.extractResponse)
      .catch(this.handleError);
  }

  deleteActionCenterWallpaper(data) {
    return this.httpClient.Delete(this.actionCenterWallpaper, data)
      .map(this.extractResponse)
      .catch(this.handleError);
  }

  getBusinessList(data) {
    const url = this.business + 'list';
    return this.httpClient.get(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllBusinessList() {
    const url = this.business + 'listAll';
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessUnitList(businessId) {
    const url = this.businessUnitUrl + 'list/' + businessId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllBusinessUnitsForParentBusiness(serviceGroupId) {
    const url = this.businessUnitUrl + 'bulist/' + serviceGroupId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getServiceGroupList(businessId) {
    const url = this.businessUnitUrl + 'sglist/' + businessId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessDetails(businessId) {
    const url = this.business + 'details/' + businessId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessForBusinessUnit(businessUnitId) {
    const url = this.business + 'businessUnit/' + businessUnitId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessUnitDetails(businessUnitId) {
    const url = this.businessUnitUrl + 'details/' + businessUnitId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteBusinessUnit(businessUnitId) {
    const url = this.businessUnitUrl + businessUnitId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createBusinessUnit(data) {
    const url = this.businessUnitUrl + 'create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateBusinessUnit(data) {
    const url = this.businessUnitUrl + 'update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createBusiness(data) {
    const url = this.business + 'create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  cloneBusiness(data) {
    const url = this.business + 'clone';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateBusiness(data) {
    const url = this.business + 'update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteBusiness(businessId) {
    const url = this.business + 'delete/' + businessId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCommunicationCredList(businessId) {
    const url = this.commCredSettingUrl + '/list/' + businessId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCommunicationsCredentialDetails(commCredId) {
    const url = this.commCredSettingUrl + '/details/' + commCredId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCommunicationsOptOutDetails(businessId, personId) {
    const url = this.commCredSettingUrl + '/optOut/details/' + businessId + '/' + personId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCommunicationsOptOutDetails(data) {
    const url = this.commCredSettingUrl + '/optOut/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createCommunicationCredential(data) {
    const url = this.commCredSettingUrl + '/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCommunicationCredential(data) {
    const url = this.commCredSettingUrl + '/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCommunicationCredential(commCredId) {
    const url = this.commCredSettingUrl + '/delete/' + commCredId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private extractResponse(res: JSON) {
    return res;
  }

  private handleError(error: any) {
    return throwError(error);
  }



}
