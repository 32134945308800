import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonComponent} from '../commonComponent';
import {DaterangePickerComponent} from 'ng2-daterangepicker';
import {Constants} from '../../constants';

@Component({
  selector: 'custom-data-type-plugin',
  templateUrl: './customDataTypePlugin.component.html',
  providers: [CommonComponent]
})

export class CustomDataTypePluginComponent {
  @Input('type') type;
  @Output('onChange') change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(DaterangePickerComponent)
  picker: DaterangePickerComponent;
  @Input('disabled') disabled: boolean;
  @Input('number') number;
  checkNumber;
  dateTimeOptions: any = {
    singleDatePicker: true,
    timePicker24Hour: true,
    timePicker: true,
    autoUpdateInput: false,
    locale: {
      format: 'MM/DD/YYYY HH:mm'
    }
  };
  dateOptions: any = {
    singleDatePicker: true,
    autoUpdateInput: false,
  };
  savedValue: any = new Date();
  showAmPm = false;
  @Input() uploadImageToServer;

  constructor(public commonComponent: CommonComponent, public constants: Constants) {

  }

  @Input()
  set format(value) {
    switch ( this.type ) {
      case this.constants.questionType.TIME:
        this.showAmPm = !(value && value === 'TIME_24HOUR');
        break;
      case this.constants.questionType.DATETIME:
        this.dateTimeOptions.timePicker24Hour = !(value && value === 'TIME_12HOUR');
        break;
      default:
        break;
    }
  }

  @Input()
  set value(value) {
    this.checkNumber = false;
    this.checkNumber = this.number;
    // console.log('default value Input = ' + value);
    switch (this.type) {
      case this.constants.questionType.DATETIME: {
        if ( this.commonComponent.isNumeric(value) ) {
          this.savedValue = this.commonComponent.convertEpochIntoDateTime(value);
        } else {
          this.savedValue = value;
        }
        break;
      }
      case this.constants.questionType.DATE: {
        if ( this.commonComponent.isNumeric(value) ) {
          this.savedValue = this.commonComponent.convertEpochIntoDate(value);
        } else {
          this.savedValue = value;
        }
        break;
      }
      case this.constants.questionType.TIME: {
        console.log('+++ time value provided = ' + value);
        if ( this.commonComponent.isNumeric(value) ) {
          this.savedValue = this.commonComponent.convertMilliSecondsIntoTime(value);
        } else {
          console.log('+++ time value is not numeric');
          const timeMilliseconds = this.commonComponent.convertTimeStringToMilliseconds(value);
          if ( timeMilliseconds ) {
            this.savedValue = this.commonComponent.convertMilliSecondsIntoTime(timeMilliseconds);
          } else {
            this.savedValue = null;
          }
        }
        console.log('+++ time savedValue = ' + this.savedValue);
        break;
      }
      default: {
        this.savedValue = value;
        break;
      }
    }
  }

  setFirstTimeDefaultValue(value) {
   // console.log('setFirstTimeDefaultValue = ' + value);
    switch (this.type) {
      case this.constants.questionType.DATETIME: {
        value = this.commonComponent.convertDateIntoEpoch(value.picker.startDate);
        this.savedValue = this.commonComponent.convertEpochIntoDateTime(value);
        break;
      }
      case this.constants.questionType.DATE: {
        value = this.commonComponent.convertDateIntoEpoch(value.picker.startDate);
        this.savedValue = this.commonComponent.convertEpochIntoDate(value);
        break;
      }
    }
    this.change.emit(value);
  }

  setValue(value) {
    console.log('+++ setValue = ' + value);
    if (value) {
      switch (this.type) {
        case this.constants.questionType.TIME: {
          value = this.commonComponent.convertTimeInMilliSeconds(value);
          console.log('+++ converted time value = ' + value);
          break;
        }
        case this.constants.questionType.NUMBER: {
          if (this.checkNumber === true) {
            value = parseFloat(value);
          }
          break;
        }
      }
    }
    this.change.emit(value);
  }
}
