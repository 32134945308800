import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EqualPasswordsValidator} from '../../validators/equalPassword.validators';
import {PasswordValidator} from '../../validators/password.validator';

declare let toastr: any;

@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})

export class PasswordComponent implements OnInit {

  passwordForm: FormGroup;
  password: AbstractControl;
  confirmPassword: AbstractControl;
  @Input('submitBtnClick') submitBtnClick;
  valForm: FormGroup;
  errorMessage: string;
  @Input('newPassword') newPassword: boolean = false;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(fb: FormBuilder) {
    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: '1000'
    };

    this.valForm = fb.group({
      'passwordForm': fb.group({
        'password': ['', Validators.compose([Validators.required, PasswordValidator.validate, Validators.minLength(4), Validators.maxLength(50)])],
        'confirmPassword': ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])]
      }, {validator: EqualPasswordsValidator.validate('password', 'confirmPassword')})
    });

    this.passwordForm = <FormGroup>this.valForm.controls['passwordForm'];
    this.password = this.passwordForm.controls['password'];
    this.confirmPassword = this.passwordForm.controls['confirmPassword'];
  }

  checkPassword(value) {
    if (value) {
      if (value.length < 8) {
        this.errorMessage = 'Password must contain at least eight characters!';
        return false;
      }
      let re = /[0-9]/;
      if (!re.test(value)) {
        this.errorMessage = 'Password must contain at least one number (0-9)!';
        return false;
      }
      re = /[a-z]/;
      if (!re.test(value)) {
        this.errorMessage = 'Password must contain at least one lowercase letter (a-z)!';
        return false;
      }
      re = /[A-Z]/;
      if (!re.test(value)) {
        this.errorMessage = 'Password must contain at least one uppercase letter (A-Z)!';
        return false;
      }
      re = /[~!@#\$%\^&\*]/;
      if (!re.test(value)) {
        this.errorMessage = 'Password must contain at least one special character (~!@#$%^&*)!';
        return false;
      }
    } else {
      this.errorMessage = 'Please check that you\'ve entered and confirmed your password!';
      return false;
    }
  }

  submitForm(values: any) {
    if (this.valForm.valid) {
      this.onChange.emit(values.passwordForm.password);
    }
  }

  ngOnInit() {

  }

}
