import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {DataTableModule} from 'angular2-datatable';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgSelectModule} from '@ng-select/ng-select';
import {TagInputModule} from 'ngx-chips';
import {TooltipModule} from 'ngx-tooltip';
import { UiSwitchModule } from 'ngx-ui-switch';
import {BootstrapModalModule} from 'ng6-bootstrap-modal';
import {ImageCropperModule} from 'ngx-img-cropper';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {Daterangepicker} from 'ng2-daterangepicker';
import {NgxUploaderModule} from 'ngx-uploader';
// import {NvD3Module} from 'ng2-nvd3';
import {NgxPowerBiModule} from 'ngx-powerbi';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
// custom component
import {ConfirmComponent} from '../routes/confirmModalDialog/confirmModalDialog.component';
import {RecurrenceV1Component} from '../routes/recurrenceV1/recurrenceV1.component';
import {CreateUserModalDialogComponent} from '../routes/agents/createUserModal.component';
import {AgentsComponent} from '../routes/agents/agents.component';
import {SortFilterPipe} from '../sortFilterPipe';
import {CreateTicketModalDialogComponent} from '../routes/home/createTicketModal.component';
import {BeforeLoginHeaderComponent} from '../layout/beforeLoginHeader/beforeLoginHeader.component';
import {PublishDataComponent} from '../routes/publishData/publishData.component';
import {ImportTextFileComponent} from './importTextFileComponent';
import {HelpNowModalDialogComponent} from '../routes/helpnow/createHelpNowModal.component';
import {SummernoteComponent} from './customComponent/plugins/summernoteComponent/summernoteComponent';
import {DurationComponent} from '../routes/duration/duration.component';
import {SetPipeVariableDefaultValueModalDialogComponent} from './setPipeVariableDefaultValue/setPipeVariableDefaultValue.component';
import {ParticipantAttributeChartComponent} from '../routes/settings/contactAttributeVisualizationSetting/participantAttributeChart/participantAttributeChart.component';
import {CustomDataTypePluginComponent} from './setTimeAndDateComponent/customDataTypePlugin.component';
import {PasswordComponent} from './passwordComponent/password.component';
import {BreadcrumbComponent} from '../layout/breadcrumb/breadcrumb.component';
import {SafeHtmlPipe} from '../pipes/safeHtmlPipe';
import {CustomImageCropperComponent} from './custom-image-cropper-component/customImageCropper.component';
import {CustomSelectComponent} from './custom-select-plugin/customSelect.component';
import {MicroSpinnerComponent} from './microSpinner.component';
import {AppModalService} from '../services/app.modalService';
import {CommonComponent} from './commonComponent';
import {AutoFocusInputDirective} from './autoFocusInput.directive';
import {CustomIntlTelInputPluginComponent} from './custom-intl-tel-input-plugin/custom-intl-tel-input-plugin.component';
import {AgentGroupListComponent} from './agent-group-list/agentGroupList.component';
import {UploadImageModalComponent} from './uploadImageModal/uploadImageModal.component';
import { ExpandedImageModalComponent } from './expanded-image-modal/expanded-image-modal.component';
import {validationResultsModalDialogComponent} from './validationResults/validationResultsModal.component';
import {UserSecurityModalDialogComponent} from '../routes/agents/userSecurityModal.component';
import {EditAttributeValueModalDialogComponent} from './attributeValues/editAttributeValueModal.component';
import {ViewSeriesValuesModalComponent} from './attributeValues/viewSeriesValuesModal.component';
import {ProfileAttributeSelectComponent} from './custom-select-plugin/profileAttributeSelect.component';
import {AlertModalDialogComponent} from '../routes/confirmModalDialog/alertModalDialog.component';
import {EditVariableValueModalDialogComponent} from './attributeValues/editVariableValueModal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TagInputModule,
    BootstrapModalModule,
    NgSelectModule,
    DataTableModule,
    TooltipModule,
    UiSwitchModule,
    ImageCropperModule,
    Daterangepicker,
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    NgxUploaderModule,
    // NvD3Module,
    NgxPowerBiModule,
    RouterModule,
    ColorPickerModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    ConfirmComponent,
    AlertModalDialogComponent,
    RecurrenceV1Component,
    AgentsComponent,
    CreateUserModalDialogComponent,
    UserSecurityModalDialogComponent,
    SortFilterPipe,
    SafeHtmlPipe,
    BeforeLoginHeaderComponent,
    CreateTicketModalDialogComponent,
    PublishDataComponent,
    ImportTextFileComponent,
    HelpNowModalDialogComponent,
    SummernoteComponent,
    DurationComponent,
    SetPipeVariableDefaultValueModalDialogComponent,
    ParticipantAttributeChartComponent,
    CustomDataTypePluginComponent,
    PasswordComponent,
    BreadcrumbComponent,
    CustomImageCropperComponent,
    CustomSelectComponent,
    ProfileAttributeSelectComponent,
    MicroSpinnerComponent,
    AutoFocusInputDirective,
    CustomIntlTelInputPluginComponent,
    AgentGroupListComponent,
    UploadImageModalComponent,
    ExpandedImageModalComponent,
    validationResultsModalDialogComponent,
    EditAttributeValueModalDialogComponent,
    EditVariableValueModalDialogComponent,
    ViewSeriesValuesModalComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonsModule,
    CollapseModule,
    BsDropdownModule,
    TranslateModule,
    RatingModule,
    TabsModule,
    BootstrapModalModule,
    TagInputModule,
    NgSelectModule,
    TooltipModule,
    DataTableModule,
    UiSwitchModule,
    TimepickerModule,
    Daterangepicker,
    ImageCropperModule,
    NgxUploaderModule,
    // NvD3Module,
    ColorPickerModule,
    ConfirmComponent,
    AlertModalDialogComponent,
    BeforeLoginHeaderComponent,
    RecurrenceV1Component,
    CreateUserModalDialogComponent,
    UserSecurityModalDialogComponent,
    AgentsComponent,
    SortFilterPipe,
    SafeHtmlPipe,
    CreateTicketModalDialogComponent,
    PublishDataComponent,
    ImportTextFileComponent,
    PaginationModule,
    SummernoteComponent,
    DurationComponent,
    SetPipeVariableDefaultValueModalDialogComponent,
    ParticipantAttributeChartComponent,
    NgxPowerBiModule,
    ParticipantAttributeChartComponent,
    CustomDataTypePluginComponent,
    PasswordComponent,
    BreadcrumbComponent,
    CustomImageCropperComponent,
    CustomSelectComponent,
    ProfileAttributeSelectComponent,
    MicroSpinnerComponent,
    AutoFocusInputDirective,
    NgxIntlTelInputModule,
    CustomIntlTelInputPluginComponent,
    AgentGroupListComponent,
    UploadImageModalComponent,
    validationResultsModalDialogComponent,
    EditAttributeValueModalDialogComponent,
    EditVariableValueModalDialogComponent,
    ViewSeriesValuesModalComponent
  ],
  entryComponents: [
    ConfirmComponent,
    AlertModalDialogComponent,
    CreateUserModalDialogComponent,
    UserSecurityModalDialogComponent,
    CreateTicketModalDialogComponent,
    HelpNowModalDialogComponent,
    SetPipeVariableDefaultValueModalDialogComponent,
    UploadImageModalComponent,
    ExpandedImageModalComponent
  ],
  providers: [
    AppModalService,
    CommonComponent
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
