import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {Constants} from '../../constants';
import {BlankSpaceValidator} from '../../validators/blankSpace.validator';
import {EmailValidator} from '../../validators/email.validator';
import {SurveyService} from '../../services/app.survey.service';
import {BusinessAccountService} from '../../services/app.businessAccount.service';
import {AuthenticationHelper} from '../../app.authentication';
import {ThemeLoader} from '../../app.component.loader';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {AppModalService} from '../../services/app.modalService';
import {CommonComponent} from '../../shared/commonComponent';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {RefdataService} from '../../services/app.refdata.service';
import {AgentGroupService} from '../../services/app.agentGroup.service';

declare let toastr;

export interface createTopicUserDialogModel {
  title: any;
  userId: any;
  userDetails: any;
}

@Component({
  selector: 'createUserModal',
  templateUrl: './createUserModal.component.html',
  providers: [BusinessAccountService, SurveyService, AppRouteChangeSubscriptionService, RefdataService, AgentGroupService]
})
export class CreateUserModalDialogComponent extends DialogComponent<createTopicUserDialogModel, string>
  implements createTopicUserDialogModel {
  title: any;
  userId: any;
  userDetails: any;
  userForm: FormGroup;
  firstName: AbstractControl;
  lastName: AbstractControl;
  email: AbstractControl;
  phoneNumber: AbstractControl;
  ext: AbstractControl;
  personType: AbstractControl;
  userName: AbstractControl = new FormControl();
  timezones: FormControl = new FormControl([]);
  languages: FormControl = new FormControl([]);
  agentGroups: FormControl = new FormControl([]);
  saveUserBtn = false;
  countryCode = null;
  data: any;
  editUserRole = '';
  selectedServiceGroupList = [];
  currentUserRole = this.authentication.getUserRole();
  serviceGroupList = [];
  phoneNumberWithCountryCode = '';
  permitConversations = false;
  showDailyQuote = false;

  serviceGroupListPageNumber = 1;
  pageSize = 100000;
  serviceGroupListSearchQuery = '';
  loadingGroup = false;
  selectedGroups: FormControl = new FormControl([]);
  inputServiceGroup$: Subject<string> = new Subject<string>();
  firstTimeLoad = true;
  agentGroupList = [];
  selectedAgentGroupList = [];
  timezoneList = [];
  languageList = [];
  personTypeList = [];
  accountList = [];
  selectedAccount: FormControl = new FormControl([]);
  isSystemAdmin = false;
  showTeamMemberGroups = true;
  participantLabel = 'Participant';

  constructor(dialogService: DialogService, fb: FormBuilder, private authentication: AuthenticationHelper, private spinner: ThemeLoader,
              private businessAccountService: BusinessAccountService, public constants: Constants, private surveyService: SurveyService,
              private refdataService: RefdataService, private agentGroupService: AgentGroupService,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService, private commonComponent: CommonComponent) {
    super(dialogService);

    this.isSystemAdmin = this.authentication.getUserRole() === this.constants.systemAdmin;

    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: '2500'
    };

    this.data = {};

    this.userForm = fb.group({
      'firstName': [null, Validators.compose([Validators.required, BlankSpaceValidator.validate, Validators.minLength(2), Validators.maxLength(50)])],
      'lastName': [null, Validators.compose([Validators.required, BlankSpaceValidator.validate, Validators.minLength(2), Validators.maxLength(50)])],
      'email': [null, Validators.compose([Validators.minLength(0), Validators.maxLength(80)])],
      'phoneNumber': [null, Validators.compose([Validators.maxLength(13)])],
      'userName': ['', Validators.compose([Validators.nullValidator])], // No validation required
      'personType': ['', Validators.compose([Validators.required])],
      'languages': ['', Validators.compose([Validators.nullValidator])], // No validation required
      'timezones': ['', Validators.compose([Validators.nullValidator])], // No validation required
      'agentGroups': ['', Validators.compose([Validators.nullValidator])],
      'selectedAccount': ['', Validators.compose([Validators.nullValidator])],  // No validation required
      'permitConversationsFlag': [null, Validators.nullValidator],
      'showDailyQuoteFlag': [null, Validators.nullValidator],
    });

    this.firstName = this.userForm.controls['firstName'];
    this.lastName = this.userForm.controls['lastName'];
    this.userName = this.userForm.controls['userName'];
    this.email = this.userForm.controls['email'];
    this.phoneNumber = this.userForm.controls['phoneNumber'];
    this.ext = this.userForm.controls['ext'];
    this.personType = this.userForm.controls['personType'];

    this.inputServiceGroup$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(
      value => this.onSearch(value)
    );
  }

  onSubmit(values: any) {
    if ((this.currentUserRole !== this.constants.businessAdmin && this.currentUserRole !== this.constants.systemAdmin) ||
         this.selectedServiceGroupList.length === 0 ) {
      this.selectedServiceGroupList.push(this.authentication.getSelectedObject());
    }
    if (typeof values.userRole === 'undefined') {
      values.userRole = this.editUserRole;
    }
    this.spinner.show();
    this.saveUserBtn = true;

    if (this.userForm.valid) {

      const defaultGroupId = this.selectedAgentGroupList.length > 0 ? this.selectedAgentGroupList[0] : null;
      const data: any = {
        id: null,
        firstName: this.commonComponent.trimLeftAndRightSpaces(values.firstName),
        lastName: this.commonComponent.trimLeftAndRightSpaces(values.lastName),
        fullName: values.firstName + ' ' + values.lastName,
        email: this.commonComponent.trimLeftAndRightSpaces(values.email),
        userName: this.commonComponent.trimLeftAndRightSpaces(values.userName),
        mobileNumber: this.commonComponent.trimLeftAndRightSpaces(values.phoneNumber),
        activated: true,
        businessAccountId: values.selectedAccount.id,
        defaultGroupId: defaultGroupId,
        languageId: (values.languages != null) ? values.languages.id : null,
        timezoneId: (values.timezones != null) ? values.timezones.id : null,
        permitConversations: this.permitConversations,
        showDailyQuote: this.showDailyQuote,
        personTypeId: values.personType.id,
        serviceGroupList: this.selectedServiceGroupList,
        groupList: this.selectedAgentGroupList
      };

      if (typeof this.userId !== 'undefined' && this.userId !== '') {
        data.id = this.userId;
        this.businessAccountService.updateBusinessEmployees(data).subscribe(
          data1 => this.success(data1),
          error => this.error(error)
        );
      } else {
        data.activated = false;
        this.businessAccountService.addBusinessEmployees(data).subscribe(
          data2 => this.success(data2),
          error => this.error(error)
        );
      }

    } else {
      this.error({validationError: 'Team member data is missing or invalid'})
    }
  }

  success(data) {
    this.saveUserBtn = false;
    this.spinner.hide();
    if (data.status === 1) {
      this.result = data;
      this.close();
      if (typeof this.userId !== 'undefined' && this.userId !== '') {
        toastr.success(this.constants.updatedSuccess);
      } else {
        toastr.success(this.constants.createdSuccess);
      }
    } else {
      toastr.error(data.message);
    }
  }

  onScroll() {
  }

  onSearch(event) {
    this.loadingGroup = true;
    this.serviceGroupListSearchQuery = event;
    this.serviceGroupListPageNumber = 1;
    this.serviceGroupList = [];
    this.getAllServiceGroups();
  }

  setSingleUserValueInForm() {
    console.log('setSingleUserValueInForm - start');

    if (typeof this.userId !== 'undefined' && this.userId !== '') {
      const singleBody = this.userDetails;
      this.firstName.patchValue(singleBody.firstName);
      this.lastName.patchValue(singleBody.lastName);
      this.email.patchValue(singleBody.email);
      // this.email.disable();
      if (singleBody.countryCallingCode) {
        this.phoneNumberWithCountryCode = singleBody.countryCallingCode + singleBody.mobile;
        this.phoneNumber.patchValue(singleBody.mobile);
      } else {
        this.phoneNumberWithCountryCode = singleBody.mobileNumber;
        this.phoneNumber.patchValue(singleBody.mobileNumber);
      }
      this.userName.patchValue(singleBody.userName);
      this.permitConversations = singleBody.permitConversations;
      this.showDailyQuote = singleBody.showDailyQuote;
      this.showTeamMemberGroups = true;

      let timezoneValue = null;
      if (this.timezoneList) {
        for (const tz of this.timezoneList ) {
          if ( tz.id === singleBody.timezoneId ) {
            timezoneValue = tz;
            break;
          }
        }
      }

      let languageValue = null;
      if (this.languageList) {
        for ( const lang of this.languageList) {
          if ( lang.id === singleBody.languageId) {
            languageValue = lang;
            break;
          }
        }
      }

      let personTypeValue = null;
      if ( this.personTypeList ) {
        for ( const ptype of this.personTypeList ) {
          if ( ptype.id === singleBody.personTypeId ) {
            personTypeValue = ptype;
            break;
          }
        }
      }

      let accountValue = null;
      if ( this.accountList ) {
        for ( const acct of this.accountList ) {
          if ( acct.id === singleBody.businessAccountId ) {
            accountValue = acct;
            break;
          }
        }
      }

      if ( personTypeValue ) {
        this.showTeamMemberGroups = (personTypeValue.id !== 1);
        if ( personTypeValue.name === 'Participant' && this.participantLabel !== 'Participant' ) {
          personTypeValue.name = this.participantLabel;
        }
        this.userForm.get('personType').patchValue(personTypeValue);
      } else {
        this.showTeamMemberGroups = false;
      }

      if ( timezoneValue ) {
        this.userForm.get('timezones').patchValue(timezoneValue);
      }

      if ( languageValue ) {
        this.userForm.get('languages').patchValue(languageValue);
      }

      if ( accountValue ) {
        this.userForm.get('selectedAccount').patchValue(accountValue);
      }

      //  old single select of team member group:
      // let agentGroupValue = null;
      // if ( this.agentGroupList ) {
      //   for ( const group of this.agentGroupList ) {
      //     if ( group.id === singleBody.defaultGroupId ) {
      //       agentGroupValue = {id: group.id, name: group.name};
      //       break;
      //     }
      //   }
      // }
      //
      // if ( agentGroupValue ) {
      //   this.userForm.get('agentGroups').patchValue(agentGroupValue);
      // }

      this.selectedAgentGroupList = [];
      if ( this.showTeamMemberGroups ) {
        const listOfAgentGroups = singleBody.groupList;
        if (listOfAgentGroups && listOfAgentGroups.length > 0) {
          for ( let i = 0; i < listOfAgentGroups.length; i++ ) {
            const singleAgentGroup = this.agentGroupList.filter(x => x.id === parseInt(listOfAgentGroups[i], 10));
            if ( singleAgentGroup.length ) {
              this.agentGroups.patchValue([...this.agentGroups.value, listOfAgentGroups[i]]);
            }
          }
        }
        this.selectedAgentGroupList = this.agentGroups.value;
      }

      const listOfService = singleBody.serviceGroupList;
      if (listOfService.length > 0) {
        for (let i = 0; i < listOfService.length; i++) {
          const singleServiceGroup = this.serviceGroupList.filter(x => x.id === parseInt(listOfService[i], 10));
          if (singleServiceGroup.length) {
            this.selectedGroups.patchValue([...this.selectedGroups.value, listOfService[i]]);
          }
        }
        this.selectedServiceGroupList = this.selectedGroups.value;
      }
    }
    this.spinner.hide();
  }

  selectedServiceGroup(value: any): void {
    this.selectedServiceGroupList = this.selectedGroups.value;
  }

  removeServiceGroup(value: any): void {
    const index = this.selectedServiceGroupList.indexOf(value.id);
    if (index > -1) {
      this.selectedServiceGroupList.splice(index, 1);
    }
  }

  selectedAgentGroup(value: any): void {
    this.selectedAgentGroupList = this.agentGroups.value;
  }

  removeAgentGroup(value: any): void {
    const index = this.selectedAgentGroupList.indexOf(value.id);
    if (index > -1) {
      this.selectedAgentGroupList.splice(index, 1);
    }
  }

  getAllServiceGroups() {
    // this.spinner.show();
    const businessId = this.isSystemAdmin ? 0 : this.authentication.getBusinessID();
    this.businessAccountService.getServiceGroupList(businessId).subscribe(
        data => this.getAllServiceGroupsSuccess(data),
        error => {
          this.spinner.hide();
          this.loadingGroup = false;
        }
    );
  }

  getAllServiceGroupsSuccess(data) {
    this.loadingGroup = false;
    let list: any;
    if (data.status === 1) {
      const serviceGroupList = data.body;
      for (let i = 0; i < serviceGroupList.length; i++) {
        list = {
          id: serviceGroupList[i].id,
          name: serviceGroupList[i].name
        };
        this.serviceGroupList = [...this.serviceGroupList, list];
      }
    }
    // this.spinner.hide();
  }

  private getLanguages() {
    this.refdataService.getLanguageList().subscribe(
      data => this.languageList = data.body,
      error => this.spinner.hide()
    );
  }

  private getTimezones() {
    this.refdataService.getTimezoneList().subscribe(
      data => this.timezoneList = data.body,
      error => this.spinner.hide()
    );
  }

  private getPersonTypes() {
    this.refdataService.getPersonTypeList().subscribe(
      data => this.getPersonTypesSuccess(data),
      error => this.spinner.hide()
    );
  }

  getPersonTypesSuccess(data) {
    if ( data.status === 1 ) {
       this.personTypeList = data.body;
       if ( this.personTypeList && this.personTypeList.length > 0 && this.participantLabel && this.participantLabel !== 'Participant' ) {
          for ( const ptype of this.personTypeList ) {
            if ( ptype.name === 'Participant' ) {
              ptype.name = this.participantLabel;
              break;
            }
          }
       }
    }
  }

  private getBusinessAccounts() {
    this.agentGroupService.getAgentBusinessAccounts().subscribe(
      data => this.accountList = data.body,
      error => this.spinner.hide()
    );
  }

  private getAllAgentGroups() {
    this.agentGroupService.getAgentGroupList().subscribe(
      data => this.agentGroupListSuccess(data),
      error => this.spinner.hide()
    );
  }

  private agentGroupListSuccess(data) {
    if ( data.status === 1 ) {
      this.agentGroupList = data.body;
      this.agentGroupList = this.agentGroupList.map(group => {
        return {
          id: group.id,
          name: group.name
        };
      });
    }
  }

  error(error) {
    this.loadingGroup = false;
    this.spinner.hide();
    this.saveUserBtn = false;
    const message = (typeof error.validationError !== 'undefined') ? error.validationError : this.constants.serverError;
    toastr.error(message);
  }

  setPhoneNumber(value) {
    if (value) {
      this.countryCode = value.countryCode;
      this.phoneNumber.patchValue(value.phoneNo);
    } else {
      this.userForm.controls['phoneNumber'].setErrors({'incorrect': true});
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.participantLabel = this.refdataService.getParticipantLabel();
    this.getLanguages();
    this.getTimezones();
    this.getPersonTypes();
    this.getBusinessAccounts();
    this.getAllAgentGroups();
    this.getAllServiceGroups();
    if (typeof this.userId !== 'undefined' && this.userId !== '') {
      setTimeout(() => {
      this.setSingleUserValueInForm(); }, 1000);
    } else {
      this.spinner.hide();
    }
    AppModalService.openModal(this);
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }



}
