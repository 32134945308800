import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BlankSpaceValidator} from '../../validators/blankSpace.validator';

declare let $;

@Component({
  selector: 'custom-intl-tel-input-plugin',
  template: `
    <form [formGroup]="phoneForm" [ngClass]="{'has-error': (!phone.valid && phone.touched), 'has-success': (phone.valid && phone.touched)}">
      <ngx-intl-tel-input maxlength="15"
        class="form-control padding-zero"
        [cssClass]="'custom'"
        [preferredCountries]="['us']"
        [enablePlaceholder]="false"
        [enableAutoCountrySelect]="true"
        name="phone"
        (keyup)="onKeyUp()"
        [formControl]="phone"></ngx-intl-tel-input>
    </form>`
})

export class CustomIntlTelInputPluginComponent {

  @Input('content') content = '';
  phoneForm: FormGroup;
  phone: AbstractControl;
  @Output('onChange') change: EventEmitter<any> = new EventEmitter<any>();
  @Input('isRequired') isRequired: boolean = true;
  @Input('sourceType') sourceType;

  constructor(fb: FormBuilder) {
    this.phoneForm = fb.group({
      'phone': [null]
    });
    this.phone = this.phoneForm.controls['phone'];

    this.addValidations();
  }

  addValidations() {
    if (this.isRequired) {
      this.phoneForm.controls['phone'].setValidators(Validators.compose([Validators.required, BlankSpaceValidator.validate]));
      this.phoneForm.controls['phone'].updateValueAndValidity({onlySelf: true, emitEvent: false});
    } else {
      this.phoneForm.controls['phone'].setValidators(Validators.compose([BlankSpaceValidator.validate]));
      this.phoneForm.controls['phone'].updateValueAndValidity({onlySelf: true, emitEvent: false});
    }
  }

  @Input()
  set phoneNo(value) {
    if (value) {
      this.phone.patchValue(value);
    }
  }

  @Input()
  set disabled(value) {
    if (value) {
      this.phone.disable();
    } else {
      this.phone.enable();
    }
  }
// internationalNumber === '+1 911'
  onKeyUp() {
    if (this.sourceType === 'emergency-contact' && this.phoneForm.controls['phone'].value && this.phoneForm.controls['phone'].value.internationalNumber) {
      const intNumber = this.phoneForm.controls['phone'].value.internationalNumber;
      const lenNum = intNumber.length;
      const localNumber = lenNum > 3 ? intNumber.substr(lenNum - 3, lenNum) : null;
      if ( localNumber && localNumber.charAt(0) === '9' ) {
        this.phoneForm.controls['phone'].setErrors(null);
      }
    }
    if (this.phoneForm.controls['phone'].valid) {
      const mobile = this.phoneForm.controls['phone'].value.internationalNumber;
      const number = {
        countryCode: mobile.substr(0, mobile.indexOf(' ')),
        phoneNo: mobile.substr(mobile.indexOf(' '), mobile.length).replace(/(-| )/g, '')
      };
      this.change.emit(number);
    } else {
      if (this.content) {
        const data = {
          valid: this.phoneForm.controls['phone'].valid,
          numberObj: this.phoneForm.controls['phone'].value
        };
        this.change.emit(data);
      } else {
        this.change.emit(this.phoneForm.controls['phone'].valid);
      }
    }
  }

}
