import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {Constants} from '../../constants';
import {UUID} from 'angular2-uuid';
import * as moment from 'moment';

declare let toastr;

@Component({
  selector: 'recurrenceV1',
  templateUrl: './recurrenceV1.component.html',
  styleUrls: ['./recurrenceV1.component.scss']
})

/**
 * important note: this is the original loop start task -- but it is really a scheduling task.
 * use this as a basis for improvement of the wait until task
 */
export class RecurrenceV1Component implements OnInit {
  title: string;
  @Input('sourceType') sourceType: string = '';
  @Output() isRecurrence: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedData: EventEmitter<Object> = new EventEmitter<Object>();
  validateData: boolean = true;
  publishStartEndDateTime: any = {};
  startDateOptions: any = {
    timePicker: true,
    autoApply: true,
    locale: {
      format: 'MM/DD/YYYY HH:mm'
    },
    singleDatePicker: true,
    timePicker24Hour: true,
    showDropdowns: true
  };
  endDateOptions: any = {
    timePicker: true,
    autoApply: true,
    locale: {
      format: 'MM/DD/YYYY HH:mm'
    },
    singleDatePicker: true,
    timePicker24Hour: true,
    showDropdowns: true
  };
  repeatFlag: boolean;
  timeTypeList: any = ['ON', 'AT', 'BETWEEN'];
  onTimeList: any = [
    {id: 15, name: 'Every 15 Minutes'}, {id: 30, name: 'Every 30 Minutes'},
    {id: 45, name: 'Every 45 Minutes'}, {id: 60, name: 'Every 1 Hour'}, {id: 120, name: 'Every 2 Hour'},
    {id: 180, name: 'Every 3 Hour'}, {id: 360, name: 'Every 6 Hour'}, {id: 720, name: 'Every 12 Hour'}
  ];
  dayTypeList: any = ['ON', 'DATE', 'DAY', 'FIRST', 'SECOND', 'THIRD', 'FOURTH', 'LAST'];
  onDayList: any = [
    {id: 'ALL', name: 'Every Day'}, {id: 'EVEN_DAYS', name: 'Even Days'}, {id: 'ODD_DAYS', name: 'Odd Days'}
  ];
  dateList: any = [];
  dayList: any = [
    {id: 'SUNDAY', name: 'Sun'}, {id: 'MONDAY', name: 'Mon'}, {id: 'TUESDAY', name: 'Tue'},
    {id: 'WEDNESDAY', name: 'Wed'}, {id: 'THURSDAY', name: 'Thu'}, {id: 'FRIDAY', name: 'Fri'}, {id: 'SATURDAY', name: 'Sat'}
  ];
  monthTypeList: any = ['ON', 'MONTH'];
  onMonthList: any = [
    {id: 'ALL', name: 'Every Month'}, {id: 'EVEN_MONTHS', name: 'Even Months'}, {id: 'ODD_MONTHS', name: 'Odd Months'}
  ];
  monthList: any = [
    {id: 'JANUARY', name: 'Jan'}, {id: 'FEBRUARY', name: 'Feb'}, {id: 'MARCH', name: 'Mar'},
    {id: 'APRIL', name: 'Apr'}, {id: 'MAY', name: 'May'}, {id: 'JUNE', name: 'Jun'}, {id: 'JULY', name: 'July'},
    {id: 'AUGUST', name: 'Aug'}, {id: 'SEPTEMBER', name: 'Sep'}, {id: 'OCTOBER', name: 'Oct'}, {id: 'NOVEMBER', name: 'Nov'},
    {id: 'DECEMBER', name: 'Dec'}
  ];
  timeConditions: any = [];
  dayConditions: any = [];
  monthConditions: any = [];
  isMultipleInstanceAllowed: boolean = false;
  numberOfInstances: FormControl = new FormControl(0);

  constructor(fb: FormBuilder, private constants: Constants) {

  }


  @Input()
  set saveButtonClick(value) {
    if (value) {
      this.saveTimeSchedulerData();
    }
  }

  @Input()
  set publishDateTimeData(value) {
    let response;
    if (typeof value['recurrencePattern'] !== 'undefined') {
      response = value['recurrencePattern'];
      this.isMultipleInstanceAllowed = value['isMultipleInstanceAllowed'];
      this.numberOfInstances.patchValue(value['maxNumberOfInstances']);
    } else {
      response = value;
    }
    response = JSON.parse(JSON.stringify(response));
    if (response && response.startDateTime) {
      response.startDateTime = response.startDateTime.split(' ');
      response.startDateTime = response.startDateTime[0].replace('T', ' ');
      this.publishStartEndDateTime.start = moment(response.startDateTime).format('YYYY-MM-DD HH:mm');
      this.startDateOptions.startDate = moment(response.startDateTime).format('MM/DD/YYYY HH:mm');
    } else {
      this.publishStartEndDateTime.start = moment().format('YYYY-MM-DD HH:mm');
      this.startDateOptions.startDate = moment().format('MM/DD/YYYY HH:mm');
    }
    if (response && response.repeat) {
      this.setIsRecurrence(true);
      this.repeatFlag = response.repeat;
    } else {
      this.repeatFlag = false;
      this.setIsRecurrence(false);
    }
    if (response && typeof response.recurrence !== 'undefined' && response.recurrence) {
      const recurrenceData = response.recurrence;
      if (recurrenceData.endDateTime) {
        recurrenceData.endDateTime = recurrenceData.endDateTime.split(' ');
        recurrenceData.endDateTime = recurrenceData.endDateTime[0].replace('T', ' ');
      }
      this.publishStartEndDateTime.end = moment(recurrenceData.endDateTime).format('YYYY-MM-DD HH:mm');
      this.endDateOptions.startDate = moment(recurrenceData.endDateTime).format('MM/DD/YYYY HH:mm');

      this.timeConditions = recurrenceData.times;
      this.timeConditions.forEach((timeCondition) => {
        if (timeCondition.type === 'ON') {
          timeCondition.meta.data.forEach((metaData) => {
            if (metaData.text) {
              delete Object.assign(metaData, {['name']: metaData['text']})['text'];
            }
          });
        }
      });

      this.dayConditions = recurrenceData.days;
      this.dayConditions.forEach((dayCondition) => {
        dayCondition.meta.data.forEach((metaData) => {
          if (metaData.text) {
            delete Object.assign(metaData, {['name']: metaData['text']})['text'];
          }
        });
      });

      this.monthConditions = recurrenceData.months;
      this.monthConditions.forEach((monthCondition) => {
        monthCondition.meta.data.forEach((metaData) => {
          if (metaData.text) {
            delete Object.assign(metaData, {['name']: metaData['text']})['text'];
          }
        });
      });

    } else {
      this.publishStartEndDateTime.end = moment().add(1, 'months').format('YYYY-MM-DD HH:mm');
      this.endDateOptions.startDate = moment().add(1, 'months').format('MM/DD/YYYY HH:mm');
      if (this.timeConditions.length == 0) {
        this.addTimeCondition();
      }
      if (this.dayConditions.length == 0) {
        this.addDayCondition();
      }
      if (this.monthConditions.length == 0) {
        this.addMonthCondition();
      }
    }
  }

  selectedStartDate(value) {
    this.publishStartEndDateTime.start = value.start;
  }

  selectedEndDate(value) {
    this.publishStartEndDateTime.end = value.end;
  }

  addTimeCondition() {
    const singleTimeConditions = {
      id: UUID.UUID(),
      seqno: this.timeConditions.length + 1,
      type: this.timeTypeList[0],
      meta: {
        data: [{
          id: 60,
          name: 'Every 1 Hour'
        }]
      }
    };
    this.timeConditions.push(singleTimeConditions);
  }

  setTimeCondition(currentIndex, selector, key, value) {
    switch (selector) {
      case 'type':
        let meta;
        this.timeConditions[currentIndex][selector] = value;
        if (this.timeConditions[currentIndex]['type'] == this.timeTypeList[0]) {
          this.timeConditions[currentIndex]['meta'] = {
            data: [{
              id: 60,
              name: 'Every 1 Hour'
            }]
          };
        }
        if (this.timeConditions[currentIndex]['type'] == this.timeTypeList[1]) {
          meta = {
            on: new Date()
          };
          this.timeConditions[currentIndex]['meta'] = meta;
        }
        if (this.timeConditions[currentIndex]['type'] == this.timeTypeList[2]) {
          meta = {
            from: new Date(),
            to: new Date(),
            repeat: 1,
            random: false
          };
          this.timeConditions[currentIndex]['meta'] = meta;
        }
        break;
      case 'meta':
        this.timeConditions[currentIndex][selector][key] = value;
        break;
    }
  }

  deleteTimeCondition(item) {
    this.timeConditions.splice(item, 1);
    for (let i = 0; i < this.timeConditions.length; i++) {
      this.timeConditions[i].seqno = i + 1;
    }
  }

  addDayCondition() {
    const singleDayConditions = {
      id: UUID.UUID(),
      seqno: this.dayConditions.length + 1,
      type: this.dayTypeList[0],
      meta: {
        data: [{
          id: 'ALL',
          name: 'Every Day'
        }]
      }
    };
    this.dayConditions.push(singleDayConditions);
  }

  setDayCondition(currentIndex, selector, key, value) {
    switch (selector) {
      case 'type':
        this.dayConditions[currentIndex][selector] = value;
        if (this.dayConditions[currentIndex]['type'] == this.dayTypeList[0]) {
          this.dayConditions[currentIndex]['meta'] = {
            data: [{
              id: 'ALL',
              name: 'Every Day'
            }]
          };
        } else if (this.dayConditions[currentIndex]['type'] == this.dayTypeList[1]) {
          this.dayConditions[currentIndex]['meta'] = {
            data: [{
              id: 1,
              name: '1'
            }]
          };
        } else {
          this.dayConditions[currentIndex]['meta'] = {
            data: [{
              id: 'MONDAY',
              name: 'Mon'
            }]
          };
        }
        break;
      case 'meta':
        this.dayConditions[currentIndex][selector][key] = value;
        break;
    }
  }

  deleteDayCondition(item) {
    this.dayConditions.splice(item, 1);
    for (let i = 0; i < this.dayConditions.length; i++) {
      this.dayConditions[i].seqno = i + 1;
    }
  }

  addMonthCondition() {
    const singleDayConditions = {
      id: UUID.UUID(),
      seqno: this.monthConditions.length + 1,
      type: this.monthTypeList[0],
      meta: {
        data: [{
          id: 'ALL',
          name: 'Every Month'
        }]
      }
    };
    this.monthConditions.push(singleDayConditions);
  }

  setMonthCondition(currentIndex, selector, key, value) {
    switch (selector) {
      case 'type':
        this.monthConditions[currentIndex][selector] = value;
        if (this.monthConditions[currentIndex]['type'] == this.monthTypeList[0]) {
          this.monthConditions[currentIndex]['meta'] = {
            data: [{
              id: 'ALL',
              name: 'Every Month'
            }]
          };
        } else {
          this.monthConditions[currentIndex]['meta'] = {
            data: [{
              id: 'JANUARY',
              name: 'Jan'
            }]
          };
        }
        break;
      case 'meta':
        this.monthConditions[currentIndex][selector][key] = value;
        break;
    }
  }

  deleteMonthCondition(item) {
    this.monthConditions.splice(item, 1);
    for (let i = 0; i < this.monthConditions.length; i++) {
      this.monthConditions[i].seqno = i + 1;
    }
  }

  saveTimeSchedulerData() {
    this.validateData = true;
    let errorMessage = '';
    if (this.repeatFlag) {
      for (let i = 0; i < this.timeConditions.length; i++) {
        let timeData: any = [];
        switch (this.timeConditions[i].type) {
          case this.timeTypeList[0]:
            timeData = [];
            const metaData = this.timeConditions[i].meta.data;
            if (metaData.length > 0) {
              for (let x = 0; x < metaData.length; x++) {
                timeData.push(metaData[x].id);
              }
            } else {
              this.validateData = false;
              errorMessage = 'Please select time in within a day';
            }
            this.timeConditions[i].data = timeData;
            break;
          case this.timeTypeList[1]:
            const onTime = moment(this.timeConditions[i].meta.on);
            timeData = {
              hour: onTime.format('HH'),
              minute: onTime.format('mm'),
            };
            this.timeConditions[i].data = timeData;
            break;
          case this.timeTypeList[2]:
            const fromTime = moment(this.timeConditions[i].meta.from);
            const toTime = moment(this.timeConditions[i].meta.to);
            if (!moment(fromTime).isBefore(toTime)) {
              this.validateData = false;
              errorMessage = 'In within a day To time should be greater than From time';
            }
            timeData = {
              from: {
                hour: fromTime.format('HH'),
                minute: fromTime.format('mm')
              },
              to: {
                hour: toTime.format('HH'),
                minute: toTime.format('mm')
              },
              repeat: this.timeConditions[i].meta.repeat,
              random: this.timeConditions[i].meta.random
            };
            this.timeConditions[i].data = timeData;
            break;
        }
      }
      for (let i = 0; i < this.dayConditions.length; i++) {
        const dayData: any = [];
        const metaData = this.dayConditions[i].meta.data;
        if (metaData.length > 0) {
          for (let x = 0; x < metaData.length; x++) {
            dayData.push(metaData[x].id);
          }
        } else {
          this.validateData = false;
          errorMessage = 'Please select any day';
        }
        this.dayConditions[i].data = dayData;
      }
      for (let i = 0; i < this.monthConditions.length; i++) {
        const monthData: any = [];
        const metaData = this.monthConditions[i].meta.data;
        if (metaData.length > 0) {
          for (let x = 0; x < metaData.length; x++) {
            monthData.push(metaData[x].id);
          }
          this.monthConditions[i].data = monthData;
        } else {
          this.validateData = false;
          errorMessage = 'Please select any month';
        }
      }
    }
    const data = {
      startDateTime: moment(this.publishStartEndDateTime.start).format('YYYY-MM-DDTHH:mm Z'),
      repeat: this.repeatFlag,
      recurrence: {
        endDateTime: moment(this.publishStartEndDateTime.end).format('YYYY-MM-DDTHH:mm Z'),
        times: this.timeConditions,
        days: this.dayConditions,
        months: this.monthConditions
      }
    };

    if (data.repeat && !moment(this.publishStartEndDateTime.start).isBefore(this.publishStartEndDateTime.end)) {
      this.validateData = false;
      errorMessage = this.constants.startEndDateTimeValidation;
    }

    if (this.sourceType === this.constants.workflowCustomParams.ACTION_FLOW && this.isMultipleInstanceAllowed && (this.numberOfInstances.value < 1 || this.numberOfInstances.value > 100)) {
      this.validateData = false;
      errorMessage = 'Please add maximum number of instances value between 1 to 100';
    }

    const recurrenceData = {
      recurrencePattern: data,
      validateData: this.validateData,
      message: errorMessage,
      isMultipleInstanceAllowed: this.isMultipleInstanceAllowed,
      maxNumberOfInstances: this.numberOfInstances.value
    };

    this.savedData.emit(recurrenceData);
  }

  setDateList() {
    for (let i = 1; i <= 31; i++) {
      let data;
      data = {
        id: i,
        name: i.toString()
      };
      this.dateList.push(data);
    }
  }

  onChangeMultipleInstancesStatus(isMultipleInstanceAllowed) {
    this.isMultipleInstanceAllowed = isMultipleInstanceAllowed;
    if (this.isMultipleInstanceAllowed) {
      this.numberOfInstances.patchValue(1);
    } else {
      this.numberOfInstances.patchValue(0);
    }
  }

  setIsRecurrence(value) {
    this.repeatFlag = value;
    if (!value) {
      this.onChangeMultipleInstancesStatus(false);
    }
    this.isRecurrence.emit(value);
  }

  ngOnInit() {
    this.setDateList();
  }
}
