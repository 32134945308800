import {Directive, HostListener, Input} from '@angular/core';
import {AppModalService} from '../services/app.modalService';

@Directive({
  selector: '[appModal]',
  providers: [AppModalService]
})
export class ModalOperationsDirective {
  @Input() appModal;

  @HostListener('window:keyup.escape', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      AppModalService.closeLastModal();
    }
  }
}
