import {ChangeDetectorRef, Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../constants';
import {Router} from '@angular/router';
import {CommonComponent} from '../commonComponent';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {AppModalService} from '../../services/app.modalService';

declare let toastr, $: any;

// tslint:disable-next-line:class-name
export interface validationResultsDialogModel {
  title: any;
  validationErrors: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'validationResultsModal',
  templateUrl: './validationResultsModal.component.html',
  styleUrls: ['./validationResultsModal.component.scss'],
  providers: [AppRouteChangeSubscriptionService]
})

// tslint:disable-next-line:max-line-length class-name
export class validationResultsModalDialogComponent extends DialogComponent<validationResultsDialogModel, string> implements validationResultsDialogModel {
  title = 'Validation Results';
  validationErrors = [];

  // tslint:disable-next-line:max-line-length
  constructor(dialogService: DialogService, fb: FormBuilder, public constants: Constants, private router: Router, private cdrf: ChangeDetectorRef,
              public commonComponent: CommonComponent, private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService) {
    super(dialogService);
  }

  setRouting() {
    // if (this.data !== 'undefined' && this.data && this.data.name) {
    //   return;
    // } else {
    //   this.router.navigate(['/journeydef']);
    // }
  }

  ngOnInit() {
    AppModalService.openModal(this);
    // this.setValueInForm();
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngAfterViewChecked() {
    this.cdrf.detectChanges();
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}
