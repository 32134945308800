import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjectService} from '../../services/app.object.service';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';
import {CommonComponent} from '../../shared/commonComponent';
import {RefdataService} from '../../services/app.refdata.service';
import {AuthenticationHelper} from '../../app.authentication';
import {Constants} from '../../constants';
import {ThemeLoader} from '../../app.component.loader';
import {AppModalService} from '../../services/app.modalService';
import {ContactService} from '../../services/app.contact.service';

declare let toastr;

export interface userStatusModalDialogModel {
  title: any;
  personId: any;
}

@Component({
  selector: 'userStatusModal',
  templateUrl: './userStatusModal.component.html',
  styleUrls: ['../settings/settings.component.scss'],
  providers: [ObjectService, AppRouteChangeSubscriptionService, ContactService]
})

export class UserStatusModalDialogComponent extends DialogComponent<userStatusModalDialogModel, string> implements userStatusModalDialogModel {
  title: any;
  personId: any;
  userStatusForm: any;
  userActivated = false;
  saveUserStatusBtn = false;
  userStatusSelect: AbstractControl;
  userStatusData = {
    activated: false,
    userStatus: 'Inactive',
    personId: null
  }

  constructor(dialogService: DialogService, fb: FormBuilder, private objectService: ObjectService,
              private authentication: AuthenticationHelper, public constants: Constants, private spinner: ThemeLoader,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService,
              private contactService: ContactService) {
    super(dialogService);
    this.userStatusForm = fb.group({
      'userStatusSelect': [[], Validators.compose([Validators.required])],
    });
    this.userStatusSelect = this.userStatusForm.controls['userStatusSelect'];
  }

  setUserStatusValue(value: any) {
    this.userStatusData.userStatus = value;
  }

  onSubmit(values) {
    this.userStatusData.activated = this.userActivated;
    this.userStatusData.userStatus = values.userStatusSelect;
    this.spinner.show();
    this.contactService.setParticipantStatus(this.userStatusData).subscribe(
      data => this.saveSuccess(data),
      error => this.error(error)
    );
  }

  private saveSuccess(data) {
    this.spinner.hide();
    if ( data.status === 1 ) {
      this.result = data;
      this.close();
    } else {
      toastr.error(data.message);
    }
  }

  private getUserStatusData() {
    this.contactService.getPersonStatus(this.personId).subscribe(
      data => this.getUserStatusDataSuccess(data),
      error => this.error(error)
    );
  }

  private getUserStatusDataSuccess(data) {
    this.spinner.hide();
    if ( data.status === 1 ) {
      this.userStatusData = data.body;
      this.userActivated = data.body.activated;
      if ( this.userStatusData.userStatus ) {
        this.userStatusSelect.patchValue(this.userStatusData.userStatus);
      }
    }
  }

  private error(error) {
    this.spinner.hide();
    toastr.error(this.constants.serverError)
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.spinner.show();
    this.getUserStatusData();
    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }


}

