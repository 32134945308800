import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AgentGroupService} from "../../services/app.agentGroup.service";
declare let toastr;

@Component({
  selector: 'agent-group-list',
  templateUrl: './agentGroupList.component.html',
  styleUrls: ['./agentGroupList.component.scss'],
  providers: [AgentGroupService]
})

export class AgentGroupListComponent {

  selectedAgentGroup: FormControl = new FormControl([]);
  agentGroup;
  defaultValue;
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private agentGroupService: AgentGroupService) {

  }

  setSelectedAgentGroup(value: any): void {
    this.defaultValue = this.selectedAgentGroup.value;
    this.selectedData.emit(this.defaultValue);
  }

  removeAgentGroup(value: any): void {
    const index = this.defaultValue.indexOf(value.id);
    if (index > -1) {
      this.defaultValue.splice(index, 1);
    }
  }

  getAllListOfAgentGroup() {
    this.agentGroupService.getAgentGroupList().subscribe(
      data => this.allAgentGroups(data),
      error => this.error(error)
    )
  }

  allAgentGroups(data) {
    if (data.status == 1) {
      this.agentGroup = data.body;
    }
  }

  error(error){

  }

  ngOnInit(){
    this.getAllListOfAgentGroup();
  }
}
