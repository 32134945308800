import * as moment from 'moment';

export class CommonComponent {

  convertEpochIntoDateTime(epoch) {
    if (epoch) {
      if ( epoch > 10000000000 ) {
        return moment.unix(epoch / 1000).format('MM/DD/YYYY HH:mm');
      } else {
        return moment.unix(epoch).format('MM/DD/YYYY HH:mm');
      }
    }
    return null;
  }

  convertEpochIntoDate(epoch) {
    if (epoch) {
      if ( epoch > 10000000000 ) {
        return moment.unix(epoch / 1000).format('MM/DD/YYYY')
      } else {
        return moment.unix(epoch).format('MM/DD/YYYY')
      }
    }
    return null;
  }

  getCurrentDate() {
    return this.convertEpochIntoDate(moment.now());
  }

  convertDateIntoEpoch(date) {
    if (date) {
      return moment(date).unix() * 1000;
    }
    return null;
  }

  convertEpochIntoDateTimeWithSeconds(epoch) {
    if (epoch) {
      return moment.unix(epoch).format('MM/DD/YYYY HH:mm:ss')
    }
    return null;
  }

  convertTimeInMilliSeconds(time) {
    if (time) {
      const hours = new Date(time).getHours();
      const minutes = new Date(time).getMinutes();
      const seconds = hours * 60 * 60 + minutes * 60;
      return seconds * 1000;
    }
    return null;
  }

  convertTimeStringToMilliseconds(stime) {
    // Convert a time of day of format HH:MM[:SS] or HH:MM:[SS] AM/PM to milliseconds in day
    // Seconds are optional. If AM or PM is omitted, 24 hour time is assumed.
    if ( stime && stime.length >= 5 ) {
      //  get AM/PM if present
      const isPM = stime.includes('PM');
      const hasAMorPM = stime.includes('AM') || stime.includes('PM');
      if ( hasAMorPM ) {
        stime = stime.substr(0, stime.length - 3);
      }
      //  parse time into hours, minutes, seconds and convert to numbers
      const timeElements = stime.split(':');
      let hours = Number(timeElements[0]);
      if ( isPM && hours !== 12 ) {
        hours += 12;
      } else if ( !isPM && hours === 12 ) {
        hours = 0;
      }
      const minutes = Number(timeElements[1]);
      let seconds = 0;
      if ( timeElements.length > 2 ) {
        seconds = Number(timeElements[2]);
      }
      //  calculate the milliseconds value and return
      const daySeconds = 3600 * hours + 60 * minutes + seconds;
      return daySeconds * 1000;
    } else {
      return null;
    }
  }

  convertMilliSecondsIntoTime(milliSeconds) {
    if (milliSeconds || milliSeconds === 0) {
      const dateObj = new Date();
      const minutes = (milliSeconds / (1000 * 60)) % 60;
      const hours = (milliSeconds / (1000 * 60 * 60)) % 24;
      dateObj.setHours(hours);
      dateObj.setMinutes(minutes);
      return dateObj;
    }
    return null;
  }

  getNameFromHTMLInputString(string) {
    if (string) {
      string = string.replace(/<input\/?[^>]+(>|$)/g, (x) => {
        let subString = x.split('data-name="');
        subString = subString[1];
        if (subString) {
          subString = subString.substring(0, subString.indexOf('"'));
        }
        return subString;
      });
      string = this.removeHtmlTagFromString(string);
      string = this.convertSpecialCharStringToKeyword(string);
      return string;
    }
  }

  removeHtmlTagFromString(string) {
    return string.replace(/<\/?[^>]+(>|$)/g, '');
  }

  removeHtmlTagsExceptInputAndImage(string) {
    return string.replace(/<(?!input|img\s*\/?)[^>]+>/g, '');
  }

  stringifyAndRestoreBrackets(questionString) {
    return JSON.stringify(questionString).replace(/&#60;/gi, '&lt;').replace(/&#62;/gi, '&gt;');
  }

  convertSpecialCharStringToKeyword(string) {
    const translate = {
      'nbsp': ' ',
      'amp': '&',
      'quot': '"',
      'lt': '<',
      'gt': '>'
    };
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    return string.replace(translate_re, (match, entity) => {
      return translate[entity];
    });
  }

  roundOffStats(value) {
    if (value < 1000) {
      return value;
    } else if (value >= 1000 && value < 1000000) {
      if (value % 100 === 0) {
        return value / 1000 + 'k';
      } else {
        value = Math.floor(value / 1000);
        return value + 'k+';
      }
    } else {
      if (value % 100000 === 0) {
        return value / 1000000 + 'm';
      } else {
        value = Math.floor(value / 1000000);
        return value + 'm+';
      }
    }
  }

  convertMilliSecondIntoActualTimeFormat(value) {
    const dateObj = this.convertMilliSecondsIntoTime(value);
    let hours: any = dateObj.getHours();
    let minutes: any = dateObj.getMinutes();
    const getAMorPMTimeString = hours < 12 ? 'AM' : 'PM'; // Set AM/PM
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    value = hours + ':' + minutes + ' ' + getAMorPMTimeString;
    return value;
  }

  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null :
           ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 13);
  }

  checkIfLoginExpired ( maxMinutes, loginMillis ) {
    if ( maxMinutes && loginMillis ) {
      const maxMillis = this.convertMinutesIntoMilliSeconds(maxMinutes);
      const currentMillis = moment.now();
      return (currentMillis - loginMillis) > maxMillis;
    } else {
      return false;
    }
  }

  checkIfPdfExpired ( maxMinutes, lastFetchedMillis ) {
    if ( maxMinutes && lastFetchedMillis ) {
      const maxMillis = this.convertMinutesIntoMilliSeconds(maxMinutes);
      const currentMillis = moment.now();
      return (currentMillis - lastFetchedMillis) > maxMillis;
    } else {
      return false;
    }
  }

  convertUtcIntoActualTime(utcTime) {
    return moment(utcTime).format('MM/DD/YYYY HH:mm');
  }

  convertDaysIntoMilliSeconds(value) {
    return parseInt(value, 10) * 24 * 60 * 60 * 1000;
  }

  convertHoursIntoMilliSeconds(value) {
    return parseInt(value, 10) * 60 * 60 * 1000;
  }

  convertMinutesIntoMilliSeconds(value) {
    return parseInt(value, 10) * 60 * 1000;
  }

  convertMilliSecondsIntoRequireTime(value, type) {
    if (value) {
      switch (type) {
        case 'Days': {
          const x = 86400000;
          value = Math.floor(value / x);
          break;
        }
        case 'Hours': {
          value = this.convertMilliSecondsIntoTime(value).getHours();
          break;
        }
        case 'Minutes': {
          value = this.convertMilliSecondsIntoTime(value).getMinutes();
          break;
        }
      }
      return value;
    }
  }

  onlyAlphabets(event) {
    let regex = /[^A-Za-z0-9]+/g;
    let value = event.target.value;
    if (!isNaN(value.substring(0, 1))) {
      regex = /[^A-Za-z]+/g;
      value = value.replace(regex, '');
    }
    event.target.value = value.replace(regex, '');
  }

  getSurveyQuestionTitle(questionText, questionTextId) {
    if (questionTextId) {
      questionText = questionTextId;
    } else {
      questionText = this.removeHtmlTagFromString(questionText);
      if (questionText.length > 60) {
        questionText = questionText.substr(0, 60) + '...';
      }
    }
    return questionText;
  }

  convertJOSNObjectIntoString(value) {
    value = JSON.stringify(value);
    return value;
  }

  trimLeftAndRightSpaces(value) {
    if (value) {
      value = value.trim();
    }
    return value;
  }

  isNumeric(val: any): boolean {
    return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
  }
}
