import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class AuthenticationHelper {
  public selectedObjectId = 'selectedObjectId';
  private tokenKey = 'auth_token';
  private userRole = 'userRole';
  private email = 'email';
  private userID = 'userID';
  private personID = 'personID';
  private businessId = 'businessId';
  private allObjects = 'allObjects';
  private tokenExpires = 'token_expires';
  private businessLogo = 'businessLogo';
  private businessName = 'businessName';
  private typeOfLogin = 'loginType';
  private surveySearchBy = 'surveySearchBy';
  private ticketSearchBy = 'ticketSearchBy';
  private contactSearchBy = 'contactSearchBy';
  private workflowSearchBy = 'workflowSearchBy';
  private journeyRunSearchBy = 'journeyRunSearchBy';
  private journeyDefSearchBy = 'journeyDefSearchBy';
  private homeActionCenterFilter = 'homeActionCenterFilter';
  private privileges = 'privileges';
  private permitConversations = 'permitConversations';
  private cachedDocumentUrl = 'cachedDocumentUrl';
  private cachedDocumentTemplateId = 'documentTemplateId';
  private isMobileApp = 'isMobileApp';
  private loginTime = 'loginTime';
  private numberOfMessages = 'numberOfMessages';
  private pdfLastFetched = 'pdfLastFetched';

  constructor() {
  }

  isLoggedIn() {
    const token = localStorage.getItem(this.tokenKey);
    if (token && token.length > 0) {
      return true
    }
    return false;
  }

  setLoggedIn(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  setUserRole(userRole) {
    localStorage.setItem(this.userRole, userRole);
  }

  removeLoggedIn() {
    localStorage.clear();
  }

  setCachedDocumentUrl(url) {
    localStorage.setItem(this.cachedDocumentUrl, url);
  }

  getCachedDocumentUrl() {
    return localStorage.getItem(this.cachedDocumentUrl);
  }

  setCachedDocumentTemplateId(templateId) {
    localStorage.setItem(this.cachedDocumentTemplateId, templateId);
  }

  getCacheDocumentTemplateId() {
    return localStorage.getItem(this.cachedDocumentTemplateId);
  }

  setUserEmail(email) {
    localStorage.setItem(this.email, email);
  }

  getUserEmail() {
    return localStorage.getItem(this.email);
  }

  setUserID(userID) {
    return localStorage.setItem(this.userID, userID);
  }

  setPersonID(personID) {
    return localStorage.setItem(this.personID, personID);
  }

  setPrivileges ( privileges: [] ) {
    for ( const priv of privileges ) {
      localStorage.setItem(priv, 'true');
    }
  }

  getPrivilege ( privilegeName: string ) {
    if ( typeof localStorage.getItem(privilegeName) !== 'undefined' ) {
      return localStorage.getItem(privilegeName) === 'true';
    } else {
      return false;
    }
  }

  setBusinessID(businessId) {
    return localStorage.setItem(this.businessId, businessId);
  }

  getBusinessID() {
    return localStorage.getItem(this.businessId);
  }

  setAllObjects(objects) {
    return localStorage.setItem(this.allObjects, objects);
  }

  getSelectedObject() {
    return localStorage.getItem(this.selectedObjectId);
  }

  setSelectedObjects(productId) {
    return localStorage.setItem(this.selectedObjectId, productId);
  }

  getUserID() {
    return localStorage.getItem(this.userID);
  }

  getPersonID() {
    return localStorage.getItem(this.personID);
  }

  getUserRole() {
    return localStorage.getItem(this.userRole);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getBusinessLogo() {
    return localStorage.getItem(this.businessLogo);
  }

  getBusinessName() {
    return localStorage.getItem(this.businessName);
  }

  setTokenExpires(tokenExpires) {
    return localStorage.setItem(this.tokenExpires, tokenExpires);
  }

  setTypeOfLogin(loginType) {
    return localStorage.setItem(this.typeOfLogin, loginType);
  }

  getTypeOfLogin() {
    return localStorage.getItem(this.typeOfLogin);
  }

  setBusinessLogo(businessLogo) {
    return localStorage.setItem(this.businessLogo, businessLogo);
  }

  setBusinessName(businessName) {
    return localStorage.setItem(this.businessName, businessName);
  }

  getTokenExpires() {
    return localStorage.getItem(this.tokenExpires);
  }

  setSurveySearchBy(searchObject) {
    return localStorage.setItem(this.surveySearchBy, searchObject);
  }

  getSurveySearchBy() {
    return localStorage.getItem(this.surveySearchBy);
  }

  setTicketSearchBy(searchObject) {
    return localStorage.setItem(this.ticketSearchBy, searchObject);
  }

  getTicketSearchBy() {
    return localStorage.getItem(this.ticketSearchBy);
  }

  setContactSearchBy(searchObject) {
    return localStorage.setItem(this.contactSearchBy, searchObject);
  }

  getContactSearchBy() {
    return localStorage.getItem(this.contactSearchBy);
  }

  setHomeActionCenterFilter(filter) {
    localStorage.setItem(this.homeActionCenterFilter, filter);
  }

  getHomeActionCenterFilter() {
    return localStorage.getItem(this.homeActionCenterFilter);
  }

  setWorkflowSearchBy(searchObject) {
    return localStorage.setItem(this.workflowSearchBy, searchObject);
  }

  getWorkflowSearchBy() {
    return localStorage.getItem(this.workflowSearchBy);
  }

  setJourneyRunSearchBy(searchObject) {
    return localStorage.setItem(this.journeyRunSearchBy, searchObject);
  }

  getJourneyRunSearchBy() {
    return localStorage.getItem(this.journeyRunSearchBy);
  }

  setJourneyDefSearchBy(searchObject) {
    return localStorage.setItem(this.journeyDefSearchBy, searchObject);
  }

  getJourneyDefSearchBy() {
    return localStorage.getItem(this.journeyDefSearchBy);
  }

  setNumberOfMessages(numMessages) {
    localStorage.setItem(this.numberOfMessages, numMessages);
  }

  getNumberOfMessages() {
    return localStorage.getItem(this.numberOfMessages);
  }

  setPermitConversations(pval) {
    localStorage.setItem(this.permitConversations, (pval ? 'Y' : 'N'));
  }

  getPermitConversations() {
    const pval = localStorage.getItem(this.permitConversations);
    return pval ? pval === 'Y' : false;
  }

  setIsMobileApp(mapp) {
    localStorage.setItem(this.isMobileApp, (mapp ? 'Y' : 'N'));
  }

  getIsMobileApp() {
    const mapp = localStorage.getItem(this.isMobileApp);
    return mapp ? mapp === 'Y' : false;
  }

  setLoginTime(timestamp) {
    localStorage.setItem(this.loginTime, timestamp);
  }

  resetLoginTime() {
    this.setLoginTime(moment.now());
  }

  getLoginTime() {
    const stimestamp = localStorage.getItem(this.loginTime);
    if ( stimestamp ) {
      return parseInt(stimestamp, 10);
    } else {
      return 0;
    }
  }

  setPdfLastFetched(timestamp) {
    localStorage.setItem(this.pdfLastFetched, timestamp);
  }

  resetPdfLastFetched() {
    this.setPdfLastFetched(moment.now());
  }

  getPdfLastFetched() {
    const stimestamp = localStorage.getItem(this.pdfLastFetched);
    if ( stimestamp ) {
      return parseInt(stimestamp, 10);
    } else {
      return 0;
    }
  }

}
