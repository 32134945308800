import {Injectable} from '@angular/core';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

// import localStorage from 'localStorage';

@Injectable()
export class ObjectService {
  private httpClient;
  private topicAPIUrl: String = 'api/product/' + this.authentication.getSelectedObject() + '/topic';
  private productAPIUrl = 'api/product/';
  private business = 'api/business/v1/';

  constructor(httpClient: HttpClientHelper, private authentication: AuthenticationHelper) {
    this.httpClient = httpClient;
  }

  createTopic(data) {
    return this.httpClient.post(this.topicAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateTopic(data) {
    return this.httpClient.put(this.topicAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteTopic(topicId) {
    const url = this.topicAPIUrl + '/' + topicId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllTopics() {
    const url = this.topicAPIUrl;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createObject(data) {
    return this.httpClient.post(this.productAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateObject(data) {
    return this.httpClient.put(this.productAPIUrl, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSingleObjectDetails(productId) {
    const url = this.productAPIUrl + productId;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllProductList() {
    const url = this.productAPIUrl;
    return this.httpClient.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteProduct(productId) {
    const url = this.productAPIUrl + productId;
    return this.httpClient.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  setAutoReplyMessage(data) {
    const url = this.productAPIUrl + 'ticket';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getServiceGroupName(data) {
    const url = this.business + 'businessunit/info';
    // const url = this.business + 'product/info';
    return this.httpClient.post(url, data)
      .map(this.extractResponse)
      .catch(this.handleError);
  }

  private extractResponse(res: JSON) {
    return res;
  }

  private handleError(error: any) {
    return throwError(error);
  }

}
