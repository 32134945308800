import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {HttpClient, HttpClientModule} from '@angular/common/http';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {Constants} from './constants';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {RoutesModule} from './routes/routes.module';
import {HttpClientHelper} from './app.httpclient';
import {AuthenticationHelper} from './app.authentication';
import {SharedModule} from './shared/shared.module';
import {ModalOperationsDirective} from './shared/modalOperations.directive';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ModalOperationsDirective
  ],
  imports: [
    BrowserModule,
    CoreModule,
    RoutesModule,
    LayoutModule,
    HttpClientModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [HttpClientHelper, AuthenticationHelper, Constants],
  bootstrap: [AppComponent],
  exports: [SharedModule, ModalOperationsDirective]
})
export class AppModule {
}
