import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';

export interface NoServiceGroupModel {
  title: string;
  message: string;
}

@Component({
  selector: 'confirm',
  template: `
    <div class="modal-dialog" id="confirm-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title || 'Warning'}}</h4>
        </div>
        <div class="modal-body text-align-center">
          <h4>
            Oops! It looks like you are not associated with any service group. Please contact your Business Administrator for further details.
          </h4>
          <button class="btn btn-primary" (click)="close()" type="button" [routerLink]="'logout'">Logout</button>
        </div>
        <div class="modal-footer">

        </div>
      </div>
  </div>`
})

export class NoServiceGroupComponent extends DialogComponent<NoServiceGroupModel, boolean> implements NoServiceGroupModel {
  title: string;
  message: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm() {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

}
