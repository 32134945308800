import {Injectable} from '@angular/core';
import {HttpClientHelper} from '../app.httpclient';
import 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

// import localStorage from 'localStorage';

@Injectable()
export class UploadService {
  private httpClient;
  private uploadImageUrl = 'api/uploadImage';
  private uploadProfileImageUrl = 'api/businessEmployees/profileImage';

  constructor(httpClient: HttpClientHelper) {
    this.httpClient = httpClient;
  }

  uploadImage(file) {
    return this.httpClient.postFile(this.uploadImageUrl, file)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImageToAccount(businessAcctId, file) {
    const url = 'api/uploadImageToAccount/' + businessAcctId;
    return this.httpClient.postFile(url, file)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadProfileImage(file) {
    return this.httpClient.postFile(this.uploadProfileImageUrl, file)
      .pipe(
        catchError(this.handleError)
      );
  }

  private extractResponse(res: JSON) {
    return res;
  }

  private handleError(error: any) {
    return throwError(error);
  }

}
