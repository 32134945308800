import {NgModule} from '@angular/core';
import {LayoutComponent} from './layout.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ThemeLoader} from '../app.component.loader';
import {SharedModule} from '../shared/shared.module';
import {ImageCropperModule} from 'ngx-img-cropper';
import {CreateServiceGroupModalDialogComponent} from '../routes/businessAdminSettings/serviceGroup/createServiceGroupModal.component';
import {NoServiceGroupComponent} from '../routes/noServiceGroupFound/noServiceGroupFound.component';
import {SetStrongPasswordModalDialogComponent} from '../shared/setStrongPasswordModalDialog.component';


@NgModule({
  imports: [
    SharedModule,
    ImageCropperModule
  ],
  providers: [
    ThemeLoader
  ],
  declarations: [
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    CreateServiceGroupModalDialogComponent,
    NoServiceGroupComponent,
    SetStrongPasswordModalDialogComponent
  ],
  exports: [
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    SetStrongPasswordModalDialogComponent
  ],
  entryComponents: [CreateServiceGroupModalDialogComponent, NoServiceGroupComponent, SetStrongPasswordModalDialogComponent]
})
export class LayoutModule {
}
