export class Constants {
  public serverAPIURl = location.hostname;
  public allowLocalServers = ['uri', 'infra', 'butler', 'kb1', 'demo', 'ohs1', 'jldemo', 'uat', 'staging', 'ohs', 'local', 'localhost', 'dev', 'qa', 'ussb', 'usdv', 'mobile', 'ui', 'ohsengg', 'sip1', 'pva', 'pva1', 'hjdemo','maps'];
  public allowIndianServers = ['ui', 'mobile', 'dev', 'qa', 'localhost'];
  public ASSIGNED_AGENT = 'ASSIGNED_AGENT';
  public riskTypes = [{name: 'HIGH NEGATIVE', value: 0, color: '#27c24c'}, {
    name: 'MEDIUM NEGATIVE',
    value: 1,
    color: '#FFAE00'
  }, {name: 'LOW NEGATIVE', value: 2, color: '#C94B3E'},
    {name: 'LOW NEUTRAL', value: 3, color: '#95FD3D'}, {name: 'MEDIUM NEUTRAL', value: 4, color: '#FF6000'}, {
      name: 'HIGH NEUTRAL',
      value: 5,
      color: '#E00406'
    },
    {name: 'LOW POSITIVE', value: 6, color: '#085318'}, {name: 'MEDIUM POSITIVE', value: 7, color: '#EC4A00'}, {
      name: 'HIGH POSITIVE',
      value: 8,
      color: '#A50000'
    }];
  public surveyVersion = 'VERSION_2';
  public Days = 'Days';
  public Minutes = 'Minutes';
  public Hours = 'Hours';
  public Seconds = 'Seconds';
  public daysList: object[] = [
    {id: 1, name: 'Sunday'},
    {id: 2, name: 'Monday'},
    {id: 3, name: 'Tuesday'},
    {id: 4, name: 'Wednesday'},
    {id: 5, name: 'Thursday'},
    {id: 6, name: 'Friday'},
    {id: 7, name: 'Saturday'}
  ];
  public actionFlowStatus = {
    ACTIVATED: 'ACTIVATED'
  };
  public chartTypes = {
    LINE: 'LINE',
    PIECHART: 'PIE',
    BARCHART: 'BAR',
    MAP: 'MAP'
  };
  public CURR_TIME_VAR = 'CURR_TIME_VAR';
  public CURR_DATETIME_VAR = 'CURR_DATETIME_VAR';
  public CURR_DATE_VAR = 'CURR_DATE_VAR';
  public CURR_DAYOFWEEK_VAR = 'CURR_DAYOFWEEK_VAR';
  public INTERVENTION = 'INTERVENTION';
  public LAYOUT_1 = 'LAYOUT_1';
  public LAYOUT_2 = 'LAYOUT_2';
  public LAYOUT_3 = 'LAYOUT_3';
  public STANDARD = 'STANDARD';

  public PARTICIPANT_TIMEOUT = 90; // minutes
  public TEAM_MEMBER_TIMEOUT = 30;
  public SAFETYPLAN_TIMEOUT  = 60;

  public personType = {
    ADMIN : 'Administrator',
    AGENT : 'Team Member',
    PARTICIPANT : 'Participant'
  };

  public journeyRunStatus = {
    PENDING : 'PENDING',
    INPROCESS : 'INPROCESS',
    COMPLETED : 'COMPLETED',
    HALTED : 'HALTED',
    ERROR: 'ERROR'
  };

  public journeyStatus = {
    PENDING: 'PENDING',
    INPROCESS: 'INPROCESS',
    COMPLETED: 'COMPLETED',
    COMPLETEDWITHERRORS: 'COMPLETEDWITHERRORS',
    SUSPENDED: 'SUSPENDED',
    DELETED: 'DELETED',
    ERROR: 'ERROR'
  }

  public workflowCustomParams = {
    RECURRENCE: 'RECURRENCE',
    SEND_MESSAGE: 'SEND_SMS_API',
    END_EVENT: 'END_EVENT',
    TERMINATE_EVENT: 'TERMINATE_EVENT',
    START_EVENT: 'START_EVENT',
    ON_CHAT_SENTIMENT_THRESHOLD_EVENT: 'ON_CHAT_SENTIMENT_THRESHOLD_REACHED_EVENT',
    SEND_FIXED_MESSAGE_ACTION: 'SEND_FIXED_MESSAGE_ACTION',
    SEND_FIXED_MESSAGE_TO_AGENT_ACTION: 'SEND_FIXED_MESSAGE_TO_AGENT_ACTION',
    SCHEDULE_ACTIVITY_ACTION: 'SCHEDULE_ACTIVITY_ACTION',
    SEND_MESSAGE_FROM_MESSAGE_BANK_ACTION: 'SEND_MESSAGE_BANK_MESSAGE_ACTION',
    PUSH_SURVEY_NOTIFICATION_ACTION: 'PUSH_SURVEY_NOTIFICATION_ACTION',
    ON_SURVEY_RESPONDED_EVENT: 'ON_SURVEY_RESPONDED_EVENT',
    WAIT_FOR_THE_SURVEY_RESPONSE: 'WAIT_FOR_SURVEY_RESPONSE_CONDITION',
    SEND_SURVEY_ACTION: 'SEND_SURVEY_ACTION',
    LAUNCH_SAFETY_PLAN_ACTION: 'LAUNCH_SAFETY_PLAN_ACTION',
    ARCHIVE_DOCUMENT_ACTION: 'ARCHIVE_DOCUMENT_ACTION',
    DIAL_EMERGENCY_CONTACT_ACTION: 'DIAL_EMERGENCY_CONTACT_ACTION',
    ADD_EMERGENCY_CONTACT_ACTION: 'ADD_EMERGENCY_CONTACT_ACTION',
    ARCHIVE_EMERGENCY_CONTACT_ACTION: 'ARCHIVE_EMERGENCY_CONTACT_ACTION',
    RUN_ACTION_FLOW_ACTION: 'RUN_ACTION_FLOW_ACTION',
    WAIT_FOR_ACTION_FLOW_COMPLETION_CONDITION: 'WAIT_FOR_ACTION_FLOW_COMPLETION_CONDITION',
    NEW_UPDATE_RISK_INDICATOR_ACTION: 'NEW_UPDATE_RISK_INDICATOR_ACTION',
    ON_RISK_THRESHOLD_EVENT: 'ON_RISK_THRESHOLD_EVENT',
    ON_LOAD_EVENT: 'ON_ACTION_FLOW_LOADED_EVENT',
    ADD_TICKER_ACTION: 'ADD_TICKER_ACTION',
    ARCHIVE_TICKER_ACTION: 'ARCHIVE_TICKER_ACTION',
    VARIABLE_OPERATION_ACTION: 'VARIABLE_OPERATION_ACTION',
    WAIT_TIME: 'WAIT_TIME_ACTION',
    UPDATE_RISK_INDICATOR_ACTION: 'UPDATE_RISK_INDICATOR_ACTION',
    UPDATE_CUSTOM_PROFILE_ACTION: 'UPDATE_CUSTOM_PROFILE_ACTION',
    ARCHIVE_SURVEY_CARD_ACTION: 'ARCHIVE_SURVEY_CARD_ACTION',
    EVENT: 'EVENT',
    CONDITION: 'CONDITION',
    SURVEY: 'SURVEY',
    SURVEY_QUES: 'SURVEY_QUEST',
    VARIABLE: 'VARIABLE',
    ON_BOARDING_EVENT: 'ON_BOARDING_EVENT',
    ON_EMERGENCY_DIAL_EVENT: 'ON_EMERGENCY_DIAL_EVENT',
    ACTION: 'ACTION',
    ACTION_FLOW: 'ACTION_FLOW',
    CUSTOM_PROFILE: 'PROFILE_ATTRIBUTE',
    JOIN: 'JOIN',
    INCLUSIVE_JOIN_OPERATION: 'INCLUSIVE_JOIN_OPERATION',
    ON_SATISFACTION_THRESHOLD_EVENT: 'ON_SATISFACTION_THRESHOLD_EVENT',
    ON_ENGAGEMENT_THRESHOLD_EVENT: 'ON_ENGAGEMENT_THRESHOLD_EVENT',
    UPDATE_ENGAGEMENT_INDICATOR_ACTION: 'UPDATE_ENGAGEMENT_INDICATOR_ACTION',
    UPDATE_SATISFACTION_INDICATOR_ACTION: 'UPDATE_SATISFACTION_INDICATOR_ACTION',
    ON_CONTACT_CREATED_EVENT: 'ON_CONTACT_CREATED_EVENT',
    PUBLISH_WORKFLOW_ACTION: 'PUBLISH_WORKFLOW_ACTION',
    onLoadWorkflowEventId: 23,
    ABSOLUTE: 'ABSOLUTE',
    RELATIVE: 'RELATIVE',
    COMMENT: 'COMMENT',
    CUSTOM_PLUGIN_CONDITION: 'CUSTOM_PLUGIN_CONDITION',
    SEND_SURVEY_TO_AGENT_ACTION: 'SEND_SURVEY_TO_AGENT_ACTION',
    ON_CUSTOM_EVENT: 'ON_CUSTOM_EVENT',    // ON_SOURCE_INPUT_EVENT
    DOT_PRODUCT_ACTION: 'DOT_PRODUCT_ACTION',
    TIME_RANGE_ALL: 'TIME_RANGE_ALL',
    TIME_RANGE_SPECIFIC: 'TIME_RANGE_SPECIFIC',
    TIME_RANGE_BEFORE_PRESENT: 'TIME_RANGE_BEFORE_PRESENT',
    TIME_RANGE_SINCE_DATE: 'TIME_RANGE_SINCE_DATE',
    TIME_RANGE_YTD: 'TIME_RANGE_YTD',
    TIME_RANGE_MTD: 'TIME_RANGE_MTD'
  };

  public participantDashboard = {
    PARTICIPANT_DETAILS: 'PARTICIPANT_DETAILS',
    PARTICIPANT_ATTRIBUTE_DETAILS: 'PARTICIPANT_ATTRIBUTE_DETAILS',
    ACTIVITY_TIMELINE: 'ACTIVITY_TIMELINE',
    HELPNOW: 'HELP_NOW',
    PARTICIPANT_FORM: 'PARTICIPANT_FORM',
    PARTICIPANT_ATTRIBUTE_TABLE: 'PARTICIPANT_ATTRIBUTE_TABLE',
    PARTICIPANT_PDF_FORM_TABLE: 'PARTICIPANT_PDF_FORM_TABLE',
    PARTICIPANT_PDF_FORM: 'PARTICIPANT_PDF_FORM',
    PARTICIPANT_SURVEY_LIST: 'PARTICIPANT_SURVEY_LIST',
    PARTICIPANT_SURVEY_STATS: 'PARTICIPANT_SURVEY_STATS',
    ASSIGN_AGENT_TABLE: 'ASSIGN_AGENT_TABLE',
    QUESTIONNAIRE_AGENT_TABLE: 'QUESTIONNAIRE_AGENT_TABLE',
    SERIES_ATTRIBUTE_VALUES: 'SERIES_ATTRIBUTE_VALUES',
    SERIES_ATTRIBUTES_MATRIX: 'SERIES_ATTRIBUTES_MATRIX',
    PARTICIPANT_LOGIN_LIST: 'PARTICIPANT_LOGIN_LIST',
    SERIES_ATTRIBUTE_PIECHART: 'SERIES_ATTRIBUTE_PIECHART'
  };

  public teamMemberDashboard = {
    teamMemberDashboardCategory: 'TEAM_MEMBER_DASHBOARD',
    participantsSubcategory: 'PARTICIPANTS',
    attributesSubcategory: 'ATTRIBUTES',
    journeysSubcategory: 'JOURNEYS',
    teamMemberLogins: 'TEAM_MEMBER_LOGINS',
    serviceGroupLogins: 'SERVICE_GROUP_LOGINS',
    participantAttributeMatrix: 'PARTICIPANT_ATTRIBUTE_MATRIX'
  };

  public ACTIVE = 'ACTIVE';
  public PDF_TEMPLATE = 'PDF_TEMPLATE';
  public PARTICIPANT_ATTRIBUTE = 'PARTICIPANT_ATTRIBUTE';
  public INACTIVE = 'INACTIVE';
  public MEDIUM = 'MEDIUM';
  public LARGE = 'LARGE';
  public SMALL = 'SMALL';
  public TIME_VALUES = 'TIME_VALUES';
  public VALUES = 'VALUES';
  public SINGLE = 'SINGLE';
  public TEXT_WIDGET = 'TEXT_WIDGET';

  public TIMELINE = 'TIMELINE';
  public CONTACT_ATTRIBUTE = 'CONTACT_ATTRIBUTE';
  public WELCOME = 'WELCOME';
  public BASIC = 'BASIC';
  public SCALAR = 'SCALAR';
  public conditionOperatorList = {
    SCQ: [
      {value: 'TEXT_IS_EQUAL', label: 'is'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'is not'}
    ],
    MCQ: [
      {value: 'TEXT_IS_EQUAL', label: 'contains'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'does not contains'},
    ],
    NUMBER: [
      {value: 'NUMBER_IS_EQUAL', label: 'is equal'},
      {value: 'NUMBER_IS_NOT_EQUAL', label: 'is not equal'},
      {value: 'NUMBER_IS_LOWER', label: 'is lower than'},
      {value: 'NUMBER_IS_LOWER_OR_EQUAL', label: 'is lower than or equal to'},
      {value: 'NUMBER_IS_GREATER', label: 'is greater than'},
      {value: 'NUMBER_IS_GREATER_OR_EQUAL', label: 'is greater than or equal to'},
    ],
    RATING: [
      {value: 'NUMBER_IS_EQUAL', label: 'is equal'},
      {value: 'NUMBER_IS_NOT_EQUAL', label: 'is not equal'},
      {value: 'NUMBER_IS_LOWER', label: 'is lower than'},
      {value: 'NUMBER_IS_LOWER_OR_EQUAL', label: 'is lower than or equal to'},
      {value: 'NUMBER_IS_GREATER', label: 'is greater than'},
      {value: 'NUMBER_IS_GREATER_OR_EQUAL', label: 'is greater than or equal to'},
    ],
    VAS: [
      {value: 'NUMBER_IS_EQUAL', label: 'is equal'},
      {value: 'NUMBER_IS_NOT_EQUAL', label: 'is not equal'},
      {value: 'NUMBER_IS_LOWER', label: 'is lower than'},
      {value: 'NUMBER_IS_LOWER_OR_EQUAL', label: 'is lower than or equal to'},
      {value: 'NUMBER_IS_GREATER', label: 'is greater than'},
      {value: 'NUMBER_IS_GREATER_OR_EQUAL', label: 'is greater than or equal to'},
    ],
    NPS: [
      {value: 'NUMBER_IS_EQUAL', label: 'is equal'},
      {value: 'NUMBER_IS_NOT_EQUAL', label: 'is not equal'},
      {value: 'NUMBER_IS_LOWER', label: 'is lower than'},
      {value: 'NUMBER_IS_LOWER_OR_EQUAL', label: 'is lower than or equal to'},
      {value: 'NUMBER_IS_GREATER', label: 'is greater than'},
      {value: 'NUMBER_IS_GREATER_OR_EQUAL', label: 'is greater than or equal to'},
    ],
    BOOLEAN: [
      {value: 'TEXT_IS_EQUAL', label: 'is'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'is not'},
    ],
    FREETEXT: [
      {value: 'TEXT_IS_EQUAL', label: 'is'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'is not'},
      {value: 'TEXT_IS_BEGIN', label: 'begins with'},
      {value: 'TEXT_IS_NOT_BEGIN', label: 'does not begin with'},
      {value: 'TEXT_IS_END', label: 'ends with'},
      {value: 'TEXT_IS_NOT_END', label: 'does not end with'},
      {value: 'TEXT_IS_CONTAIN', label: 'contains'},
      {value: 'TEXT_IS_NOT_CONTAIN', label: 'does not contain'}
    ],
    TEXT: [
      {value: 'TEXT_IS_EQUAL', label: 'is'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'is not'},
      {value: 'TEXT_IS_BEGIN', label: 'begins with'},
      {value: 'TEXT_IS_NOT_BEGIN', label: 'does not begin with'},
      {value: 'TEXT_IS_END', label: 'ends with'},
      {value: 'TEXT_IS_NOT_END', label: 'does not end with'},
      {value: 'TEXT_IS_CONTAIN', label: 'contains'},
      {value: 'TEXT_IS_NOT_CONTAIN', label: 'does not contain'}
    ],
    DATETIME: [
      {value: 'DATE_IS_EQUAL', label: 'is'},
      {value: 'DATE_IS_NOT_EQUAL', label: 'is not'},
      {value: 'DATE_IS_BEFORE', label: 'is before'},
      {value: 'DATE_IS_BEFORE_OR_EQUAL', label: 'is same or before'},
      {value: 'DATE_IS_AFTER', label: 'is after'},
      {value: 'DATE_IS_AFTER_OR_EQUAL', label: 'is same or after'},
    ],
    DATE: [
      {value: 'DATE_IS_EQUAL', label: 'is'},
      {value: 'DATE_IS_NOT_EQUAL', label: 'is not'},
      {value: 'DATE_IS_BEFORE', label: 'is before'},
      {value: 'DATE_IS_BEFORE_OR_EQUAL', label: 'is same or before'},
      {value: 'DATE_IS_AFTER', label: 'is after'},
      {value: 'DATE_IS_AFTER_OR_EQUAL', label: 'is same or after'},
    ],
    TIME: [
      {value: 'DATE_IS_EQUAL', label: 'is'},
      {value: 'DATE_IS_NOT_EQUAL', label: 'is not'},
      {value: 'DATE_IS_BEFORE', label: 'is before'},
      {value: 'DATE_IS_BEFORE_OR_EQUAL', label: 'is same or before'},
      {value: 'DATE_IS_AFTER', label: 'is after'},
      {value: 'DATE_IS_AFTER_OR_EQUAL', label: 'is same or after'},
    ],
    MATRIX: [
      {value: 'TEXT_IS_EQUAL', label: 'is'},
      {value: 'TEXT_IS_NOT_EQUAL', label: 'is not'}
    ],
    MULTI_TEXT: [
      {value: 'LIST_CONTAIN', label: 'contains'},
      {value: 'LIST_NOT_CONTAIN', label: 'does not contain'},
    ]
  };
  public DOCUMENT = 'DOCUMENT';
  public TICKET = 'TICKET';
  public SURVEY = 'SURVEY';
  public questionType = {
    MCQ: 'MCQ',
    SCQ: 'SCQ',
    MATRIX: 'MATRIX',
    THANK_YOU: 'THANK_YOU',
    DESCRIPTIVE_TEXT: 'DESCRIPTIVE_TEXT',
    FREETEXT: 'FREETEXT',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT',
    TEXT: 'TEXT',
    DATETIME: 'DATETIME',
    DATE: 'DATE',
    TIME: 'TIME',
    NUMBER: 'NUMBER',
    VAS: 'VAS',
    RATING: 'RATING',
    NPS: 'NPS',
    BOOLEAN: 'BOOLEAN'
  };

  public variableTypes = {
    NUMBER: 'NUMBER',
    BOOLEAN: 'BOOLEAN',
    TEXT: 'TEXT',
    DATETIME: 'DATETIME',
    DATE: 'DATE',
    TIME: 'TIME',
    IMAGE: 'IMAGE',
    EMAIL: 'EMAIL'
  };

  public dateFunctions = [
    {
      name: 'Future Date and Time',
      value: 'FutureDate',
    },
    {
      name: 'Select Nearest Future Time',
      value: 'SelectNearestTime',
    }
  ];

  public timeFunctions = [
    {
      name: 'Ensure Time Is After By',
      value: 'TimeAfterBy',
    },
    {
      name: 'Ensure Time Is Before By',
      value: 'TimeBeforeBy',
    }
  ];

  public statisticsFunctions = [
    {
      name: 'Average',
      value: 'Average'
    },
    {
      name: 'Sum',
      value: 'Sum'
    },
    {
      name: 'Minimum',
      value: 'Min'
    },
    {
      name: 'Maximum',
      value: 'Max'
    },
    {
      name: 'Variance',
      value: 'Variance'
    },
    {
      name: 'Standard Deviation',
      value: 'StandardDeviation'
    },
    {
      name: 'Number of Values',
      value: 'Count'
    },
    {
      name: 'Std Devs from Average',
      value: 'StdDevsFromAverage'
    }
  ];

  public variableOperationOperators = {
    BOOLEAN: [
      {
        name: 'AND',
        value: 'AND'
      },
      {
        name: 'OR',
        value: 'OR'
      }
    ],
    NUMBER: [
      {
        name: 'Addition',
        value: 'ADDITION'
      },
      {
        name: 'Subtraction',
        value: 'SUBTRACTION'
      },
      {
        name: 'Multiplication',
        value: 'MULTIPLICATION'
      },
      {
        name: 'Division',
        value: 'DIVISION'
      },
      {
        name: 'Random Number',
        value: 'RANDOM'
      },
      {
        name: 'Date-Time Difference',
        value: 'DATETIMEDIFF'
      },
      {
        name: 'Statistics Function',
        value: 'STATISTICS'
      }
    ],
    DATETIME: [
      {
        name: 'Addition',
        value: 'ADDITION'
      },
      {
        name: 'Subtraction',
        value: 'SUBTRACTION'
      },
      {
        name: 'Date Function',
        value: 'DATE_FUNCTION'
      }
    ],
    DATE: [
      {
        name: 'Addition',
        value: 'ADDITION'
      },
      {
        name: 'Subtraction',
        value: 'SUBTRACTION'
      }
    ],
    TIME: [
      {
        name: 'Addition',
        value: 'ADDITION'
      },
      {
        name: 'Subtraction',
        value: 'SUBTRACTION'
      },
      {
        name: 'Time Function',
        value: 'TIME_FUNCTION'
      }
    ],
    TEXT: [
      {
        name: 'Addition',
        value: 'ADDITION'
      }
    ],
    EMAIL: [
      {
      }
    ]
  };

  public customStyleTypes = [
    'PageBackground', 'LeftMenuColor', 'HeaderColor', 'WidgetHeaderColor', 'WidgetHeaderBackground',
    'LeftMenuHighlight', 'HeaderFontColor', 'PageHeaderBackground', 'PageButtonColor', 'PageButtonBackground'
  ];

  public allNotifyList = [{id: 'APP', name: 'Participant APP'}, {id: 'EMAIL', name: 'EMAIL'}, {id: 'SMS', name: 'SMS'}];
  public questionTypeList = [
    {name: 'Single Select', type: 'SCQ', icon: '../assets/img/question_type_modal_icons/single_select_QT.svg', category: 'Selection'},
    {name: 'Multi Select', type: 'MCQ', icon: '../assets/img/question_type_modal_icons/multi_select_QT.svg', category: 'Selection'},
    {name: 'Yes/No', type: 'BOOLEAN', icon: '../assets/img/question_type_modal_icons/yes_no_QT.svg', category: 'Selection'},
    {name: 'Matrix', type: 'MATRIX', icon: '../assets/img/question_type_modal_icons/matrix_QT.svg', category: 'Selection'},
    {name: 'DateTime', type: 'DATETIME', icon: '../assets/img/question_type_modal_icons/date_QT.svg', category: 'Free Response'},
    {name: 'DateTime', type: 'DATE', icon: '../assets/img/question_type_modal_icons/date_QT.svg', category: 'Free Response'},
    {name: 'DateTime', type: 'TIME', icon: '../assets/img/question_type_modal_icons/date_QT.svg', category: 'Free Response'},
    {name: 'Text', type: 'FREETEXT', icon: '../assets/img/question_type_modal_icons/text_input_QT.svg', category: 'Free Response'},
    {name: 'Number', type: 'NUMBER', icon: '../assets/img/question_type_modal_icons/number_input_QT.svg', category: 'Free Response'},
    {name: 'Image', type: 'IMAGE', icon: '../assets/img/question_type_modal_icons/image_upload_QT.svg', category: 'Participant Upload'},
    {name: 'Video', type: 'VIDEO', icon: '../assets/img/question_type_modal_icons/video_upload_QT.svg', category: 'Participant Upload'},
    {name: 'Audio', type: 'AUDIO', icon: '../assets/img/question_type_modal_icons/audio_upload_QT.svg', category: 'Participant Upload'},
    {
      name: 'Document',
      type: 'DOCUMENT',
      icon: '../assets/img/question_type_modal_icons/document_upload_QT.svg',
      category: 'Participant Upload'
    },
    {name: 'Slider', type: 'NPS', icon: '../assets/img/question_type_modal_icons/nps_QT.svg', category: 'Scoring'},
    {name: 'Rating - Stars', type: 'RATING', icon: '../assets/img/question_type_modal_icons/rating_QT.svg', category: 'Scoring'},
    {
      name: 'Visual Analog Scale',
      type: 'VAS',
      icon: '../assets/img/question_type_modal_icons/visual_analog_scale_QT.svg',
      category: 'Scoring'
    },
    {name: 'Thank You', type: 'THANK_YOU', icon: '../assets/img/question_type_modal_icons/thank_you_QT.svg', category: 'Non Interactive'},
    {
      name: 'Transition Text',
      type: 'DESCRIPTIVE_TEXT',
      icon: '../assets/img/question_type_modal_icons/transition_text_QT.svg',
      category: 'Non Interactive'
    },

  ];
  public SMS = 'SMS';
  public QUESTION = 'QUESTION';
  public clonedOnString = ' - Cloned on ';
  public BUSINESS_ACCOUNT = 'BUSINESS_ACCOUNT';
  public EMAIL = 'EMAIL';
  public ONBOARDING_EMAIL = 'ONBOARDING_EMAIL';
  public ONBOARDING_SMS = 'ONBOARDING_SMS';
  public SURVEY_LAST_COMPLETED = 'LASTCOMPLETED';
  public APP = 'APP';
  public PDF = 'PDF';
  public RUNNING = 'RUNNING';
  public DRAFT = 'DRAFT';
  public PUBLISHED = 'PUBLISHED';
  public DEPLOYED = 'DEPLOYED';
  public STOP = 'STOP';
  public INVALID = 'INVALID';
  public PUBLISH = 'PUBLISH';
  public CURRENT_ACTIVE_CONTACT_STATE = 'CURRENT_ACTIVE_CONTACT_STATE';
  public HISTORICAL = 'HISTORICAL';
  public PAUSED = 'PAUSED';
  public CUSTOM = 'CUSTOM';
  public DYNAMIC = 'DYNAMIC';
  public FIXED = 'FIXED';
  public LAST = 'LAST';
  public SYSTEM = 'SYSTEM';
  public LATEST = 'LATEST';
  public ASSIGN_VALUE = 'ASSIGN_VALUE';
  public operandResult = 'operandResult';
  public ACTOHEALTHAPP = 'ACTOHEALTHAPP';
  public operator = 'operator';
  public SET = 'SET';
  public FUNCTION = 'FUNCTION';
  public RANDOM = 'RANDOM';
  public STATISTICS = 'STATISTICS';
  public DATE_FUNCTION = 'DATE_FUNCTION';
  public TIME_FUNCTION = 'TIME_FUNCTION';
  public DATETIMEDIFF = 'DATETIMEDIFF';
  public AGENT = 'AGENT';
  public TODO = 'TODO';
  public frequency = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY'
  };
  public MESSAGE = 'MESSAGE';
  public RISK = 'RISK';
  public MANUAL = 'MANUAL';
  public ENGAGEMENT = 'ENGAGEMENT';
  public SATISFACTION = 'SATISFACTION';
  public systemAdmin = 'ROLE_SYSTEM_ADMINISTRATOR';
  public businessAdmin = 'ROLE_BUSINESS_SYSTEM_ADMINISTRATOR';
  public serviceGroupAdmin = 'ROLE_BUSINESS_SERVICE_GROUP_ADMINISTRATOR';
  public ticketAgent = 'ROLE_BUSINESS_TICKET_AGENT';
  public surveyAgent = 'ROLE_BUSINESS_SURVEY_AGENT';
  public workflowAgent = 'ROLE_BUSINESS_WORKFLOW_AGENT';
  public serviceGroupAgent = 'ROLE_BUSINESS_SERVICE_GROUP_AGENT';
  public journeyDesigner = 'ROLE_BUSINESS_WORKFLOW_ADMINISTRATOR';
  public ticketAssignSuccess = 'Ticket Assigned Successfully!';
  public deleteConfirmationMessage = '<h4>Are you sure you want to delete this?</h4>';
  public businessDeleteConfirmation = '<h4>You will lose your all data which is associated with this organization. <br>Are you sure you want to delete?</h4>';
  public participantAttributeInUseMessage = '<p style="color: red; font-size: 13px; padding-top: 10px;"><u>Note</u>: Please make sure that this Journey Attribute is not being used in active/draft questionnaire or actionflow before performing this action.</p>';
  public serviceGroupDeleteConfirmation = '<h4>You will lose your all data which is associated with this organizational unit. <br>Are you sure you want to delete this?</h4>';
  public createdSuccess = 'Created Successfully!';
  public addedSuccess = 'Added Successfully!';
  public messageSentSuccess = 'Message Sent Successfully!';
  public updatedSuccess = 'Updated Successfully!';
  public deletedSuccess = 'Deleted Successfully!';
  public publishSuccess = 'Published Successfully';
  public serverError = 'Something went wrong!';
  public uploadImageError = 'Please Upload Your Image!';
  public uploadLayoutImageError = 'Please upload an image for the selected questionnaire layout!';
  public loggedInSuccess = 'Logged In Successfully!';
  public selectObjectError = 'Please select any target service group first!';
  public logoutSuccess = 'Logout Successfully!';
  public logicJumpBlankAnswerError = 'Please set any answer first for branch logic condition!';
  public selectSurveyTypeError = 'Please select any Questionnaire type!';
  public registerCompleteSuccess = 'Registered Successfully!';
  public passwordResetSuccess = 'Your password has been reset successfully!.<br>You can login with your new password';
  public forgotPasswordConfirmation = 'A verification email has been sent to your registered email address with further instructions to reset your password.';
  public resentActivationEmailSuccess = 'Email Sent Successfully!';
  public exportResultSuccess = 'Exported SuccessFully!';
  public actionFlowExportResultSuccess = 'Please wait, your download will start in a moment...';
  public addChoiceError = 'Please add any choice first!';
  public fileImportedSuccess = 'File imported succcessfully!';
  public ratingNumberValidation = 'Please enter correct rating!';
  public logicJumpQuestionTypeError = 'Logic jump can not apply on this question type';
  public copyQuestionNode = 'Are you sure you want to clone this question?';
  public deleteQuestionWithMainNode = 'Are you sure you want to delete this question(you will lose all your sub group questions and relative logic paths click "ok" to confirm)?';
  public transitionTextAnalyzeError = 'Analyze result is not available for transition text';
  public contactGroupSelectionError = 'Please select any participant group';
  public stopWorkflowCofirmation = 'Are you sure you want to stop this action flow?';
  public stopJourneyRunConfirmation = 'Are you sure you want to stop this journey run?';
  public deleteJourneyRunConfirmation = 'Are you sure you want to delete this journey run?';
  public startJourneyRunConfirmation = 'Click OK to start the journey for all participants.';
  public createSurveyError = 'Please add any question first!';
  public invalidFileData = 'Invalid data in file!';
  public invalidFileFormat = 'Invalid file format!';
  public workflowDataImportSuccess = 'Action Flow Imported Successfully!';
  public copySuccess = 'Copied Successfully!';
  public uploadFileError = 'Please Upload any file!';
  public activeObjectDeleteError = 'Current active service group can not be deleted!';
  public selectionErrorHelpNow = 'Please configure at least one of the following Emergency Contact/Document/Questionnaire/Support URL!';
  public repeatError = 'Please provide all values for repeat!';
  public selectEmployeeError = 'Please select any employee to associate!';
  public dateSelectionError = 'Please select Start Date & Time and End Date & Time';
  public sessionExpired = 'Session Expired. Please Login again!';
  public saveSuccess = 'Saved Successfully!';
  public deleteAdminError = 'Only Business Administrator can perform this action!';
  public startEndDateTimeValidation = 'End Date & Time should be greater than Start Date & Time';
  public cloneSucces = 'Cloned Successfully!';
  public questionBlankText = 'Please enter any text for question!';
  public downloadFileSuccess = 'File Downloaded Successfully!';
  public invalidTimeError = 'Please enter a valid time';

  // Action flow validation messages
  public blankActionFlow = 'Action Flow can not be blank!';
  public provideAllValues = 'Please provide all values!';
  public highThresholdValueError = 'High threshold value should be greater than low threshold value';
  public minuteError = 'Minute value can not be negative!';
  public hourError = 'Hour value can not be negative!';
  public dayError = 'Day value can not be negative!';
  public removeSuccess = 'Removed Successfully!';
  public maxResponseError = 'Please provide correct value for Max Response between 2 to 25';
  public matrixError = 'Matrix require atleast one Row and one Column';
  public thresholdUpdateError = ' Max value should be greater than Min';
  public surveyChangeWarningMessage = 'Caution: Changing a Questionnaire selection may impact your ActionFlow Conditions.';
  public participationTerminationSuccess = 'Participant has completed the Action Flow';
  public multipleInConnectionError = 'Multiple in connections are not allowed. Please use Inclusive Join Operation component.';

  // PDF template message
  public blankPDFTemplate = 'PDF Form cannot be blank';

  // infinite scroll list type
  public SURVEY_LIST = 'SURVEY_LIST';
  public SERVICE_GROUP_LIST = 'SERVICE_GROUP_LIST';
  public CUSTOM_ATTRIBUTE_LIST = 'CUSTOM_ATTRIBUTE_LIST';
  public PDF_FORMS_LIST = 'PDF_FORMS_LIST';
  public VARIABLES_LIST = 'VARIABLES_LIST';
  public CUSTOM_VAR = 'CUSTOM_VAR';
  public SURVEY_QUESTION_LIST = 'SURVEY_QUESTION_LIST';
}
