import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import 'rxjs';
import {AuthenticationHelper} from '../app.authentication';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

@Injectable()
export class PdfFormService {
  private httpClient;
  private pdfFormsPrefix = 'api/pdf/pdfTemplate';
  private product: string = 'product/' + this.authentication.getSelectedObject();

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper) {
    this.httpClient = httpClient;
  }

  getAllPDFForms(params): Observable<any> {
    const url = this.pdfFormsPrefix + '/' + this.product;
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  createPDFTemplate(data): Observable<any> {
    const url = this.pdfFormsPrefix + '/' + this.product;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePDFTemplate(data): Observable<any> {
    const url = this.pdfFormsPrefix + '/update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePDFTemplate(templateId): Observable<any> {
    const url = this.pdfFormsPrefix + '/template/' + templateId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getHTMLOfDocument(templateId, contactId): Observable<any> {
    const url = this.pdfFormsPrefix + '/parse/template/' + templateId + '/contact/' + contactId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  generatePDF(templateId, contactId): Observable<any> {
    const url = this.pdfFormsPrefix + '/generatePdf/template/' + templateId + '/contact/' + contactId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  extractResponse(res: JSON) {
    return res;
  }

  handleError(error: any) {
    return throwError(error);
  }

}
