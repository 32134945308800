import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ThemeLoader} from '../../app.component.loader';
import {UserService} from '../../services/app.user.service';
import {DialogService} from 'ng6-bootstrap-modal';
import {CreateUserModalDialogComponent} from './createUserModal.component';
import {ConfirmComponent} from '../confirmModalDialog/confirmModalDialog.component';
import {Constants} from '../../constants';
import {BusinessAccountService} from '../../services/app.businessAccount.service';
import {AuthenticationHelper} from '../../app.authentication';
import {CommonComponent} from '../../shared/commonComponent';
import {Router} from '@angular/router';
import {UserSecurityModalDialogComponent} from './userSecurityModal.component';
import {ChangeUserPwModalDialogComponent} from './changeUserPwModal.component';
import {UserStatusModalDialogComponent} from './userStatusModal.component';
import {RefdataService} from '../../services/app.refdata.service';
import {DomSanitizer} from '@angular/platform-browser';

declare let toastr: any;

@Component({
  selector: 'agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
  providers: [BusinessAccountService, UserService, RefdataService, CommonComponent]
})

export class AgentsComponent implements OnInit {

  @Input() isUserSettings = false;
  userList = [];
  filteredUserList = [];
  searchEmailControl: FormControl = new FormControl('');
  currentUserRole = this.authentication.getUserRole();
  allowEdit = false;
  allowChangePw = false;
  participantLabel = 'Participant';
  sortBy = 'email';
  orderBy = 'ASC';
  pageNumber = 1;
  pageSize = 10;
  maxPageLinks = 5;
  totalUsers = 0;
  isFilterApplied = false;
  searchBy = {
    userName: '',
    email: '',
    fullName: ''
  };
  buttonStyle = null;

  constructor(private spinner: ThemeLoader, private dialogService: DialogService, private userService: UserService, private authentication: AuthenticationHelper,
              public constants: Constants,  private commonComponent: CommonComponent,  private router: Router,
              private sanitizer: DomSanitizer,
              private businessAccountService: BusinessAccountService, private refdataService: RefdataService) {
    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: '1000'
    };
    this.allowEdit = this.authentication.getPrivilege('EditGeneralSettings');
        const userRole = this.authentication.getUserRole();
    this.allowChangePw = userRole === this.constants.businessAdmin || userRole === this.constants.systemAdmin;
    // this.searchEmailControl.valueChanges.pipe(debounceTime(1000)).pipe(distinctUntilChanged()).subscribe(
    //   value => this.filterAgentList(value)
    // );
    this.searchEmailControl.valueChanges.pipe(debounceTime(1000)).pipe(distinctUntilChanged()).subscribe(
      value => {
        this.searchByColumn(value, 'email');
      }
    );
  }

  getAllListOfBusinessEmployees() {
    this.spinner.show();
    if ( this.isUserSettings ) {
      const filterData = {
        orderBy: this.orderBy,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        email: this.searchBy['email'],
        userName: null, // for naw
        fullName: null
      }
      this.userService.getBusinessEmployeesFiltered(filterData).subscribe(
        data => this.allUserListSuccess(data),
        error => this.spinner.hide()
      );
    } else {
      this.userService.getAllBusinessEmployees(false).subscribe(
        data => this.allUserListSuccess(data),
        error => this.spinner.hide()
      );
    }
  }

  allUserListSuccess(data) {
    if (data.status === 1 && data.body ) {
      this.userList = data.body.content;
      this.totalUsers = data.body.totalElements;
      console.log('number of users on page = ' + this.userList.length);
      console.log('total number of users = ' + this.totalUsers);
      this.userList = this.userList.map((x) => {
          x.lastUpdatedTimestamp = this.commonComponent.convertEpochIntoDateTime(x.lastUpdatedTimestamp);
          if ( x.personType && x.personType === 'Participant' && this.participantLabel !== 'Participant') {
            x.personType = this.participantLabel;
          }
          return x;
      });
      this.filteredUserList = this.userList;
    } else {
      toastr.error(data.message);
    }
    this.spinner.hide();
  }

  filterAgentList(searchQuery) {
    this.filteredUserList = this.userList.filter(item => item.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
  }

  createUserModal(title = '', userId = '', userDetails = '') {
    this.dialogService.addDialog(CreateUserModalDialogComponent, {
      title: title,
      userId: userId,
      userDetails: userDetails
    }).subscribe((message) => {
      if (typeof message !== 'undefined') {
        this.getAllListOfBusinessEmployees();
      }
    });
  }

  deleteConfirmBusinessEmployee(userId) {
    this.dialogService.addDialog(ConfirmComponent, {
      title: 'Confirm Deletion of User',
      message: this.constants.deleteConfirmationMessage
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.spinner.show();
        this.businessAccountService.deleteBusinessEmployee(userId).subscribe(
          data => this.deleteSuccess(data),
          error => this.error(error)
        );
      }
    });
  }

  deleteSuccess(data) {
    if (data.status === 1) {
      toastr.success(this.constants.deletedSuccess);
      this.getAllListOfBusinessEmployees();
    } else {
      this.spinner.hide();
      toastr.error(data.message);
    }
  }

  resendActivationLink(userId, email) {
    this.dialogService.addDialog(ConfirmComponent, {
      title: 'Confirmation',
      message: 'Are you sure you want to send verification link to ' + email + '?'
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.spinner.show();
        this.businessAccountService.resendActivationLink(userId).subscribe(
          data => this.resentEmailSuccess(data),
          error => this.error(error)
        );
      }
    });
  }

  resentEmailSuccess(data) {
    this.spinner.hide();
    if (data.status === 1) {
      toastr.success(this.constants.resentActivationEmailSuccess);
    } else {
      toastr.success(data.message);
    }
  }

  editRelationships(personId, name) {
   // const data = { id: personId, name: name };
    this.router.navigate(['/contact/relationship', personId]);
  }

  editSecurityGroups(personId, name: string) {
    this.dialogService.addDialog(UserSecurityModalDialogComponent, {
      title: 'Assign Security Groups for ' + name,
      personId: personId,
      text: ''
    }).subscribe((message) => {

    });
  }

  openChangePasswordModal(personId, name: string) {
    this.dialogService.addDialog(ChangeUserPwModalDialogComponent, {
      title: 'Change Password for ' + name,
      personId: personId,
    }).subscribe((message) => {

    });
  }

  editUserStatusModal(id) {
    this.dialogService.addDialog(UserStatusModalDialogComponent, {
      title: 'Edit Status for ' + name,
      personId: id,
    }).subscribe((message) => {
      this.getAllListOfBusinessEmployees();
    });
  }

  error(error) {
    this.spinner.hide();
    toastr.error(this.constants.serverError)
  }

  getUsersOnPagination(event) {
    this.pageNumber = event.page;
    this.getAllListOfBusinessEmployees();
  }

  searchByColumn(searchText = '', searchByColumnIndex = '') {
    if (searchByColumnIndex) {
      if (searchText) {
        this.isFilterApplied = true;
      }
      this.searchBy[searchByColumnIndex] = searchText;
    }
    this.pageNumber = 1;
    this.getAllListOfBusinessEmployees();
  }

  sortByColumnName(columnName) {
    if (this.sortBy === columnName) {
      this.orderBy = this.orderBy === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.orderBy = 'ASC';
    }
    this.sortBy = columnName;
    this.pageNumber = 1;
    this.getAllListOfBusinessEmployees();
  }

  getButtonStyle() {
    if ( this.buttonStyle ) {
      return this.sanitizer.bypassSecurityTrustStyle(this.buttonStyle);
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.participantLabel = this.refdataService.getParticipantLabel();
    this.buttonStyle = this.refdataService.getCustomStyleCss('SettingButtonStyle');
    this.getAllListOfBusinessEmployees();
  }

}
