import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-before-login-header',
  templateUrl: './beforeLoginHeader.component.html',
  styleUrls: ['./beforeLoginHeader.component.scss'],
  providers: []
})
export class BeforeLoginHeaderComponent implements OnInit {

  @Input('headerText') headerText: String;

  constructor() {

  }


  ngOnInit() {
  }

}
