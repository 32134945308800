import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {UserService} from '../../services/app.user.service';
import {AuthenticationHelper} from '../../app.authentication';
import {DialogService} from 'ng6-bootstrap-modal';
import {Constants} from '../../constants';
import {CreateServiceGroupModalDialogComponent} from '../../routes/businessAdminSettings/serviceGroup/createServiceGroupModal.component';
import {NoServiceGroupComponent} from '../../routes/noServiceGroupFound/noServiceGroupFound.component';
import {ThemeLoader} from '../../app.component.loader';
import {SetStrongPasswordModalDialogComponent} from '../../shared/setStrongPasswordModalDialog.component';
import {BusinessAccountService} from '../../services/app.businessAccount.service';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SurveyService} from '../../services/app.survey.service';
import {RefdataService} from '../../services/app.refdata.service';
import {DomSanitizer} from '@angular/platform-browser';

declare let $: any;
const screenfull = require('screenfull');
const browser = require('jquery.browser');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [UserService, BusinessAccountService, SurveyService, RefdataService]
})
export class HeaderComponent implements OnInit {

  menuItems = []; // for horizontal layout
  currentUserDetails: any = '';
  selectedObjectId;
  @Output()
  serviceGroupLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton;  // the fullscreen button
  callToGetList = false;
  appVersion;
  serviceGroupListPageNumber = 1;
  pageSize = 100000;
  serviceGroupListSearchQuery = '';
  serviceGroupList = [];
  loadingGroup = false;
  selectedGroups: FormControl = new FormControl([]);
  inputServiceGroup$: Subject<string> = new Subject<string>();
  selectedServiceGroupId = 0;
  selectedServiceGroupName = '';
  selectedOrganization = '';
  headerStyle = '';
  headerFontStyle = '';
  sideMenuStyle = '';

  constructor(private menu: MenuService, public settings: SettingsService, private spinner: ThemeLoader,
              private dialogService: DialogService, public constants: Constants, private userService: UserService,
              private authentication: AuthenticationHelper, private businessAccountService: BusinessAccountService,
              private surveyService: SurveyService, private refdataService: RefdataService, private sanitizer: DomSanitizer ) {

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

    //  get the header style
    this.headerStyle = this.refdataService.getCustomStyleCss('HeaderColor');
    this.headerFontStyle = this.refdataService.getCustomStyleCss('HeaderFontColor');
    if ( !this.headerStyle ) {
      this.headerStyle = 'background-color: white;';
    }
    this.sideMenuStyle = this.refdataService.getCustomStyleCss('LeftMenuColor');
    if ( !this.sideMenuStyle ) {
      this.sideMenuStyle = 'background-color: #303f9f;';
    }

  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('fa-expand').addClass('fa-compress');
    } else {
      el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
  }

  getUserDetails() {
    this.spinner.show();
    this.userService.getUserAccountDetails().subscribe(
      data => this.userDetailsSuccess(data),
      error => this.spinner.hide()
    );
  }

  userDetailsSuccess(data) {
    this.currentUserDetails = data;
    const bacct = this.currentUserDetails.businessAccountDTO;

    if ( bacct && !this.authentication.getBusinessID()) {
      this.authentication.setBusinessID(this.currentUserDetails.businessAccountDTO.businessId);
      this.authentication.setBusinessName(this.currentUserDetails.businessAccountDTO.businessName);
      this.authentication.setBusinessLogo(this.currentUserDetails.businessAccountDTO.imageUrl);
      this.selectedOrganization = this.authentication.getBusinessName();
    } else if ( bacct && this.authentication.getBusinessID() !== bacct.businessId ) {
      this.currentUserDetails.businessAccountDTO.businessId = this.authentication.getBusinessID();
      this.currentUserDetails.businessAccountDTO.businessName = this.authentication.getBusinessName();
      this.currentUserDetails.businessAccountDTO.imageUrl = this.authentication.getBusinessLogo();
      if ( this.currentUserDetails.businessAccountDTO.imageUrl === 'null' ) {
        this.currentUserDetails.businessAccountDTO.imageUrl = null;
      }
      this.selectedOrganization = this.authentication.getBusinessName();
    }

    console.log('userDetailsSuccess - business name = ' + this.selectedOrganization);

    if (this.currentUserDetails.productList.length > 0 && !this.authentication.getSelectedObject()) {
      this.authentication.setSelectedObjects(this.currentUserDetails.productList[0]);
      this.authentication.setAllObjects(this.currentUserDetails.productList);
    }
    if (data.forcePasswordChange) {
      this.spinner.hide();
      this.dialogService.addDialog(SetStrongPasswordModalDialogComponent, {}).subscribe(() => {

      });
    } else {
      this.callToGetList = true;
    }
    this.getServiceGroupList();
  }

  onItemSelect(serviceGroupId: any, reloadPage: boolean) {
    console.log('onItemSelect called for service group ID ' + serviceGroupId)
    this.authentication.setSelectedObjects(serviceGroupId);
    this.setBusinessID(serviceGroupId);
    localStorage.removeItem('ticketSearchBy');
    localStorage.removeItem('surveySearchBy');
    localStorage.removeItem('contactSearchBy');
    localStorage.removeItem('workflowSearchBy');
    if (reloadPage) {
      this.reloadPage();
    }
  }

  setBusinessID(serviceGroupId) {
    if ( this.serviceGroupList && this.serviceGroupList.length > 0 ) {
      let sgValue = null;
      for ( const sg of this.serviceGroupList ) {
        if ( sg.id === serviceGroupId ) {
          sgValue = sg;
          break;
        }
      }
      if ( sgValue ) {
        this.selectedServiceGroupName = sgValue.name;
        const currentBusinessId = this.authentication.getBusinessID();
        const businessIdChanged = !currentBusinessId || currentBusinessId !== sgValue.businessId;
        if ( businessIdChanged ) {
          console.log('Business ID changed ...');
          this.authentication.setBusinessID(sgValue.businessId);
          this.authentication.setBusinessName(sgValue.businessName);
          this.authentication.setBusinessLogo(sgValue.imageUrl);
          this.getCustomStyles();
        }
        this.selectedOrganization = this.authentication.getBusinessName();
        console.log('setBusinessID - organization and service group = ' +
          this.selectedOrganization + ' - ' + this.selectedServiceGroupName);
      }
    }
  }

  private getCustomStyles() {
    console.log('getCustomStyles called ...');
    this.refdataService.clearCustomStyles();
    this.refdataService.getCustomStyles().subscribe(
      data => this.getCustomStylesSuccess(data),
      error => error(error)
    );
  }

  private getCustomStylesSuccess(data: any) {
    if ( data.status === 1 ) {
      console.log('use the custom styles for selected organization');
      const customStyleList = data.body;
      if ( customStyleList && customStyleList.length > 0 ) {
        console.log('Found ' + customStyleList.length + ' custom styles');
        this.refdataService.addCustomStyles(customStyleList, true);
      }

      //  get the header style
      this.headerStyle = this.refdataService.getCustomStyleCss('HeaderColor');
      if ( !this.headerStyle ) {
        this.headerStyle = 'background-color: white;';
      }
      this.sideMenuStyle = this.refdataService.getCustomStyleCss('LeftMenuColor');
      if ( !this.sideMenuStyle ) {
        this.sideMenuStyle = 'background-color: #303f9f;';
      }
    }
  }

  reloadPage() {
    window.location.reload();
  }

  callProperFunction(value) {
    if (value) {
      this.onListAPICallSuccess();
    } else {
      this.callToGetList = false;
      this.onNoServiceListDataFound();
    }
  }

  onListAPICallSuccess() {
    this.spinner.hide();
    this.serviceGroupLoaded.emit(true);
  }

  onNoServiceListDataFound() {
    if (this.currentUserDetails && (this.currentUserDetails.roles[0] === this.constants.businessAdmin
      || this.currentUserDetails.roles[0] === this.constants.systemAdmin)) {
      this.createObjectModal();
    } else {
      this.noServiceGroupFound();
    }
  }

  noServiceGroupFound() {
    this.spinner.hide();
    this.dialogService.addDialog(NoServiceGroupComponent, {
      title: 'Warning',
      message: ''
    }).subscribe((message) => {

    });
  }

  createObjectModal() {
    const businessId = this.authentication.getBusinessID();
    this.dialogService.addDialog(CreateServiceGroupModalDialogComponent, {
      title: 'Add Service Group',
      text: 'noServiceGroup',
      objectId: '',
      cloneObject: false,
      businessId: businessId
    }).subscribe((message) => {
      if (message) {
        this.reloadPage();
      }
    });
  }

  reloadAllTabsOnServiceGroupChange() {
    const self = this;
    window.addEventListener('storage', function (event) {
      if (event.key === self.authentication.selectedObjectId) {
        if (event.oldValue !== event.newValue) {
          console.log('reloading tabs for service group change ...');
          window.location.reload();
        }
      }
    }, false);
  }

  getAppVersion() {
    this.businessAccountService.getPlatformVersion().subscribe(
      data => this.appVersionSuccess(data),
    )
  }

  appVersionSuccess(data) {
    if (data.status === 1) {
      if (data.body) {
        this.appVersion = data.body.versionText;
      }
    }
  }

  selectedServiceGroup(value: any): void {
    this.selectedServiceGroupId = this.selectedGroups.value;
    console.log('selected service group value = ' + this.selectedServiceGroupId);
    const currentServiceGroupId = parseInt(this.authentication.getSelectedObject(), 10);
    if ( this.selectedServiceGroupId && this.selectedServiceGroupId !== currentServiceGroupId ) {
      this.onItemSelect(this.selectedServiceGroupId, true);
    }
  }

  private getServiceGroupList() {
    const params = {
      orderBy: 'ASC',
      pageNumber: 1,
      pageSize: 25,
      sortBy: 'name'
    };
    this.surveyService.getListOfObjects(params).subscribe(
      data => this.getServiceGroupListSuccess(data),
      error => error(error)
    );
  }

  private getServiceGroupListSuccess(data) {
    if ( data.status === 1 ) {
      const tempArray = data.body.elements;
      if (tempArray.length) {
        this.serviceGroupList = tempArray[0];
        const defaultServiceGroupId = parseInt(this.authentication.getSelectedObject(), 10);
        console.log('default service group ID = ' + defaultServiceGroupId);
        this.setCurrentServiceGroup(defaultServiceGroupId);
      }
    }
    this.spinner.hide();
  }

  private setCurrentServiceGroup(defaultServiceGroupId) {
    let sgValue = null;
    if ( this.serviceGroupList ) {
      for ( const sg of this.serviceGroupList ) {
        if ( sg.id === defaultServiceGroupId ) {
          sgValue = sg;
          break;
        }
      }
    }

    this.selectedServiceGroupId = defaultServiceGroupId;
    if ( sgValue ) {
      console.log('current service group = ' + sgValue.name);
      this.selectedServiceGroupName = sgValue.name;
      this.selectedGroups.patchValue(defaultServiceGroupId);
      this.onListAPICallSuccess();
    } else {
      this.selectedServiceGroupName = '';
      console.log('no matching service group found for id = ' + defaultServiceGroupId);
    }
  }

  onScroll() {
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) {
      this.fsbutton.nativeElement.style.display = 'none';
    }
    this.selectedObjectId = this.authentication.getSelectedObject();
    this.getUserDetails();
    this.reloadAllTabsOnServiceGroupChange();
    this.getAppVersion();
  }


  getHeaderStyle() {
     return this.sanitizer.bypassSecurityTrustStyle(this.headerStyle);
  }

  getSidemenuStyle() {
    return this.sanitizer.bypassSecurityTrustStyle(this.sideMenuStyle);
  }

  getHeaderFontStyle() {
    if ( this.headerFontStyle ) {
      return this.sanitizer.bypassSecurityTrustStyle(this.headerFontStyle);
    } else {
      return '';
    }
  }
}
