import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';
import {Constants} from '../constants';

@Injectable()
export class RefdataService {

  private httpClient;
  private refdataUrlPrefix = 'api/refdata/';
  private serverUrlPrefix = 'api/serverurls'
  private labelUrlPrefix = 'api/label/'
  private styleUrlPrefix = 'api/customStyle/';
  private serviceGroupUrl = 'serviceGroup/' + this.authentication.getSelectedObject();
  private customLabels = 'customLabels';
  private labelCategories = 'labelCategories';

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper,
              private constants: Constants,
              private http: HttpClient) {
    this.httpClient = httpClient;
  }

  getServerURLs() {
    const url = this.serverUrlPrefix;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSettingTypeList() {
    const url = this.refdataUrlPrefix + 'settingTypes/' + this.authentication.getBusinessID();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSettingTypesByCategory(category: string) {
    const url = this.refdataUrlPrefix + 'settingTypes/category/' + category + '/' + this.authentication.getBusinessID();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChildSettingTypesByCategory(category: string, parentSettingType: string) {
    const url = this.refdataUrlPrefix + 'settingTypes/category/' + category + '/' +
                 this.authentication.getBusinessID() + '/childrenOf/' + parentSettingType;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSettingTypePaginatedList(params: any = {}) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
      params.name = '';
      params.orderBy = 'ASC';
      params.sortBy = 'name';
      params.businessId = this.authentication.getBusinessID();
    }
    const url = this.refdataUrlPrefix + 'settingTypes/page';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSettingTypeDetails(settingTypeId) {
    const url = this.refdataUrlPrefix + 'settingTypes/details/' + settingTypeId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createSettingType(data) {
    const url = this.refdataUrlPrefix + 'settingTypes/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSettingType(data) {
    const url = this.refdataUrlPrefix + 'settingTypes/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSettingType(settingTypeId) {
    const url = this.refdataUrlPrefix + 'settingTypes/delete/' + settingTypeId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceTypeList() {
    const url = this.refdataUrlPrefix + 'resourceTypes/' + this.authentication.getBusinessID();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEventTopicTypeList() {
    const url = this.refdataUrlPrefix + 'eventTopicTypes/' + this.authentication.getSelectedObject();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceTypePaginatedList(params: any = {}) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
      params.name = '';
      params.orderBy = 'ASC';
      params.sortBy = 'name';
    }
    const url = this.refdataUrlPrefix + 'resourceTypes/page';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceTypeDetails(resourceTypeId) {
    const url = this.refdataUrlPrefix + 'resourceTypes/details/' + resourceTypeId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createResourceType(data) {
    const url = this.refdataUrlPrefix + 'resourceTypes/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateResourceType(data) {
    const url = this.refdataUrlPrefix + 'resourceTypes/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteResourceType(resourceTypeId) {
    const url = this.refdataUrlPrefix + 'resourceTypes/delete/' + resourceTypeId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEventTopicTypeDetails(eventTopicTypeId) {
    const url = this.refdataUrlPrefix + 'eventTopicTypes/details/' + eventTopicTypeId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createEventTopicType(data) {
    const url = this.refdataUrlPrefix + 'eventTopicTypes/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEventTopicType(data) {
    const url = this.refdataUrlPrefix + 'eventTopicTypes/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEventTopicType(resourceTypeId) {
    const url = this.refdataUrlPrefix + 'eventTopicTypes/delete/' + resourceTypeId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getLanguageList() {
    const url = this.refdataUrlPrefix + 'languages';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTimezoneList() {
    const url = this.refdataUrlPrefix + 'timezones';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonTypeList() {
    const url = this.refdataUrlPrefix + 'personTypes';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getRelationshipTypeList() {
    const url = this.refdataUrlPrefix + 'relationshipTypes';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBusinessUnitTypeList() {
    const url = this.refdataUrlPrefix + 'businessUnitTypes';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPrivilegeList() {
    const url = this.refdataUrlPrefix + 'privileges';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSecurityGroupList() {
    const url = this.refdataUrlPrefix + 'securityGroup/list';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserSecurityGroups(personId) {
    const url = this.refdataUrlPrefix + 'securityGroup/person/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUserSecurityGroups(data) {
    const url = this.refdataUrlPrefix + 'securityGroup/update/person';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createSecurityGroup(data: any) {
    const url = this.refdataUrlPrefix + 'securityGroup/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSecurityGroup(data: any) {
    const url = this.refdataUrlPrefix + 'securityGroup/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSecurityGroupDetails(securityGroupId) {
    const url = this.refdataUrlPrefix + 'securityGroup/' + securityGroupId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSecurityGroup(securityGroupId) {
    const url = this.refdataUrlPrefix + 'securityGroup/' + securityGroupId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllLabels() {
    const url = this.labelUrlPrefix + 'list';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomLabelDetails(id) {
    const url = this.labelUrlPrefix + 'custom/details/' + id;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomLabelPaginatedList(params: any = {}) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
      params.orderBy = 'ASC';
      params.sortBy = 'labelId';
    }
    const url = this.labelUrlPrefix + this.serviceGroupUrl + '/custom/page';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomLabelsByCategory(catName: string) {
    const url = this.labelUrlPrefix + this.serviceGroupUrl + '/list/category/' + catName;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserCustomLabelsByCategory(catName: string, userId: any) {
    const url = this.labelUrlPrefix + 'user/' + userId + '/list/category/' + catName;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createCustomLabel(data) {
    const url = this.labelUrlPrefix + 'custom/create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCustomLabel(data) {
    const url = this.labelUrlPrefix + 'custom/update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCustomLabel(id) {
    const url = this.labelUrlPrefix + 'custom/delete/' + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getCustomStyles() {
    const url = this.styleUrlPrefix + 'list/' + this.authentication.getBusinessID();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getCustomStylesForUser(userId: any) {
    const url = this.styleUrlPrefix + 'user/' + userId +  '/list';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomStyleDetails(id) {
    const url = this.styleUrlPrefix + 'details/' + id;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createCustomStyle(data) {
    const url = this.styleUrlPrefix + 'create';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCustomStyle(data) {
    const url = this.styleUrlPrefix + 'update';
    return this.httpClient.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCustomStyle(id) {
    const url = this.styleUrlPrefix + 'delete/' + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    return throwError(error);
  }

  //  the following functions are for local storage of custom style data
  clearCustomStyles() {
    for ( const styleType of this.constants.customStyleTypes ) {
      if ( typeof localStorage.getItem(styleType) !== 'undefined' && localStorage.getItem(styleType) ) {
        localStorage.removeItem(styleType);
      }
    }
  }

  addCustomStyles(customStyleList, clearExisting: boolean) {
    if ( clearExisting ) {
      this.clearCustomStyles();
    }
    if ( customStyleList && customStyleList.length > 0 ) {
      for (const style of customStyleList) {
        localStorage.setItem(style.styleType, style.styleCss);
      }
    }
  }

  setCustomStyleCss(styleType, styleCss) {
    localStorage.setItem(styleType, styleCss);
  }

  getCustomStyleCss(styleType) {
    if ( typeof localStorage.getItem(styleType) !== 'undefined' && localStorage.getItem(styleType) ) {
      return localStorage.getItem(styleType);
    } else {
      return null;
    }
  }

  getPageHeaderBackgroundCss() {
    let styleCss = this.getCustomStyleCss('PageHeaderBackground');
    if ( !styleCss ) {
      styleCss = 'background-color: white;';
    }
    return styleCss;
  }

  //  the following functions are for local storage and retrieval of custom label data
  hasLabelCategory(categoryName: string) {
    if ( typeof localStorage.getItem(this.labelCategories) !== 'undefined' && localStorage.getItem(this.labelCategories) ) {
      const categories = JSON.parse(localStorage.getItem(this.labelCategories));
      return categories.length > 0 && categories.indexOf(categoryName) > -1;
    } else {
      return false;
    }
  }

  addLabelCategory(categoryName: string) {
    let categories = [];
    if ( typeof localStorage.getItem(this.labelCategories) !== 'undefined' ) {
      categories = JSON.parse(localStorage.getItem(this.labelCategories));
    }
    categories.push(categoryName);
    const jsonCategories = JSON.stringify(categories);
    localStorage.setItem(this.labelCategories, jsonCategories);
  }

  getLabelsForCategory(categoryName: string) {
    let customLabels = [];
    const itemKey = this.customLabels + '::' + categoryName;
    if ( typeof localStorage.getItem(itemKey) !== 'undefined' ) {
      const labelData = localStorage.getItem(itemKey);
      if ( labelData) {
        customLabels = JSON.parse(labelData);
      }
    }
    return customLabels;
  }

  addLabelsForCategory(categoryName: string, customLabels: [], replaceExisting: boolean) {
    let categoryCustomLabels = this.getLabelsForCategory(categoryName);
    if ( categoryCustomLabels && categoryCustomLabels.length > 0 && !replaceExisting ) {
      categoryCustomLabels = categoryCustomLabels.concat(customLabels);
    } else {
      categoryCustomLabels = customLabels;
    }
    const jsonLabels = JSON.stringify(categoryCustomLabels);
    const itemKey = this.customLabels + '::' + categoryName;
    localStorage.setItem(itemKey, jsonLabels);
  }

  getLabelByNameInCategory(categoryName: string, labelName: string) {
    let selectedLabel = null;
    const categoryLabels = this.getLabelsForCategory(categoryName);
    if ( categoryLabels && categoryLabels.length > 0 ) {
      for ( const label of categoryLabels ) {
        if ( label.labelName === labelName ) {
          selectedLabel = label.labelValue;
          break;
        }
      }
    }
    return selectedLabel;
  }

  getParticipantLabel() {
    let participantLabel = 'Participant';
    const participantLabels = this.getLabelsForCategory('Participants');
    if ( participantLabels && participantLabels.length > 0 ) {
      for ( const label of participantLabels ) {
        if ( label.labelName === 'Participant' ) {
          participantLabel = label.labelValue;
          break;
        }
      }
    }
    return participantLabel;
  }



}
