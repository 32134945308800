/**
 * Created by himanshugupta on 4/17/18.
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Constants} from '../constants';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {throwError} from 'rxjs/internal/observable/throwError';

declare let toastr;


@Component({
  selector: 'import-text-file',
  template: `
    <form class="form-group col-md-12">
      <div>
        <div class="float-left full-width">
          <label>Suffix Name</label>
          <input autoFocusInput type="text" class="form-control" placeholder="Suffix Name" maxlength="10"
                 [formControl]="suffixName"/>
        </div>
        <div class="float-left full-width padding-top-20-px">
          <label>Import file</label>
          <input type="file" accept=".txt, .jla, .jls" (change)="readTextFile($event.target)"/>
        </div>
      </div>
      <button class="btn btn-primary float-right" [disabled]="!importTextFileForm.valid || saveImportTextFileBtn"
              (click)="saveImportData(importTextFileForm.value)">Import
      </button>
    </form>`
})

export class ImportTextFileComponent {

  @Input('type') type;
  jsonDataToImport = null;
  @Output()
  savedData: EventEmitter<Object> = new EventEmitter<Object>();
  importTextFileForm: FormGroup;
  saveImportTextFileBtn: boolean = false;
  suffixName: AbstractControl;

  constructor(private constants: Constants, fb: FormBuilder) {
    this.importTextFileForm = fb.group({
      'suffixName': [null]
    });

    this.suffixName = this.importTextFileForm.controls['suffixName'];
  }

  readTextFile(fileInput) {
    try {
      if (fileInput.files && fileInput.files[0]) {
        this.jsonDataToImport = fileInput.files[0];
        if (!this.type) {
          const textType = 'text/plain';
          if (fileInput.files[0].type.match(textType)) {
            const reader = new FileReader();
            reader.readAsText(fileInput.files[0]);
            reader.onload = (e: any) => {
              try {
                this.jsonDataToImport = JSON.parse(decodeURIComponent(atob((e.target.result))));
              } catch (e) {
                toastr.error(this.constants.invalidFileData);
                throwError(e);
              }
            };

          } else {
            toastr.error(this.constants.invalidFileFormat);
          }
        }
      }
    } catch (e) {
      throwError(e);
    }
  }

  saveImportData(value) {
    if (this.jsonDataToImport) {
      const result = {
        data: this.jsonDataToImport,
        suffix: value.suffixName
      };
      this.savedData.emit(result);
    } else {
      toastr.error('Please Import a File')
    }
  }

  ngOnInit() {

  }

}
