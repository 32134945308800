import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationHelper} from './app.authentication';
import {Constants} from './constants';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';

declare let toastr: any;

@Injectable()
export class HttpClientHelper {
  baseUrl = this.getApiBaseUrl();
  private http;

  constructor(http: HttpClient, private authentication: AuthenticationHelper, private constants: Constants) {
    this.http = http;
  }

  getApiBaseUrl() {
    const serverUrl = this.constants.serverAPIURl;
    const protocol = window.location.protocol;

    /* Joel ... uncomment
    if(serverUrl == 'localhost'){
      serverUrl = 'ui.journeylabs.io';
      protocol = 'https:';
    }
    */

    return protocol + '//' + serverUrl + '/';
  }

  createAuthorizationHeader(isPowerBIAuth = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (this.authentication.getToken()) {
      if (isPowerBIAuth) {
        headers = headers.append('authorization', 'Basic Sm91cm5leUxhYnM6YzNYWjdMTGdMcXM0S1dCdGZndDh6eUJGV3RDckM1Uk4=');
      } else {
        headers = headers.append('X-Auth-Token', this.authentication.getToken());
      }
    }
    return headers;
  }

  postFile(url, file, data) {
    let headers = new HttpHeaders();
    url = this.baseUrl + url;
    headers = headers.append('X-Auth-Token', this.authentication.getToken());
    const formData: FormData = new FormData();
    formData.append('file', file);
    if (data) {
      formData.append('proceedWithWarnings', data.proceedWithWarnings);
    }
    return this.http.post(url, formData, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  get(url, params = {}, isPowerBIAuth = false): Observable<any> {
    url = this.baseUrl + url;
    const headers = this.createAuthorizationHeader(isPowerBIAuth);
    return this.http.get(url, {headers: headers, params: params})
      .pipe(
        catchError(this.handleError)
      );
  }

  getPreLogin(url): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.get(url, {headers: headers})
      .pipe(
        catchError(this.handleErrorPreLogin)
      );
  }

  postPreLogin(url, data): Observable<any> {
    const body = JSON.stringify(data);
    const headers = this.createAuthorizationHeader();
    url = this.baseUrl + url;
    return this.http.post(url, body, {headers: headers})
      .pipe(
        catchError(this.handleErrorPreLogin)
      );
  }

  post(url, data = '', isPowerBIAuth = false): Observable<any> {
    const body = JSON.stringify(data);
    const headers = this.createAuthorizationHeader(isPowerBIAuth);
    url = this.baseUrl + url;
    return this.http.post(url, body, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  put(url, data = ''): Observable<any> {
    const body = JSON.stringify(data);
    const headers = this.createAuthorizationHeader();
    url = this.baseUrl + url;
    return this.http.put(url, body, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  Delete(url, data = ''): Observable<any> {
    const headers = this.createAuthorizationHeader();
    let body;
    url = this.baseUrl + url;
    if (data != '') {
      body = JSON.stringify(data);
      return this.http.delete(url, {
        headers: headers,
        body: body
      })
        .pipe(
          catchError(this.handleError)
        );
    } else {
      return this.http.delete(url, {headers: headers})
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  postByHeader(url, data, header): Observable<any> {
    const headers = this.createAuthorizationHeader();
    url = this.baseUrl + url;
    return this.http.post(url, {headers: headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  postByHeaderPreLogin(url, data, header): Observable<any> {
    const body = JSON.stringify(data);
    url = this.baseUrl + url;
    return this.http.post(url, body, {headers: header})
      .pipe(
        catchError(this.handleErrorPreLogin)
      );
  }

  // extractResponse(res: Response) {
  //   let body = res.json();
  //   return body || { };
  // }

  handleError(error) {
    if (error.status == 401) {
      if (localStorage.getItem('auth_token')) {
        toastr.error('Session Expired. Please Login again!');
      }
      localStorage.clear();
      window.location.href = '/login'
    }
    return throwError(error || 'Server error');
  }

  handleErrorPreLogin(error: HttpErrorResponse): Observable<any> {
    // if (!error || !error.error.error_message) {
    //   error.error.error_message = 'Incorrect Username or Password';
    // }
    // return throwError(error ||  'Server error');


    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
