import {Component, OnInit} from '@angular/core';
import {Constants} from '../../../../constants';
import {ThemeLoader} from '../../../../app.component.loader';
import {DialogService} from 'ng6-bootstrap-modal';
import {ConfirmComponent} from '../../../confirmModalDialog/confirmModalDialog.component';
import {ContactAttributeService} from '../../../../services/app.contactAttribute.service';

declare let toastr: any;

@Component({
  selector: 'participant-attribute-chart',
  templateUrl: './participantAttributeChart.component.html',
  styleUrls: ['./participantAttributeChart.component.scss'],
  providers: [ContactAttributeService]
})

export class ParticipantAttributeChartComponent implements OnInit {

  chartAttributeListSuccess;

  constructor(private spinner: ThemeLoader, private dialogService: DialogService, private constants: Constants,
              private contactAttributeService: ContactAttributeService, ) {
    toastr.options = {
      positionClass: 'toast-top-right',
      timeOut: '1000'
    };
  }


  getAllListOfVisualizationParameter() {
    this.spinner.show();
    this.contactAttributeService.getChartVisualizationParameter().subscribe(
      data => this.success(data),
      error => this.spinner.hide()
    );
  }

  success(data) {
    if (data.status == 1) {
      this.chartAttributeListSuccess = data.body;
    } else {
      toastr.error(data.message);
    }
    this.spinner.hide();
  }

  deleteConfirm(id) {
    this.dialogService.addDialog(ConfirmComponent, {
      title: 'Confirmation',
      message: this.constants.deleteConfirmationMessage
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.spinner.show();
        this.contactAttributeService.deleteChartVisualizationParameter(id).subscribe(
          data => this.deleteSuccess(data),
          error => this.error(error)
        );
      }
    });
  }

  deleteSuccess(data) {
    if (data.status == 1) {
      toastr.success(this.constants.deletedSuccess);
      this.getAllListOfVisualizationParameter();
    } else {
      toastr.error(data.message);
      this.spinner.hide();
    }
  }

  error(error) {
    this.spinner.hide();
    toastr.error(this.constants.serverError)
  }

  ngOnInit() {
    this.getAllListOfVisualizationParameter();
  }
}
