import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BlankSpaceValidator} from '../../../validators/blankSpace.validator';
import {Constants} from '../../../constants';
import {AuthenticationHelper} from '../../../app.authentication';
import {ObjectService} from '../../../services/app.object.service';
import {ThemeLoader} from '../../../app.component.loader';
import {AppRouteChangeSubscriptionService} from '../../../services/app.routerChangeSubscription.service';
import {AppModalService} from '../../../services/app.modalService';
import {CommonComponent} from '../../../shared/commonComponent';
import {BusinessAccountService} from '../../../services/app.businessAccount.service';
import {RefdataService} from '../../../services/app.refdata.service';

declare let toastr;

export interface createServiceGroupModalDialogModel {
  title: any;
  text: string;
  objectId: any;
  cloneObject: any;
  businessId: any;
}

@Component({
  selector: 'createServiceGroupModal',
  templateUrl: './createServiceGroupModal.component.html',
  styleUrls: ['../../settings/settings.component.scss'],
  providers: [ObjectService, AppRouteChangeSubscriptionService, CommonComponent, RefdataService, BusinessAccountService]
})
export class CreateServiceGroupModalDialogComponent extends DialogComponent<createServiceGroupModalDialogModel, string> implements createServiceGroupModalDialogModel {
  title: any;
  text: string;
  objectId: any;
  businessId: any;
  name: string;
  cloneObject: any;
  objectForm: any;
  cropImageBtn = true;
  description: AbstractControl;
  uploadedImageUrl: any = '';
  saveObjectBtn = false;
  serviceGroupDetails: any = {};
    orgUnitName: AbstractControl;
  businessUnitTypeList = [];
  businessUnitType:  FormControl = new FormControl([]);
  businessUnitList = [];
  businessList = [];
  parentBusinessUnit:  FormControl = new FormControl([]);
  isServiceGroup = false;
  useMobileWebView = false;
  parentUnitId;
  businessUnitTypeId;
  businessAccountList = [];
  businessAccount: FormControl = new FormControl([]);
  organizationSelect: FormControl = new FormControl([]);
  businessAccountId;
  isSysAdmin = false;

  constructor(dialogService: DialogService, fb: FormBuilder, private objectService: ObjectService,
              private authentication: AuthenticationHelper, public constants: Constants, private spinner: ThemeLoader,
              private appRouteChangeSubscriptionService: AppRouteChangeSubscriptionService,
              private refdataService: RefdataService, private businessAccountService: BusinessAccountService,
              private commonComponent: CommonComponent) {
    super(dialogService);

    this.objectForm = fb.group({
      'orgUnitName': [null, Validators.compose([Validators.required, BlankSpaceValidator.validate, Validators.minLength(3), Validators.maxLength(20)])],
      'description': [null, Validators.compose([Validators.nullValidator, Validators.minLength(3), Validators.maxLength(150)])],
      'businessUnitType': [null, Validators.nullValidator],
      'parentBusinessUnit': [null, Validators.nullValidator],
      'businessAccount': [null, Validators.nullValidator],
      'serviceGroupFlag': [null, Validators.nullValidator],
      'mobileWebViewFlag': [null, Validators.nullValidator],
      'organizationSelect': [null, Validators.nullValidator],
    });

    this.orgUnitName = this.objectForm.controls['orgUnitName'];
    this.description = this.objectForm.controls['description'];
    this.parentBusinessUnit = this.objectForm.controls['parentBusinessUnit'];
    this.businessAccount = this.objectForm.controls['businessAccount'];
    this.businessUnitType = this.objectForm.controls['businessUnitType'];
    this.organizationSelect = this.objectForm.controls['organizationSelect'];
  }

  onSubmit(values: any) {
    this.saveObjectBtn = true;
    this.spinner.show();
    this.submitDataToServer(values);
  }

  success(data) {
    this.saveObjectBtn = false;
    let message;

    if (data.status === 1) {
      this.result = data;
      this.close();
      if (this.objectId !== '' && !this.cloneObject) {
        message = this.constants.updatedSuccess;
      } else {
        message = this.constants.createdSuccess;
      }
      toastr.success(message);
    } else {
      this.spinner.hide();
      toastr.error(data.message);
    }
  }

  getSingleObject() {
    if (this.objectId) {
      this.businessAccountService.getBusinessUnitDetails(this.objectId).subscribe(
        data => this.setSingleObjectValueInForm(data),
        error => this.error(error)
      );
    } else {
      // if (this.authentication.getBusinessLogo() && this.authentication.getBusinessLogo() != 'null') {
      //   this.serviceGroupDetails.imageUrl = this.authentication.getBusinessLogo();
      //   this.uploadedImageUrl = this.serviceGroupDetails.imageUrl.slice(this.serviceGroupDetails.imageUrl.lastIndexOf('/') + 1, this.serviceGroupDetails.imageUrl.length);
      // }
    }
    this.spinner.hide();
  }

  setSingleObjectValueInForm(data) {
    if (data.status === 1) {
      this.serviceGroupDetails = data.body;
      this.description.patchValue(this.serviceGroupDetails.description);
      this.orgUnitName.patchValue(this.serviceGroupDetails.name);
      this.isServiceGroup = this.serviceGroupDetails.isServiceGroup;
      this.useMobileWebView = this.serviceGroupDetails.useMobileWebView;
      this.businessUnitTypeId = this.serviceGroupDetails.businessUnitTypeId;
      this.parentUnitId = this.serviceGroupDetails.parentBusinessUnitId;
      this.businessAccountId = this.serviceGroupDetails.defaultBusinessAccountId;
      this.businessId = this.serviceGroupDetails.businessId;

      let businessUnitTypeValue = null;
      if (this.businessUnitTypeList) {
        for (const but of this.businessUnitTypeList ) {
          if ( but.id === this.businessUnitTypeId ) {
            businessUnitTypeValue = but;
            break;
          }
        }
      }

      let businessAccountValue = null;
      if ( this.businessAccountList ) {
        for ( const ba of this.businessAccountList ) {
          if ( ba.id === this.businessAccountId ) {
            businessAccountValue = ba;
            break;
          }
        }
      }

      let organizationValue = null;
      if ( this.businessList ) {
        for ( const b of this.businessList ) {
          if ( b.id === this.businessId ) {
            organizationValue = b;
            break;
          }
        }
      }

      let parentBusinessUnitValue  = null;
      if ( this.businessUnitList ) {
        for ( const bu of this.businessUnitList ) {
          if ( bu.id === this.parentUnitId ) {
            parentBusinessUnitValue = bu;
            break;
          }
        }
      }

      if ( businessUnitTypeValue ) {
        this.objectForm.get('businessUnitType').patchValue(businessUnitTypeValue);
      }

      if ( businessAccountValue ) {
        this.objectForm.get('businessAccount').patchValue(businessAccountValue);
      }

      if ( parentBusinessUnitValue ) {
        this.objectForm.get('parentBusinessUnit').patchValue(parentBusinessUnitValue);
      }

      if ( organizationValue ) {
        this.objectForm.get('organizationSelect').patchValue(organizationValue);
      }
    }
  }

  error(error) {
    this.saveObjectBtn = false;
    this.spinner.hide();
    this.cropImageBtn = true;
    toastr.error(this.constants.serverError);
  }

  submitDataToServer(values) {

    console.log('submitDataToServer - start');

    this.businessAccountId = values.businessAccount && values.businessAccount.id ? values.businessAccount.id : null;
    if ( this.businessId === 0 ) {
      this.businessId = values.organizationSelect.id;
    }

    const parentBusinessUnitId = values.parentBusinessUnit && values.parentBusinessUnit.id ? values.parentBusinessUnit.id : null;

    const data: any = {
       name: this.commonComponent.trimLeftAndRightSpaces(values.orgUnitName),
       description: this.commonComponent.trimLeftAndRightSpaces(values.description),
       businessUnitTypeId: values.businessUnitType.id,
       parentBusinessUnitId: parentBusinessUnitId,
       isServiceGroup: this.isServiceGroup,
       useMobileWebView: this.useMobileWebView,
       businessId: this.businessId,
       defaultBusinessAccountId: this.businessAccountId
    };

    if (this.objectId && this.objectId > 0 && !this.cloneObject) {
      data.id = this.objectId;
      this.businessAccountService.updateBusinessUnit(data).subscribe(
        data => this.success(data),
        error => this.error(error)
      );
    } else {
      this.businessAccountService.createBusinessUnit(data).subscribe(
          data => this.success(data),
          error => this.error(error)
      );
    }
  }

  setImageValueAndSaveData(imageUrl) {
    /*
    if (imageUrl || this.uploadedImageUrl) {
      this.uploadedImageUrl = imageUrl.slice(imageUrl.lastIndexOf('/') + 1, imageUrl.length);
      this.submitDataToServer(this.objectForm.value);
    } else {
      this.saveObjectBtn = false;
      toastr.error(this.constants.uploadImageError);
      this.spinner.hide();
    } */
  }

  private getBusinessUnitTypeList() {
    this.refdataService.getBusinessUnitTypeList().subscribe(
      data => this.businessUnitTypeList = data.body,
      error => console.log('error retrieving business unit types')
    );
  }

  private getParentUnitList() {
    // const businessId = this.authentication.getBusinessID();
    this.businessAccountService.getBusinessUnitList(this.businessId).subscribe(
      data => this.businessUnitList = data.body,
      error => console.log('error retrieving parent business units')
    )
  }

  private getBusinessAccountList() {
    // const businessId = this.authentication.getBusinessID();
    this.businessAccountService.getBusinessAccountsForBusiness(this.businessId).subscribe(
      data => this.businessAccountList = data.body,
      error => console.log('error retrieving business account list')
    );
  }

  private getBusinessList() {
    if ( this.businessId === 0 ) {
      this.isSysAdmin = true;
      this.businessAccountService.getAllBusinessList().subscribe(
        data => this.businessList = data.body,
        error => console.log('error retrieving business list')
      );
    }
  }

  ngOnInit() {
    AppModalService.openModal(this);
    this.spinner.show();
    this.getBusinessList();
    this.getBusinessUnitTypeList();
    this.getParentUnitList();
    this.getBusinessAccountList();

    setTimeout(() => {
        this.getSingleObject(); }, 500);

    this.appRouteChangeSubscriptionService.closeModalOnRouteChange(this);
  }

  ngOnDestroy() {
    this.appRouteChangeSubscriptionService.routeSubscription.unsubscribe();
    super.ngOnDestroy();
  }


}
