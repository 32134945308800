import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuService} from '../../core/menu/menu.service';
import {SettingsService} from '../../core/settings/settings.service';
import {AuthenticationHelper} from '../../app.authentication';
import {Constants} from '../../constants';
import {RefdataService} from '../../services/app.refdata.service';
import {DomSanitizer} from '@angular/platform-browser';

declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [RefdataService]
})

export class SidebarComponent implements OnInit {

  menuItems: Array<any>;
  router: Router;
  checkAdmin = false;
  ticketAgent = false;
  workflowAgent = false;
  surveyAgent = false;
  participantUser = false;
  participantLabel = null; // for relabeling participant menus
  menuCustomStyle = null;
  menuCustomHighlight = null;

  constructor(private menu: MenuService, public settings: SettingsService, private injector: Injector,
              private refdataService: RefdataService, private sanitizer: DomSanitizer,
              private authentication: AuthenticationHelper, private constants: Constants) {

    const allMenuItems = menu.getMenu();
    // this.menuItems = menu.getMenu();
    this.participantLabel = this.refdataService.getParticipantLabel();
    this.menuCustomStyle = this.refdataService.getCustomStyleCss('LeftMenuColor');
    this.menuCustomHighlight = this.refdataService.getCustomStyleCss('LeftMenuHighlight');
    if ( !this.menuCustomStyle ) {
      this.menuCustomStyle = 'background-color: #303f9f;';
      console.log('use default menu custom style');
    } else {
      console.log('menu custom style = ' + this.menuCustomStyle);
    }
    const userRole = this.authentication.getUserRole();
    if (userRole === this.constants.businessAdmin || userRole === this.constants.systemAdmin ) {
      this.checkAdmin = true;
      this.menuItems = allMenuItems;
      if ( this.participantLabel && this.participantLabel !== 'Participant' ) {
        for ( const mitem of this.menuItems ) {
          if ( mitem.text && mitem.text.includes('Participant') ) {
            mitem.text = mitem.text.replace('Participant', this.participantLabel);
          }
        }
      }
    } else if ( userRole ) {
      this.menuItems = [];
      for ( const mitem of allMenuItems ) {
        if ( this.authentication.getPrivilege(mitem.showif) || mitem.showif === '*' ||
             (mitem.altshowif && this.authentication.getPrivilege(mitem.altshowif)) ) {
          if ( this.participantLabel && mitem.text && mitem.text.includes('Participant') ) {
            mitem.text = mitem.text.replace('Participant', this.participantLabel);
          }
          this.menuItems.push(mitem);
        }
      }
      // } else if (userRole === this.constants.surveyAgent) {
      //   this.surveyAgent = true;
      // } else if (userRole === this.constants.ticketAgent) {
      //   this.ticketAgent = true;
      // } else if (userRole === this.constants.workflowAgent) {
      //   this.workflowAgent = true;
    } else {
      this.participantUser = true;
      this.menuItems = [];
    }
    // console.log('# menu items to show = ' + this.menuItems.length);
    // this.participantUser = !(this.checkAdmin || this.surveyAgent || this.ticketAgent || this.workflowAgent);
  }

  ngOnInit() {

    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
    });

  }

  toggleSubmenuClick(event) {
    if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
      event.preventDefault();

      const target = $(event.target || event.srcElement || event.currentTarget);
      let ul, anchor = target;

      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a').first();
      }
      ul = anchor.next();

      // hide other submenus
      const parentNav = ul.parents('.sidebar-subnav');
      $('.sidebar-subnav').each((idx, el) => {
        const $el = $(el);
        // if element is not a parent or self ul
        if (!$el.is(parentNav) && !$el.is(ul)) {
          this.closeMenu($el);
        }
      });

      // abort if not UL to process
      if (!ul.length) {
        return;
      }

      // any child menu should start closed
      ul.find('.sidebar-subnav').each((idx, el) => {
        this.closeMenu($(el));
      });

      // toggle UL height
      if (parseInt(ul.height(), 0)) {
        this.closeMenu(ul);
      } else {
        // expand menu
        ul.on('transitionend', () => {
          ul.height('auto').off('transitionend');
        }).height(ul[0].scrollHeight);
        // add class to manage animation
        ul.addClass('opening');
      }

    }

  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.height(elem[0].scrollHeight); // set height
    elem.height(0); // and move to zero to collapse
    elem.removeClass('opening');
  }

  toggleSubmenuHover(event) {
    const self = this;
    if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
      event.preventDefault();

      this.removeFloatingNav();

      const target = $(event.target || event.srcElement || event.currentTarget);
      let ul, anchor = target;
      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a');
      }
      ul = anchor.next();

      if (!ul.length) {
        return; // if not submenu return
      }

      const $aside = $('.aside');
      const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
      const $sidebar = $asideInner.children('.sidebar');
      const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
      const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

      const floatingNav = ul.clone().appendTo($aside);
      const vwHeight = $(window).height();

      // let itemTop = anchor.position().top || anchor.offset().top;

      floatingNav
        .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
        .addClass('nav-floating')
        .css({
          position: this.settings.layout.isFixed ? 'fixed' : 'absolute',
          top: itemTop,
          bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
        });

      floatingNav
        .on('mouseleave', () => {
          floatingNav.remove();
        })
        .find('a').on('click', function (e) {
        e.preventDefault(); // prevents page reload on click
        // get the exact route path to navigate
        self.router.navigate([$(this).attr('route')]);
      });

      this.listenForExternalClicks();

    }

  }

  listenForExternalClicks() {
    const $doc = $(document).on('click.sidebar', (e) => {
      if (!$(e.target).parents('.aside').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.layout.isCollapsed;
  }

  isSidebarCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  isEnabledHover() {
    return this.settings.layout.asideHover;
  }

  getSidebarSyle() {
     return this.sanitizer.bypassSecurityTrustStyle(this.menuCustomStyle);
  }

  getMenuItemHighlightStyle(isActive: boolean) {
    if ( isActive && this.menuCustomHighlight ) {
      return this.sanitizer.bypassSecurityTrustStyle(this.menuCustomHighlight);
    } else {
      return '';
    }
  }


}
