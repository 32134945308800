import {Component} from '@angular/core';
import {DialogComponent, DialogService} from 'ng6-bootstrap-modal';
import {seriesDialogModel} from '../../routes/contact/contactDetails/contactAttributeTable/seriesValuesModal.component';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';
import {CommonComponent} from '../commonComponent';
import {ThemeLoader} from '../../app.component.loader';
import {Constants} from '../../constants';
import {AppRouteChangeSubscriptionService} from '../../services/app.routerChangeSubscription.service';

declare let toastr;

export interface viewSeriesDialogModel {
  contactId: number;
  attributeId: number;
  attributeName: string;
}

@Component({
  template: `<div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button (click)="close()" class="close" type="button">&times;</button>
              <h4 class="modal-title">{{attributeName}}'s Timeline Values</h4>
          </div>
          <div class="modal-body">
              <table class="table table-bordered table-striped"
                     *ngIf="attributeSeriesData && attributeSeriesData.length > 0">
                  <thead>
                  <tr class="text-align-center">
                      <th class="width-50-percent text-align-center">Values</th>
                      <th class="width-50-percent text-align-center">Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-align-center" *ngFor="let single of attributeSeriesData">
                      <td>
                          <span *ngIf="single.variableValue.value != null && attributeDataType != constants.variableTypes.IMAGE">{{single.variableValue.value}}</span>
                          <span *ngIf="single.variableValue.value && attributeDataType == constants.variableTypes.IMAGE">
                              <img *ngIf="single.variableValue.value" [src]="single.variableValue.value" height="40" width="40"/>
                          </span>
                          <span *ngIf="single.variableValue.value == null">NA</span>
                      </td>
                      <td>{{single.timeStamp}}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>`,
  styles: [`
    .modal-body {
        min-height: 450px;
        max-height: 450px;
        overflow-y: auto;
    }
  `],
  providers: [ContactAttributeService]
})

export class ViewSeriesValuesModalComponent extends DialogComponent<viewSeriesDialogModel, any> implements viewSeriesDialogModel{
  attributeId: number;
  contactId: number;
  attributeName: string;
  attributeDataType: string;
  attributeSeriesData: any[] = [];

  constructor(dialogService: DialogService, private contactAttributeService: ContactAttributeService,
              private commonComponent: CommonComponent, private spinner: ThemeLoader, private constants: Constants) {
    super(dialogService)
  }

  getSeriesAttributeTimelineData() {
    const data = {
      sortOrders: {
        'fieldName': 'timeStamp',
        'sortOrder': 'DESC'
      }
    };
    this.contactAttributeService.getSeriesAttributeData(this.contactId, this.attributeId, data).subscribe(
      data => this.getSeriesAttributeTimelineDataSuccess(data),
      error => this.error(error)
    );
  }

  error(error) {
    this.spinner.hide();
    toastr.error(this.constants.serverError);
  }

  getSeriesAttributeTimelineDataSuccess(data) {
    if (data.status === 1) {
      if (data.body.variableValues) {
        this.attributeSeriesData = data.body.variableValues;
        this.attributeDataType = data.body.customProfileAttribute.attributeDataType;

        this.attributeSeriesData.map((single) => {
          single.variableValue.value = this.showContactAttributeValue(this.attributeDataType, single.variableValue.value);
          single.timeStamp = this.commonComponent.convertEpochIntoDateTime(single.timeStamp);
        });
      }
    }
  }

  showContactAttributeValue(type, value) {
    switch (type) {
      case this.constants.variableTypes.DATETIME: {
        value = this.commonComponent.convertEpochIntoDateTime(value);
        break;
      }
      case this.constants.variableTypes.DATE: {
        value = this.commonComponent.convertEpochIntoDate(value);
        break;
      }
      case this.constants.variableTypes.TIME: {
        value = this.commonComponent.convertMilliSecondIntoActualTimeFormat(value);
        break;
      }
    }
    return value;
  }

  ngOnInit() {
    this.getSeriesAttributeTimelineData();
  }

}
