const Home = {
  text: 'Team Member Dashboard',
  link: '/home/teamMemberDashboard',
  type: 'home',
  icon: '../../../assets/img/sidebar_icons/home_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/home_icon_selected.svg',
  showif: 'ViewTeamMemberDashboard',
  altshowif: 'ViewParticipantDashboard'
};

const Logout = {
  text: 'Logout',
  link: '/logout',
  icon: 'fa fa-sign-out',
  showif: '*'
};

const Survey = {
  text: 'Surveys & Pages',
  link: '/surveyList',
  type: 'survey',
  icon: '../../../assets/img/sidebar_icons/survey_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/survey_icon_selected.svg',
  showif: 'ViewSurveys',
};

const Settings = {
  text: 'Settings',
  link: '/settings',
  type: 'settings',
  icon: '../../../assets/img/sidebar_icons/settings_gear_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/settings_gear_icon_selected.svg',
  showif: 'ViewGeneralSettings'
};

const Contacts = {
  text: 'Participant Management',
  link: '/contact',
  type: 'contact',
  icon: '../../../assets/img/sidebar_icons/contact_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/contact_icon_selected.svg',
  showif: 'ViewParticipants'
};

const Tickets = {
  text: 'Conversations',
  link: '/tickets',
  type: 'tickets',
  icon: '../../../assets/img/sidebar_icons/conversations_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/conversations_icon_selected.svg',
  showif: 'ViewConversations'
};

const JourneyRun = {
  text: 'Manage Journeys',
  link: '/journey',
  type: 'journey',
  icon: '../../../assets/img/sidebar_icons/ui-checks-grid-white.svg',
  active_icon: '../../../assets/img/sidebar_icons/ui-checks-grid-selected.svg',
  showif: 'ViewJourneys'
};

const JourneyDef = {
  text: 'Design Journeys',
  link: '/journeydef',
  type: 'journeydef',
  icon: '../../../assets/img/sidebar_icons/action_flows_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/action_flows_icon_selected.svg',
  showif: 'ViewJourneyDesigns'
};

const Calendar = {
  text: 'Calendar',
  link: '/calendar/events',
  type: 'calendar',
  icon: '../../../assets/img/sidebar_icons/my_care_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/my_care_icon_selected.svg',
  showif: 'ViewCalendar'
};

const helpNow = {
  text: 'Resources',
  link: '/helpnow',
  type: 'helpnow',
  icon: '../../../assets/img/sidebar_icons/help_now_icon_white.svg',
  active_icon: '../../../assets/img/sidebar_icons/help_now_icon_selected.svg',
  showif: 'ViewHelpResources'
};

const Analytics = {
  text: 'Analytics',
  link: '/tableau',
  type: 'analytics',
  icon: 'fa fa-bar-chart fa-lg',
  showif: '*'
};

const journeyPlanner = {
  text: 'Journey Planner(Alpha Version)',
  link: '/journeyPlanner',
  type: 'journeyPlanner',
  icon: 'icon-alpha',
  showif: '*'
};

export const menu = [
  Home,
  Contacts,
  Tickets,
  Calendar,
  Survey,
  JourneyDef,
  JourneyRun,
  // Workflow,
  helpNow,
  Settings,
];
