import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SurveyService} from '../../services/app.survey.service';
import {Constants} from '../../constants';
import {AuthenticationHelper} from '../../app.authentication';
import {ObjectService} from '../../services/app.object.service';
import {ThemeLoader} from '../../app.component.loader';
import {ContactAttributeService} from '../../services/app.contactAttribute.service';
import {CommonComponent} from '../commonComponent';
import {PdfFormService} from '../../services/app.pdfFormService.service';

declare let $;

@Component({
  selector: 'custom-select',
  templateUrl: './customSelect.component.html',
  styleUrls: ['./customSelect.component.scss'],
  providers: [ObjectService, ContactAttributeService, SurveyService, PdfFormService, CommonComponent],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'handleClick($event)',
  }
})

export class CustomSelectComponent implements AfterViewInit {
  @Input('showCustomVar') showCustomVar;
  @Input() filters;
  @Output() setControlValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() callParentFunction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('optionList') optionList: ElementRef;
  @ViewChild('optionSearchInput') optionSearchInput: ElementRef;
  @Output('getType') getType: EventEmitter<any> = new EventEmitter<any>();
  @Input('showFixedAttribute') showFixedAttribute;
  searchListControl: FormControl = new FormControl('');
  selectedOptionName = '';
  selectedOptionNameText = '';
  pageNumber = 1;
  listClick = false;
  pageSize = 10;
  searchQuery = '';
  listData;
  readyToAPICall = true;
  selectedOptionID: any = null;
  listType = '';
  firstTimeLoad = true;
  surveyQuestions;
  surveyList = [];
  isServerDataLoad = false;
  participantAttributeList = [];
  surveyId;
  fixedAttributeList = [{
    attributeDataType: 'IMAGE',
    defaultValue: '',
    displayName: 'Participant Profile Image',
    id: 'PARTICIPANT:PROFILE_IMAGE',
  }];
  customVariable = {
    'DATE': {
      name: 'Current Date',
      value: this.constants.CURR_DATE_VAR,
      type: this.constants.CUSTOM_VAR
    },
    'DATETIME': {
      name: 'Current Datetime',
      value: this.constants.CURR_DATETIME_VAR,
      type: this.constants.CUSTOM_VAR
    },
    'TIME': {
      name: 'Current Time',
      value: this.constants.CURR_TIME_VAR,
      type: this.constants.CUSTOM_VAR
    },
    'TEXT': {
      name: 'Current Day of Week',
      value: this.constants.CURR_DAYOFWEEK_VAR,
      type: this.constants.CUSTOM_VAR
    },
    'CUSTOM': {
      name: 'Fix Value',
      value: this.constants.CUSTOM,
      type: this.constants.CUSTOM_VAR
    }
  };
  @Input('variableList') variableList;

  constructor(private elRef: ElementRef, private renderer: Renderer2, private surveyService: SurveyService,
              private objectService: ObjectService, private contactAttributeService: ContactAttributeService,
              private pdfFormService: PdfFormService, public constants: Constants,
              public authentication: AuthenticationHelper, public spinner: ThemeLoader,
              public commonComponent: CommonComponent) {

  }

  @Input()
  set setPreselectedOption(value) {
    if (!this.listClick || this.listType === this.constants.SURVEY_QUESTION_LIST) {
      this.listType = value.type;
      this.selectedOptionID = value.id;
      switch (this.listType) {
        case this.constants.SURVEY_LIST: {
          this.selectedOptionNameText = 'Select Questionnaire';
          this.selectedOptionName = 'Select Questionnaire <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
          break;
        }
        case this.constants.CUSTOM_ATTRIBUTE_LIST: {
          this.selectedOptionNameText = 'Select Participant Attribute';
          this.selectedOptionName = 'Select Participant Attribute <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
          break;
        }
        case this.constants.VARIABLES_LIST: {
          this.selectedOptionNameText = 'Select Variable';
          this.selectedOptionName = 'Select Variable <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
          break;
        }
        case this.constants.SURVEY_QUESTION_LIST: {
          this.surveyId = null;
          this.selectedOptionID = this.selectedOptionID.split(':');
          this.surveyId = this.selectedOptionID[0];
          this.selectedOptionID = this.selectedOptionID[1];
          this.selectedOptionNameText = 'Select Question';
          this.selectedOptionName = 'Select Question <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
          break;
        }
        case this.constants.PDF_FORMS_LIST: {
          this.selectedOptionNameText = 'Select PDF Form';
          this.selectedOptionName = 'Select PDF Form <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
          break;
        }
      }
      this.getNameFromId(this.selectedOptionID);
    }
  }

  @Input()
  set setList(value) {
    if (value) {
      this.getList();
    }
  }

  setSelectedOption(content, type) {
    // Reload page for service group drop-down
    let dataType = '', name = '', reloadPage = true;
    this.listClick = true;
    switch (type) {
      case this.constants.SERVICE_GROUP_LIST: {
        if (content.id === this.selectedOptionID) {
          reloadPage = false;                // For reloading page on changing service group
        }
        name = content.name;
        break;
      }
      case this.constants.CUSTOM_ATTRIBUTE_LIST: {
        dataType = content.attributeDataType;
        name = content.displayName;
        this.getType.emit(dataType);
        break;
      }
      case this.constants.SURVEY_LIST: {
        name = content.title;
        break;
      }
      case this.constants.SURVEY_QUESTION_LIST: {
        name = this.commonComponent.getSurveyQuestionTitle(content.question, content.questionTextId);
        break;
      }
      case this.constants.VARIABLES_LIST: {
        content = JSON.parse(JSON.stringify(content));
        dataType = content.type;
        name = content.name;
        if (dataType === this.constants.CUSTOM_VAR) {
          content.name = content.value;
        } else {
          this.getType.emit(dataType);
        }
        this.selectedOptionID = content.name;
        break;
      }
      case this.constants.PDF_FORMS_LIST: {
        this.selectedOptionID = content.id;
        break;
      }
    }

    if (type !== this.constants.VARIABLES_LIST) {
      this.selectedOptionID = content.id
    }

    if (this.listType === this.constants.SERVICE_GROUP_LIST && !this.firstTimeLoad) {
      this.setControlValue.emit({id: this.selectedOptionID, name: name, reloadPage: reloadPage});
    } else {
      this.setControlValue.emit(content);
    }
    this.selectedOptionNameText = name;
    this.selectedOptionName = `<span class="option-text">${name}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
  }

  toggleShowClass() {
    $('.listDropdown').removeClass('visible');
    this.elRef.nativeElement.querySelector('.listDropdown').classList.toggle('visible');
    this.renderer.selectRootElement(this.optionSearchInput.nativeElement).focus();
  }

  handleClick(event) {
    if (!event.target.matches('.drop-down-btn') && !event.target.matches('.option-text') &&
      !event.target.matches('.down-arrow') && !event.target.matches('.search-box-container') &&
      !event.target.matches('.form-control') && !event.target.matches('.disabled-option')) {
      const dropdowns = document.getElementsByClassName('dropdown-content-options');
      for (let i = 0; i < dropdowns.length; i++) {
        const openDropdown = dropdowns[i];
        if (openDropdown.parentElement.classList.contains('visible')) {
          openDropdown.parentElement.classList.remove('visible');
        }
      }
    }
  }

  onListScroll(event) {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop >= limit * 0.7 && this.readyToAPICall) {
      this.readyToAPICall = false;
      this.pageNumber++;
      this.getList();
    }
  }

  getList() {
    this.spinner.show();
    this.isServerDataLoad = false;
    switch (this.listType) {
      case this.constants.SURVEY_LIST: {
        const filterData: any = {
          length: 0,
          computeStats: true,
          contactList: [],
          orderBy: 'ASC',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortBy: 'title',
          status: this.filters.status,
          title: this.searchQuery,
          type: ''
        };
        this.surveyService.getSurveyListWithFilter(filterData).subscribe(
          data => this.getListSuccess(data),
          error => {
            this.spinner.hide();
            this.readyToAPICall = true;
            this.pageNumber--;
          }
        );
        break;
      }
      case this.constants.SERVICE_GROUP_LIST: {
        const params = {
          name: this.searchQuery,
          orderBy: 'ASC',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortBy: 'name'
        };

        this.surveyService.getListOfObjects(params).subscribe(
          data => this.getListSuccess(data),
          error => {
            this.spinner.hide();
            this.readyToAPICall = true;
            this.pageNumber--;
          }
        );
        break;
      }
      case this.constants.CUSTOM_ATTRIBUTE_LIST: {
        console.log('+++ filters = ', JSON.stringify(this.filters));
        const params = {
          dataType: this.filters ? (this.filters.dataType ? this.filters.dataType : ['']) : [''],
          name: this.searchQuery,
          orderBy: 'ASC',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          scope: this.filters ? this.filters.scope : '',
          sortBy: 'attributeName',
          type: this.filters ? (this.filters.type ? this.filters.type : ['']) : ['']
        };

        this.contactAttributeService.getAllCustomAttributes(params).subscribe(
          data => this.getListSuccess(data),
          error => this.spinner.hide()
        );
        break;
      }
      case this.constants.VARIABLES_LIST: {
        if (this.filters && this.filters.dataType) {
          this.variableList = this.variableList.filter((x) => {
            if ((typeof this.filters.dataType === 'string' && this.filters.dataType === x.type)
              || (typeof this.filters.dataType === 'object' && this.filters.dataType.indexOf(x.type) > -1)) {
              return x;
            }
          });
        }
        this.listData = this.variableList;
        this.spinner.hide();
        break;
      }
      case this.constants.SURVEY_QUESTION_LIST: {
        const data = {
          surveys: [this.surveyId],
          matrixQuestionsInline: true
        };
        this.surveyService.getFormattedSurveyDetails(data).subscribe(
          data => this.getListSuccess(data),
          error => this.spinner.hide()
        );
        break;
      }
      case this.constants.PDF_FORMS_LIST: {
        const params = {
          orderBy: 'ASC',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          searchByName: '',
          sortBy: 'templateName'
        };

        this.pdfFormService.getAllPDFForms(params).subscribe(
          data => this.getListSuccess(data),
          error => console.log(error)
        );
      }
    }
  }

  getListSuccess(data) {
    let tempArray;
    if (data.status === 1) {
      this.isServerDataLoad = true;
      switch (this.listType) {
        case this.constants.SURVEY_LIST: {
          tempArray = data.body.surveyList;
          break;
        }
        case this.constants.SERVICE_GROUP_LIST: {
          tempArray = data.body.elements;
          if (tempArray.length) {
            this.listData = tempArray[0];
            const selectedObjectId = this.authentication.getSelectedObject();
            this.getNameFromId(selectedObjectId);
          } else {
            if (this.firstTimeLoad) {
              this.callParentFunction.emit(false);
            }
          }
          break;
        }
        case this.constants.CUSTOM_ATTRIBUTE_LIST: {
          tempArray = data.body.elements;
          break;
        }
        case this.constants.SURVEY_QUESTION_LIST: {
          this.listData = data.body[0].questions;
          if (this.filters) {
            this.listData = this.listData.filter((singleQuestion) => {
              if (this.filters.dataType === this.constants.questionType.TEXT || this.filters.dataType === this.constants.questionType.NUMBER ) {
                this.filters.dataType = [this.constants.questionType.FREETEXT, this.constants.questionType.NUMBER, this.constants.questionType.BOOLEAN, this.constants.questionType.SCQ, this.constants.questionType.MCQ];
              }

              if ((typeof this.filters.dataType === 'string' && this.filters.dataType === singleQuestion.questionType) ||
                (typeof this.filters.dataType === 'object' && this.filters.dataType.indexOf(singleQuestion.questionType) > -1)) {
                singleQuestion.question = this.commonComponent.getSurveyQuestionTitle(singleQuestion.question, singleQuestion.questionTextId);
                return singleQuestion;
              }
            });
          }

          this.surveyQuestions = this.listData;

          if (this.listData && this.listData.length > 0) {
            const selectedQuestion = this.listData.filter((item) => {
              return item.id === this.selectedOptionID
            })[0];

            if (selectedQuestion) {
              this.selectedOptionNameText = selectedQuestion.question;
              this.selectedOptionName = `<span class="option-text">${this.selectedOptionNameText}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
            }
          }
          break;
        }
        case this.constants.PDF_FORMS_LIST: {
          tempArray = data.body.elements;
          break;
        }
      }
      if (this.listType !== this.constants.SURVEY_QUESTION_LIST) {
        if (tempArray.length) {
          this.readyToAPICall = true;
          if (this.listData && this.pageNumber !== 1) {
            this.listData = this.listData.concat(tempArray);  // on scroll api call load the data
          } else {   // first time load
            this.listData = tempArray;
            if (!this.searchListControl.value) {
              if (this.listType === this.constants.CUSTOM_ATTRIBUTE_LIST) {
                this.participantAttributeList = tempArray;
              }

              if (this.listType === this.constants.SURVEY_LIST) {
                this.surveyList = tempArray;
              }
            }
            if (this.firstTimeLoad) {
              if (this.listData.length) {
                this.callParentFunction.emit(true);
              }
              this.firstTimeLoad = false;
            }
          }
          if (tempArray.length < this.pageSize) {
            this.readyToAPICall = false;
          }
        } else {
          this.readyToAPICall = false;
        }
      }
    } else {
      this.readyToAPICall = true;
      this.pageNumber--;
    }
    this.spinner.hide();
  }

  searchOptionInRecords(searchQuery) {
    this.listData = [];
    this.searchQuery = searchQuery;
    if (this.listType === this.constants.VARIABLES_LIST || this.listType === this.constants.SURVEY_QUESTION_LIST) {
      if (this.listType === this.constants.VARIABLES_LIST) {
        this.listData = this.variableList.filter(item => item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1);
      }
      if (this.listType === this.constants.SURVEY_QUESTION_LIST) {
        this.listData = this.surveyQuestions.filter((item) => {
          const questionText = this.commonComponent.getSurveyQuestionTitle(item.question, item.questionTextId);
          return questionText.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
        });
      }
    } else {
      this.pageNumber = 1;
      this.getList();
    }
  }

  getNameFromId(id) {
    if (id) {
      const selectedId = {
        id: id
      };
      switch (this.listType) {
        case this.constants.SURVEY_LIST: {
          this.surveyService.getSurveyTitle(selectedId).subscribe(
            data => this.setNameFromId(data, id),
            error => this.getNameError(error)
          );
          break;
        }
        case this.constants.SERVICE_GROUP_LIST: {
          this.objectService.getServiceGroupName(selectedId).subscribe(
            data => this.setNameFromId(data, id),
            error => this.getNameError(error)
          );
          break;
        }
        case this.constants.CUSTOM_ATTRIBUTE_LIST: {
          if (typeof id === 'string' && id.indexOf('PARTICIPANT:') > -1) {   // if fixed attribute selected
            this.selectedOptionID = id;
            const selectedFixedAttribute = this.fixedAttributeList.filter(x => x.id === id)[0];
            this.selectedOptionNameText = selectedFixedAttribute.displayName;
            this.selectedOptionName = `<span class="option-text">${this.selectedOptionNameText}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
            this.getType.emit(selectedFixedAttribute.attributeDataType);
          } else {
            this.contactAttributeService.getContactAttributeName(selectedId).subscribe(
              data => this.setNameFromId(data, id),
              error => this.getNameError(error)
            );
          }
          break;
        }
        case this.constants.SURVEY_QUESTION_LIST: {
          this.selectedOptionID = id;
          if (this.listData && this.listData.length > 0) {
            const selectedQuestion = this.listData.filter((item) => {
              return item.id === id
            })[0];
            if (selectedQuestion) {
              this.selectedOptionNameText = selectedQuestion.question;
              this.selectedOptionName = `<span class="option-text">${this.selectedOptionNameText}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
            }
          }
          break;
        }
        case this.constants.VARIABLES_LIST: {
          this.selectedOptionID = id;
          const selectedVariable = this.variableList.filter((item) => {
            return item.name === id
          })[0];
          if (selectedVariable) {
            this.selectedOptionNameText = selectedVariable.name;
            this.selectedOptionName = `<span class="option-text">${selectedVariable.name}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
            if (!this.showCustomVar) {
              this.getType.emit(selectedVariable.type);
            }
          } else {
            if (this.showCustomVar && this.customVariable[this.showCustomVar].name) {
              this.selectedOptionNameText = this.customVariable[this.showCustomVar].name;
              this.selectedOptionName = `<span class="option-text">${this.customVariable[this.showCustomVar].name}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
            }
          }
          break;
        }
      }
    }
  }

  setNameFromId(data, id) {
    this.resetEmitParameters();
    if (data.status === 1) {
      if (data.body.delete) {
        this.selectedOptionID = null;
        switch (this.listType) {
          case this.constants.SURVEY_LIST: {
            this.selectedOptionNameText = 'Select Questionnaire';
            this.selectedOptionName = 'Select Questionnaire <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
            break;
          }
          case this.constants.CUSTOM_ATTRIBUTE_LIST: {
            this.selectedOptionNameText = 'Select Participant Attribute';
            this.selectedOptionName = 'Select Participant Attribute <i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>';
            break;
          }
        }
      } else {
        this.selectedOptionNameText = data.body.title;
        this.selectedOptionID = id;
        this.selectedOptionName = `<span class="option-text">${this.selectedOptionNameText}</span><i class="fa fa-caret-down down-arrow float-right" aria-hidden="true"></i>`;
      }
      if (this.listType === this.constants.CUSTOM_ATTRIBUTE_LIST) {
        this.getType.emit(data.body.attributeDataType);
      }
      this.spinner.hide();
    }
  }

  resetEmitParameters() {
    this.selectedOptionID = null;
    this.selectedOptionName = null;
    this.selectedOptionNameText = null;
  }

  getNameError(error) {
    this.resetEmitParameters();
  }

  ngAfterViewInit() {
    this.searchListControl.valueChanges.pipe(debounceTime(1000)).pipe(distinctUntilChanged()).subscribe(query => {
      this.searchOptionInRecords(query)
    });
    const ignoreList = [this.constants.VARIABLES_LIST, this.constants.SURVEY_QUESTION_LIST];
    if (ignoreList.indexOf(this.listType) === -1) {
      if (this.optionList) {
        this.optionList.nativeElement.addEventListener('scroll', this.onListScroll.bind(this));
      }
    }
  }
}
